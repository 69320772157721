// //Samp lePage
import VendorGroup from '../Components/Pages/Forms/Procurement/VendorGroup';
import VendorCategory from '../Components/Pages/Forms/VendorCatogory';
import Sitemaster from '../Components/Pages/Forms/sitemaster';
import Inventorysite from '../Components/Pages/Forms/inventory';
import GeneralLedgersite from '../Components/Pages/Forms/general_ledger';
import Taxationsite from '../Components/Pages/Forms/taxation';
import Uom from '../Components/Pages/Forms/Inventory/Uom';
import ItemL3 from '../Components/Pages/Forms/Inventory/ItemL3';
import Currency from '../Components/Pages/Forms/Inventory/Currency';
import Expensecode from '../Components/Pages/Forms/Inventory/Expense_code';

import Paymentbase from '../Components/Pages/Forms/Submasters/Procurement/Paymentbase';
import MaterialClassification from '../Components/Pages/Forms/Submasters/inventory/MaterialClassification';
import Materialownership from '../Components/Pages/Forms/Submasters/inventory/MaterialOwnership';
import ItemGroup from '../Components/Pages/Forms/Inventory/ItemGroup';
import Warehouse from '../Components/Pages/Forms/Inventory/warehouse';
import WarehouseCategorysub from '../Components/Pages/Forms/Submasters/inventory/WarehouseCategorysub';
import AssesseeTerritory from '../Components/Pages/Forms/Submasters/Taxation/AssesseeTerritory';
import GST_TaxCategory from '../Components/Pages/Forms/Submasters/Taxation/GST_TaxCategory';
import MaterialSegregation from '../Components/Pages/Forms/Submasters/inventory/MaterialSegregation';
import CoaGroupNature from '../Components/Pages/Forms/Submasters/General_Ledger/COAGroupNature';
import GST_TaxType from '../Components/Pages/Forms/Submasters/Taxation/GST_TaxType';
import GST_TaxNature from '../Components/Pages/Forms/Submasters/Taxation/GST_TaxNature';
import GST_ChargeableBasis from '../Components/Pages/Forms/Submasters/Taxation/GST_ChargeableBasis';
import WHTaxType from '../Components/Pages/Forms/Submasters/Taxation/WHTaxType';
import WHTaxCategory from '../Components/Pages/Forms/Submasters/Taxation/WHTaxCategory';
import WHTaxImpact from '../Components/Pages/Forms/Submasters/Taxation/WHTaxImpact';
import NatureOfAssessee from '../Components/Pages/Forms/Submasters/Taxation/NatureOfAssessee';
import RegistrationType from '../Components/Pages/Forms/Submasters/Taxation/RegistrationType';
import ItemCategory from '../Components/Pages/Forms/Inventory/ItemCategory';
import ItemSubCategory from '../Components/Pages/Forms/Inventory/ItemSubCategory';
import COAGroup from '../Components/Pages/Forms/GeneralLedger/COAGroup';
import COALedger from '../Components/Pages/Forms/GeneralLedger/COALedger';
import HSN from '../Components/Pages/Forms/Taxation/HSN';
import SAC from '../Components/Pages/Forms/Taxation/SAC';
import Withhold_Tax from '../Components/Pages/Forms/Taxation/Withhold_Tax';
import TaxElement from '../Components/Pages/Forms/Taxation/TaxElement';
import Vendor from '../Components/Pages/Forms/Procurement/Vendor';
import TaxCluster from '../Components/Pages/Forms/Taxation/TaxCluster';
import TaxDesign from '../Components/Pages/Forms/Taxation/TaxDesign';
import Withhold_Tax_Assign from '../Components/Pages/Forms/Submasters/Taxation/Withhold_Tax_Assign';
import { Component } from 'react';
// import FormComponent from '../Components/Pages/Forms/call';
export const routes = [
  { path: `/sitemaster/`, Component: <Sitemaster/> },
  { path: `/inventorysite/`, Component: <Inventorysite/> },  
  { path: `/generalledgersite/`, Component: <GeneralLedgersite/> },
  { path: `/taxationsite/`, Component: <Taxationsite/> },  
  { path: `/vendorgroup/`, Component: <VendorGroup/> },
  { path: `/vendorcategory/`, Component: <VendorCategory /> },
  { path: `/paymentbase/`, Component: <Paymentbase /> },
  { path: `/materialclassification/`, Component: <MaterialClassification/> },
  { path: `/materialownership/`, Component: <Materialownership /> },
  { path: `/itemgroup/`, Component: <ItemGroup /> },
  { path: `/warehouse/`, Component: <Warehouse /> },
  { path: `/warehousecategory/`, Component: <WarehouseCategorysub /> },
  { path: `/assesseeterritory/`, Component: <AssesseeTerritory /> },
  { path: `/gsttaxcategory/`, Component: <GST_TaxCategory /> },
  { path: `/materialsegregation/`, Component: <MaterialSegregation /> },
  { path: `/coagroupnature/`, Component: <CoaGroupNature /> },
  { path: `/gsttaxtype/`, Component: <GST_TaxType /> },
  { path: `/gsttaxnature/`, Component: <GST_TaxNature /> },
  { path: `/gstchargeablebasis`, Component: <GST_ChargeableBasis />},
  { path: `/whtaxcategory/`, Component: <WHTaxCategory /> },
  { path: `/whtaxtype/`, Component: <WHTaxType /> },
  { path: `/whtaximpact/`, Component: <WHTaxImpact /> },
  { path: `/natureofassessee/`, Component: <NatureOfAssessee /> },
  { path: `/registrationtype/`, Component: <RegistrationType /> },
  { path: `/itemcategory`, Component: <ItemCategory/>},
  { path: `/uom/`, Component: <Uom /> },
  { path: `/itemsubcategory`, Component: <ItemSubCategory/>},
  { path: `/hsn/`, Component: <HSN/>},
  { path: `/sac/`, Component: <SAC/>},
  {path: `/currency/`, Component: <Currency />  },
  {path: `/expensecode/`, Component: <Expensecode />  },
  {path: `/coagroup/`, Component: <COAGroup />  },
  {path: `/coaledger/`, Component: <COALedger/>  },
  { path: `/withholdtax/`, Component: <Withhold_Tax />},
  { path: `/taxelement/`, Component: <TaxElement />},
  { path: `/vendor/`, Component: <Vendor />},
  { path: `/taxcluster/`, Component: <TaxCluster />},
  { path: `/iteml3/`, Component: <ItemL3 />},
  { path: `/taxdesign/`,  Component: <TaxDesign />},
  { path: `/WithholdTaxassign/`, Component: <Withhold_Tax_Assign/>}
  // { path: `/form/`, Component: <FormComponent /> },

];










