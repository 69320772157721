import React, {useEffect} from "react";
import {Link} from 'react-router-dom';
import { Card, Container } from "reactstrap";


function GeneralLedgersite(){



    return (
        <Container>
            <Card>
        <div className="formdivss">


        <div className="formdivcol"><h3> Sub Master</h3>
                <div>
                    <Link to="/coagroupnature">COA Group Nature</Link>
                </div>   
               

            </div>
        

            <div className="formdivcol"><h3>Master</h3>
                    <div>
                    <Link to="/currency">Currency</Link>
                    </div>
                        <div>
                    <Link to="/coagroup">COA Group</Link>
                    </div>   
                    <div>
                        <Link to="/coaledger">COA Ledger</Link>
                    </div>
            </div>
          
        </div>
        </Card>
        </Container>
        
        
   
    )
    
   
  
   


}

export default GeneralLedgersite;   