import React, {Fragment, useEffect, useState, useRef} from "react";
import { Container, Row, Col, Card, CardBody, Form, FormGroup, Label, Table, Input, Button,InputGroup ,InputGroupText } from 'reactstrap';
import Select from 'react-select';
import { Breadcrumbs ,H2, H3, H4, H5,H6} from '../../../../AbstractElements';
import { IoArrowBackOutline, IoArrowForward  } from "react-icons/io5";
import HeaderCard from '../../../Common/Component/HeaderCard';
import Swal from 'sweetalert2';
import { IoTrash } from "react-icons/io5";
import DataTable from 'react-data-table-component';
import '../forms.css';
import axiosInstance from '../../../../Auth/AxiosInstance';
import Highlighter from 'react-highlight-words';
import { FaRegEdit,FaSearch } from "react-icons/fa";
import { IoMdClose } from "react-icons/io";
import { Tabs, Tab, Box, Typography } from '@mui/material';
import classNames from "classnames";
import { Category, FontSize, FontWeight, MarginLeft, MarginRight, MarginTop } from '../../../../Constant';

function Taxdesign(){
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [under, setUnder] = useState(null);
    const [categories, setCategories] = useState([]);
    const [clearRows, setClearRows] = useState(false);
    const [selectedIds, setSelectedIds] = useState([]);
    const [isEditing, setIsEditing] = useState(false);
    const [editId, setEditId] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);
    const [selectOptions, setSelectOptions] = useState([]);
    const [selectnatureOptions, setSelectnatureOptions] = useState([]);
    const [selectbasisOptions, setSelectbasisOptions] = useState ([]);
    const [selectelementOptions, setSelectelementOptions] = useState([]);
    const [nameError1 , setNameError1] = useState(null);
    const [descriptionError1, setDescriptionError1] = useState(null);
    const [nameError2 , setNameError2] = useState(null);
    const [nameError3 , setNameError3] = useState(null);
    const [descriptionError2, setDescriptionError2] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [loading, setLoading] = useState(true);
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredCategories, setFilteredCategories] = useState([]);
    const [submitbtn, setSubmintbtn] =useState('Save');
    const [opt1 , setOpt1] = useState([]);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [shouldFocusName, setShouldFocusName] = useState(false);
    const cancelButtonRef = useRef(null);
    const submitButtonRef = useRef(null);
    const [submittedRows, setSubmittedRows] = useState([]);
    const [filteredPaymentBase, setFilteredPaymentBase] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [taxcluster, setTaxCluster] = useState('');
    const [tax_design_number, setTaxDesignNumber] = useState('');
    const [grid_view, setGridView] = useState(false);
    const [addedRows, setAddedRows] = useState([]);
    const [checked, setChecked] = useState(false);
    const [rows, setRows] = useState([{
      index: 1,
      tax_element: '',
      description: '',
      load_on_inventory: false,
      tax_nature: '',
      chargeable_basis: '',
      calculation_factors: '',
      tax_design_details_number: '',
  }]);
    const load_on_inventory = [{ value: 'yes', label: 'Yes' },
      { value: 'no', label: 'No' }];
  
    const rowsPerPage = 10;
    const customStylesTable = {
      header: {
        style: {
          minHeight: '40px',
          backgroundColor: '#f0f0f0',
          color: '#333',
        },
      },
      headRow: {
        style: {minHeight: '40px',
          backgroundColor: '#f0f0f0',
        },
      },
      headCells: {
        style: {
          fontSize: '12px',
          fontWeight: 'bold',
          textAlign: 'left',
          color: '#000',
        },
      },
      rows: {
        style: {
          minHeight: '40px',
          borderBottom: '1px solid #ddd',
        },
      },
      cells: {
        style: {
          textAlign: 'left',
          fontSize:'12px'
        },
      },
    };
    
    const customStyles = {
      control: (provided, state) => ({
          ...provided,
          minHeight: '30px',
          borderColor: state.isFocused ? 'blue' : '#afafaf', // Change border color on focus
          boxShadow: state.isFocused ? '0 0 0 1px blue' : 'none', // Optional: Add shadow on focus
          '&:hover': {
              borderColor: state.isFocused ? 'blue' : 'black', // Keep the same color on hover
          },
      }),
      placeholder: (provided) => ({
          ...provided,
          fontSize: '12px', // Change the font size for the placeholder
          color: '#aaa', // Optional: Change placeholder color
        }),
      valueContainer: (provided) => ({
          ...provided,
          height: '30px', // Adjust the container height
          padding: '0 6px',
        }),
        input: (provided) => ({
          ...provided,
          margin: '0px', // Remove default margin for the input inside the select
        }),
        indicatorsContainer: (provided) => ({
          ...provided,
          height: '30px', // Adjust the indicator container height
        }),

        header: {
          style: {
            minHeight: '40px', // Adjust header height
            backgroundColor: '#f0f0f0', // Light grey background for the header
            color: '#333', // Text color
          },
        },
        headRow: {
          style: {
            minHeight: '40px',
            backgroundColor: '#f0f0f0', // Grey color for the table header row
          },
        },
        headCells: {
          style: {
    
            fontSize: '12px', // Font size for header cells
            fontWeight: 'bold',
            textAlign: 'left',
            color: '#000', // Header text color
          },
        },
        rows: {
          style: {
            minHeight: '40px', // Minimum height of rows
            borderBottom: '1px solid #ddd', // Bottom border for rows
          },
        },
        cells: {
          style: {
            fontSize: '12px',
            textAlign: 'left',
          },
        },
  };
  
  // const paginatedData = Array.isArray(submittedRows)
  // ? submittedRows.slice((currentPage - 1) * rowsPerPage, currentPage * rowsPerPage)
  // : [];

  const paginatedData = Array.isArray(categories) ? categories.slice((currentPage - 1) * rowsPerPage, currentPage * rowsPerPage) : [];

  console.log(filteredPaymentBase)
  const columns = [
    {
      name: (<div className='dataTableDiv'>SN</div>),
      selector: (row, index) => index + 1 + (currentPage - 1) * rowsPerPage,
      width:'70px',
      cell:(row, index) =>(
        <div className='dataTableDiv' style={{textAlign: 'left !important',display:"flex", justifyContent:"flex-start !important" ,paddingLeft:"4px"}}>{index + 1 + (currentPage - 1) * rowsPerPage}</div>
      )
    },
    {
      name:(<div className='dataTableDiv'>Tax Design Number</div>),
      selector: row => row.tax_design_number.toString(),
      cell: row => (
        <div style={{textAlign: 'left !important',display:"flex", justifyContent:"flex-start !important" ,paddingLeft:"12px"}}>
          <Highlighter
            highlightClassName="highlighted-text"
            className='dataTableDiv'
            searchWords={[searchTerm]}
            autoEscape={true}
            textToHighlight={row.tax_design_number.toString()}
        />
        </div>
      ),
    },
    {
      name: (<div className='dataTableDiv'>Tax Cluster</div>),
      selector: row => row.taxcluster_name,
      cell: row => (
        <div style={{textAlign: 'left !important',display:"flex", justifyContent:"flex-start !important" ,paddingLeft:"4px"}}>
          <Highlighter
            highlightClassName="highlighted-text"
            className='dataTableDiv'
            searchWords={[searchTerm]}
            autoEscape={true}
            textToHighlight={row.taxcluster_name}
        />
        </div>
      ),
    },
    {
      name: (
        <div className='dataTableDiv'>Description</div>
      ),
      selector: row => row.description,
      width:"auto",
      cell: row => (
        <div style={{textAlign: 'left !important',display:"flex", justifyContent:"flex-start !important" ,paddingLeft:"4px"}}>
          <Highlighter
            highlightClassName="highlighted-text"
            className='dataTableDiv'
            searchWords={[searchTerm]}
            autoEscape={true}
            textToHighlight={row.description}
        />
        </div>
      ),
    },
    {
      name: (<div className='dataTableDiv'>Action</div>),
      center: true,
      width:'100px',
      cell: row => (
        <div className="buttonven">
          <button
            className="vendorupdate"
            color='primary'
            title='Edit'
            onClick={() => handleUpdateClick(row)}
          >
            <FaRegEdit className="vendortrash"/>
          </button>
          <button title='Delete' className="vendordele" onClick={() => handleDeleteCategory(row.id)}>
            <IoTrash className="vendortrash" />
          </button>
        </div>
      ),
    },
  ];

  
    const defaultSortField = 'Tax';
    const defaultSortAsc = true;
  
    useEffect(() => {
      if (searchTerm.trim() === '') {
        setFilteredCategories(categories || []);
      } else {
        const safeCategories = Array.isArray(categories) ? categories : [];
        const filteredData = safeCategories.filter(item =>
          item.name && item.name.toLowerCase().includes(searchTerm.toLowerCase())
        );
        setFilteredCategories(filteredData);
      }
    }, [searchTerm, currentPage, categories]);
  
    useEffect(() => {
      fetchCategories(currentPage);
      fetchSelectOptions();
      fetchTaxnatureOptions();
      fetchBasisOptions();
      fetchElementOptions();
    }, [currentPage,searchTerm]); 
  
    const fetchCategories = async (page) => {
      setLoading(true);
      try {
        let response;
        if (searchTerm) {
            response = await axiosInstance.get(`taxation/taxdesign/search/?name=${searchTerm}`);
        } else {
            response = await axiosInstance.get(`taxation/taxdesign/get/`);
        }
        setCategories(response.data);
        setFilteredCategories(response.data); 
      } catch (error) {
        if (error.response && error.response.status === 404 && page > 1) {
          handlePageChange(page - 1);
        } else {
          console.error("Error fetching categories:", error);
        }
      }finally {
        setLoading(false);
      }
    };
    const handlePageChange = (page) => {
      setCurrentPage(page);
      fetchCategories(page);
    };
    const fetchSelectOptions = async (editId = null) => {
      try {
        const response = await axiosInstance.get('taxation/taxcluster/get/');
        console.log(editId,'snckdsvv');
        const opt = response.data.map(category => ({
          value: category.id,
          label: category.name,
          description: category.description
        }));
        setOpt1(opt);
        console.log(opt,'options');
        const filteredOptions = response.data
        .filter(category => category.id !== editId)
        .map(category => ({
          value: category.id,
          label: category.name,
          description: category.description
        }));
        setSelectOptions([...filteredOptions]);
      } catch (error) {
        console.error('Error fetching select options:', error);
      }
    };
    const fetchTaxnatureOptions = async (editId = null) => {
      try {
        const response = await axiosInstance.get('taxation/gsttaxnature/get/');
        console.log(editId,'snckdsvv');
        const filteredOptions = response.data
        .filter(category => category.id !== editId) // Exclude the category being edited
        .map(category => ({
          value: category.id,
          label: category.name,
        }));
        setSelectnatureOptions([...filteredOptions]);
      } catch (error) {
        console.error('Error fetching select options:', error);
      }
    };

    const fetchBasisOptions = async (editId = null) => {
      try {
        const response = await axiosInstance.get('taxation/gstchargablebasis/get/');
        console.log(editId,'snckdsvv');
        const filteredOptions = response.data
        .filter(category => category.id !== editId) // Exclude the category being edited
        .map(category => ({
          value: category.id,
          label: category.name,
        }));
        setSelectbasisOptions([...filteredOptions]);
      } catch (error) {
        console.error('Error fetching select options:', error);
      }
    };

    const fetchElementOptions = async (editId = null) => {
      try {
        const response = await axiosInstance.get('taxation/taxelement/get/');
        console.log(editId,'snckdsvv');
        const opt = response.data.map(category => ({
          value: category.id,
          label: category.tax_element,
          description: category.description
        }));
        setOpt1(opt);
        console.log(opt,'options');
        const filteredOptions = response.data
        .filter(category => category.id !== editId)
        .map(category => ({
          value: category.id,
          label: category.tax_element,
          description: category.description
        }));
        setSelectelementOptions([...filteredOptions]);
      } catch (error) {
        console.error('Error fetching select options:', error);
      }
    };
  
    const handleSubmit = async (e) => {
      e.preventDefault();
      const rowEmptyFieldsMessages = rows
      .map((row, index) => {
      const missingFields = [];
      if (!row.tax_element) missingFields.push("Tax Element");
      // if (!row.description) missingFields.push("Description");
      // if (!row.load_on_inventory) missingFields.push("Load On Inventory");
      if (!row.tax_nature) missingFields.push("Tax Nature");
      if (!row.chargeable_basis) missingFields.push("Chargeable Basis");
      if (!row.calculation_factors) missingFields.push("Calculation Factors");
      if (!row.tax_design_details_number) missingFields.push("Tax Design Details Number");
  
      if (missingFields.length > 0) {
          return `Row ${index + 1} missing fields: ${missingFields.join(", ")}`;
      }
      return null;
      })
      .filter(Boolean);
      if (rowEmptyFieldsMessages.length > 0) {
        alert(`Tax Details Data:\n${rowEmptyFieldsMessages.join("\n")}`);
    }else{
      const payload = {
        taxcluster: under? under.value: null,
        description,
        tax_design_number:tax_design_number,
        grid_view: rows,
      };
      setNameError1(null);
      setDescriptionError1(null);
      try {
        
        if (isEditing) {
          await axiosInstance.put(`taxation/taxdesign/${editId}/`, payload);
          setIsEditing(false);
          // Swal.fire({
          //   title: 'Success!',
          //   text: 'Item Category has been updated successfully.',
          //   icon: 'success',
          //   confirmButtonText: 'OK'
          // });
        } else {
          await axiosInstance.post('taxation/taxdesign/create/', payload);
          setErrorMessage(null);
          // Swal.fire({
          //   title: 'Success!',
          //   text: 'Item Category has been saved successfully.',
          //   icon: 'success',
          //   confirmButtonText: 'OK'
          // });
        }
        fetchCategories(currentPage);
        fetchSelectOptions();
        fetchTaxnatureOptions();
        fetchBasisOptions();
        fetchElementOptions();
        setTaxDesignNumber();
        setRows();
        resetForm();
       
      } catch (error) {
        console.error('Error:', error);
        if (error.response && error.response.data) {
          const nameError = error.response.data.name && Array.isArray(error.response.data.name) ? error.response.data.name[0] : null;
          const descriptionError = error.response.data.description && Array.isArray(error.response.data.description) ? error.response.data.description[0] : null;
          const existsError = error.response.data.error && Array.isArray(error.response.data.error) ? error.response.data.error[0] : null;
          console.log(nameError, 'Name Error');
          console.log(descriptionError, 'Description Error');
          if (nameError && descriptionError) {
          } else if (nameError) {
            setNameError1(nameError)
          } else if (descriptionError) {
            setDescriptionError1(descriptionError);
          } else if (existsError) {
            setErrorMessage(existsError);
   
          }
        } else {
          setErrorMessage('An error occurred while submitting the form.');
        }
      }
    }
    };
  
    const handleUpdateClick = (category) => {
      resetForm();
      setNameError1(null);
      setNameError3(null);
      setEditId(category.id);
      setNameError2(null);
      setDescriptionError2(null);
      setName('');
      setDescription('');
      setTaxDesignNumber('');
      setRows('')
      setIsEditing(false);
      // setUnder(null);
      setNameError1(null);
      setClearRows(!clearRows);
      setSubmintbtn('Save')
      setDescriptionError1(null);
      setErrorMessage('');
      setNameError1(null);
      setDescriptionError1(null);
      setUnder(category.taxcluster_name);
      setDescription(category.description);
      setTaxDesignNumber(category.tax_design_number);
      setSubmintbtn('Update');
      console.log(category.id,'nbhgvcsfdnhvbjmb');
      setUnder({ value: category.taxcluster, label: category.taxcluster_name });
      setIsEditing(true);
      fetchSelectOptions(category.id);
      fetchTaxnatureOptions(category.id);
      fetchBasisOptions(category.id);
      fetchElementOptions(category.id);
      setErrorMessage('');
      setRows(category.grid_view.map((rows) =>{
        const taxElement_des = selectelementOptions.find(option => option.value === rows.tax_element);
        return{
          index: rows.index,
          tax_element: rows.tax_element,
          description: taxElement_des? taxElement_des.description:'',
          load_on_inventory: rows.load_on_inventory,
          tax_nature: rows.tax_nature,
          chargeable_basis: rows.chargeable_basis,
          calculation_factors: rows.calculation_factors,
          tax_design_details_number: rows.tax_design_details_number,
        }
      }))
    };
  
    const resetForm = () => {
      setNameError2(null);
      setNameError3(null);
      setDescriptionError2(null);
      setName('');
      setDescription('');
      setIsEditing(false);
      setUnder(null);
      setTaxDesignNumber('');
      setNameError1(null);
      setClearRows(!clearRows);
      setSubmintbtn('Save')
      setDescriptionError1(null);
      setEditId(null);
      setErrorMessage('');
      setRows([{ 
      index: 1,
      tax_element: '',
      description: '',
      load_on_inventory: false,
      tax_nature: '',
      chargeable_basis: '',
      calculation_factors: '',
      tax_design_details_number: '', }]);
    };
  
    const handleCheckboxChange = (selectedRows, e, rowIndex) => {
      // If it's a row selection change (selectedRows)
      if (selectedRows) {
        setSelectedIds(selectedRows.selectedRows.map(row => row.id));
      } 
      // If it's a checkbox change on a specific row (rowIndex and e.target.checked)
      else if (rowIndex !== undefined) {
        const updatedRows = [...rows];
        updatedRows[rowIndex].load_on_inventory = e.target.checked;
        setRows(updatedRows);
      }
    };
    
    const handleDelete = async () => {
      try {
        // const response = await axiosInstance.get('inventory/itemcategory/get/');
        // const categories = response.data;
  
        // const categoryMap = new Map(categories.map(cat => [cat.id, cat.name]));
  
        // const selectedNames = selectedIds.map(id => categoryMap.get(id)).filter(name => name);
  
        // const escapeHtml = (text) => {
        //   return text.replace(/[&<>"']/g, (match) => {
        //     const escape = {
        //       '&': '&amp;',
        //       '<': '&lt;',
        //       '>': '&gt;',
        //       '"': '&quot;',
        //       "'": '&#039;'
        //     };
        //     return escape[match];
        //   });
        // };
  
        // const namesText = selectedNames.map(name => `<strong>${escapeHtml(name)}</strong>`).join(', ');
  
        // const result = await Swal.fire({
        //   title: 'Are you sure?',
        //   html: `You are about to delete the following categories: ${namesText}. You won't be able to revert this!`,
        //   icon: 'warning',
        //   showCancelButton: true,
        //   confirmButtonColor: '#3085d6',
        //   cancelButtonColor: '#d33',
        //   confirmButtonText: 'Yes, delete it!'
        // });
  
        // if (result.isConfirmed) {
          await axiosInstance.delete('taxation/taxdesign/delete/', {
            data: { ids: selectedIds },
          });
          fetchCategories(currentPage);
          setSelectedIds([]);
          fetchSelectOptions();
          fetchTaxnatureOptions();
          fetchBasisOptions();
          fetchElementOptions();
          resetForm();
        // }
      } catch (error) {
        console.error('Error:', error.response.data);
        const errorMessage = JSON.stringify(error.response.data.detail, null, 2);
        Swal.fire('Error!',errorMessage , 'error');
      }
    };
  
    const handleDeleteCategory = async (id) => {
      // const result = await Swal.fire({
      //   title: 'Are you sure?',
      //   text: `You won't be able to revert this!`,
      //   icon: 'warning',
      //   showCancelButton: true,
      //   confirmButtonColor: '#3085d6',
      //   cancelButtonColor: '#d33',
      //   confirmButtonText: 'Yes, delete it!'
      // });
  
      // if (result.isConfirmed)
       {
        try {
          await axiosInstance.delete(`taxation/taxdesign/${id}/`);
          fetchCategories(currentPage);
        } catch (error) {
          console.error('Error deleting category:', error.response.data);
          const errorMessage = JSON.stringify(error.response.data.detail, null, 2);
          Swal.fire('Error!', errorMessage, 'error');
        }
  
        setSelectedIds([]);
        fetchSelectOptions();
        fetchTaxnatureOptions();
        fetchBasisOptions();
        fetchElementOptions();
        resetForm();
      }
    };
  
    const getHighlightedText = (text, highlight) => {
      const escapedHighlight = highlight.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&');
      const parts = text.split(new RegExp(`(${escapedHighlight})`, 'gi'));
      return (
        <span>
          {parts.map((part, index) =>
            part.toLowerCase() === highlight.toLowerCase() ? (
              <span key={index} style={{fontWeight:'bold' }}>{part}</span>
            ) : (
              part
            )
          )}
        </span>
      );
    };
    const validateName = (value) => {
      console.log(value,'kjhvckjsdnv');
      
     if (value.match('  ')) {
        setNameError1('Continuous spaces are not allowed ');
        return false;
      }  else if(value.length === 30){
        setNameError2(null);
      }else {
        setNameError1(null);
        return true;
      }
    };
  
    const handleNameChange = (e) => {
        let value = e.target.value;
        value = value.replace(/\s+/g,' ').trimStart();
        setName(value);
        validateName(value); 
    };
  
  const handleEnter = (event) => {
    if (event.target && nameError1 && (event.key === 11 || event.key === 13)) {
      event.target.focus();  
    } 
    else if (event.keyCode === 13 && event.target.form) {
      if (event.target.tagName === 'INPUT' || event.target.tagName === 'TEXTAREA') {
        event.target.value = event.target.value.trim();
      }
      if (dropdownOpen) {
        return; 
      } else {
        const form = event.target.form;
        const index = Array.prototype.indexOf.call(form.elements, event.target);
        const nextElement = form.elements[index + 1];
        if (nextElement) {
          nextElement.focus();
        }
        event.preventDefault();
      }
    }
    const { value } = event.target;
    if (
      (event.key === ' ' && value.length === 0) || 
      (event.key === ' ' && value.endsWith(' ')) || 
      (event.key === ' ' && value.includes('  '))
    ) {
      event.preventDefault();
      return;
    }
  };
  
  const handleArrowNavigation = (event, buttonType) => {
    if (event.key === 'ArrowRight' && buttonType === 'save' && cancelButtonRef.current) {
      cancelButtonRef.current.focus();
    } else if (event.key === 'ArrowLeft' && buttonType === 'cancel' && submitButtonRef.current) {
      submitButtonRef.current.focus();
    }
  };
    const handleBlur = (e) => {
      setNameError2(null);
      setDescriptionError2(null);
      if (e.target.tagName === 'INPUT' || e.target.tagName === 'TEXTAREA') {
        e.target.value = e.target.value.trim();  // Ensures the value is trimmed properly
        
        if (nameError1) {
            e.target.focus();
        }else if (descriptionError1) {
          e.target.focus(); 
        }
      }
    };
    const handleDescriptionFocus = () => {
      // Perform name validation when switching to the description field
      if(isEditing){
        const otherCategories = categories.filter(category => category.id !== editId);
        console.log(otherCategories,'is editing');
        if (Array.isArray(otherCategories) && otherCategories.some((item) => item.name.replace(/\s+/g,'').toLowerCase() === name.replace(/\s+/g,'').toLowerCase())) {
          setShouldFocusName(true);
          setNameError3('Item category with this name already exists.');
          setTimeout(() => {
            const nameInput = document.getElementById('nameInput');
            if (nameInput) {
              nameInput.focus(); // Set focus on the input with id 'nameInput'
            }
        }, 0);
        }
        else {
          setNameError3(null);
        }
        
      }else{
        if(Array.isArray(categories) && categories.some((item) => item.name.replace(/\s+/g,'').toLowerCase() === name.replace(/\s+/g,'').trim().toLowerCase())){
          setShouldFocusName(true);
          setNameError3('Item category with this name already exists.');
          setTimeout(() => {
            const nameInput = document.getElementById('nameInput');
            if (nameInput) {
              nameInput.focus(); // Set focus on the input with id 'nameInput'
            }
          }, 0);
          return false;
        }else {
          setNameError3(null);
        }
  
      }
    };

const handleAddRow = (event) => {
    event.preventDefault(); 
    setIsOpen(true);
    const newRow = {
        index: 1,  
        tax_element: '',
        description: '',
        load_on_inventory: false,
        tax_nature: '',
        chargeable_basis: '',
        calculation_factors: '',
        tax_design_details_number: '',
    };
    console.log('Current Rows:', rows);
};

const handleInputChange = (index, event) => {
  const { name, value } = event.target;
  const values = [...rows];
  values[index][name] = value;
  console.log(values,"_+_=_+_+_++__");
  if (name === 'tax_element') {
    const id = values[index].tax_element
    const tax_value = +id;
    const selectedOption = selectelementOptions.find(option => option.value === tax_value);
    if (selectedOption) {
      values[index].description = selectedOption.description;
      console.log(values[index].description = selectedOption.description,"kjefnjk_____+++++++")
    }
  }
  setRows(values);
};

const handleDeleteRow = (index) => {
  const updatedRows = rows.filter((_, i) => i !== index);
  const reindexedRows = updatedRows.map((row, i) => ({
    ...row,
    index: i + 1, // Reindex starting from 1
  }));
  
  setRows(reindexedRows);
};


const handleAbatementsubmit = (event) => {
  event.preventDefault();
  console.log(rows);
  setSubmittedRows([...submittedRows, ...rows]); 
  setRows([{
      index: 1,
      tax_element: '',
      description: '',
      load_on_inventory: false,
      tax_nature: '',
      chargeable_basis: '',
      calculation_factors: '',
      tax_design_details_number: '',
  }]);
  setIsOpen(false); 
};

const handleSelectChange = (selectOptions) => {
  setUnder(selectOptions); 
  if (selectOptions) {
    setDescription(selectOptions.description); 
  }
};

const addRow = () => {
  const newRow = {
    tax_element: '',
    description: '',
    load_on_inventory: false,
    tax_nature: '',
    chargeable_basis: '',
    calculation_factors: '',
    tax_design_details_number: '',
    index: rows.length + 1,
    // index: '',
  };
  setRows((prevRows) => [...prevRows, newRow]);
        setAddedRows((prevAddedRows) => [...prevAddedRows, newRow]);
  if (isNaN(newRow.index) || newRow.index <= 0) {
    console.error("Invalid index value:", newRow.index);
    return;  
  }

  setRows([...rows, newRow]);
};

const handleTaxDesignNumberChange = (e) => {
  setTaxDesignNumber(e.target.value);
};

const handleSave = ()=>{
  const rowEmptyFieldsMessages = rows
  .map((row, index) => {
  const missingFields = [];
  if (!row.tax_element) missingFields.push("Tax Element");
  // if (!row.description) missingFields.push("Description");
  // if (!row.load_on_inventory) missingFields.push("Load On Inventory");
  if (!row.tax_nature) missingFields.push("Tax Nature");
  if (!row.chargeable_basis) missingFields.push("Chargeable Basis");
  if (!row.calculation_factors) missingFields.push("Calculation Factors");
  if (!row.tax_design_details_number) missingFields.push("Tax Design Details Number");

  if (missingFields.length > 0) {
      return `Row ${index + 1} missing fields: ${missingFields.join(", ")}`;
  }
  return null;
  })
  .filter(Boolean);
  if (rowEmptyFieldsMessages.length > 0) {
    alert(`Tax Details Data:\n${rowEmptyFieldsMessages.join("\n")}`);
}else{
  setIsOpen(false);
}
}

const handleClose =()=>{
  setRows((prevRows) => prevRows.filter((row) => !addedRows.includes(row)));
  setAddedRows([]); // Reset added rows
  setIsOpen(false);
}


  return (
      <Fragment>
          <Breadcrumbs mainTitle="Tax Design" parent="Taxation" title="Tax Design" />
          <Container fluid={true} className='Container1'>
              <Form className="theme-form" onSubmit={handleSubmit}>
                  <Col sm="12" xl="6" className='vendorcol2a'>
                      <Card>
                          <div className='formdiv1ba'>
                              <div className='rowForm1'>
                                  <div className='formcol1'>
                                      <div className='formin1'>
                                          <Label style={{fontWeight:"500"}} className="col-form-label">Tax Cluster</Label>
                                          <Select
                                              className="forminput"
                                              value={under}
                                              onMenuOpen={() => setDropdownOpen(true)}
                                              onMenuClose={() => setDropdownOpen(false)}
                                              styles={customStyles}
                                              onChange={handleSelectChange}
                                              options={selectOptions}
                                              filterOption={(option, inputValue) =>
                                                option.label.toLowerCase().startsWith(inputValue.toLowerCase())
                                              }
                                              formatOptionLabel={(option, { inputValue }) =>
                                                getHighlightedText(option.label, inputValue)
                                              }
                                              required
                                          />
                                      </div>
                                      <FormGroup className='formin1'>
                                          <Label style={{fontWeight:"500"}} className="col-form-label">Description</Label>
                                          <Input
                                                value={description} 
                                                onBlur={handleBlur}
                                                onChange={handleSelectChange}
                                                className="forminput"
                                                type="text"
                                                style={{ color: 'black', borderColor: '#afafaf' }}
                                                readOnly
                                          />                                    
                                      </FormGroup>
                                      <FormGroup className='formin1'>
                                          <Label style={{fontWeight:"500"}} className="col-form-label">Tax Design Number</Label>
                                          <Input  onBlur={handleBlur} className="forminput" type="number" style={{color:"black",borderColor: "#afafaf"}} value={tax_design_number} onChange={handleTaxDesignNumberChange} onInput={(e) => {if ( /[eE]/.test(e.target.value)) {e.target.value = e.target.value.replace(/[eE ]/g, "");}}} onKeyDown={(event)=>{if (event.key === "e" || event.key === "E" || event.key === "+" || event.key === "-") {event.preventDefault();}handleEnter(event)}} required/>
                                      </FormGroup>
                                  </div>
                                  <br></br>
                                      <div className="subdiv">
                                          <div className="vengirdbtn">
                                        <Button className='btn2a' color='primary' type='button' style={{marginRight: 'auto', marginBottom: '20px'}} onClick={handleAddRow}>Tax Design Details</Button>
                                          </div>
                                      </div>
                                      <div className="vengirdbtn1">
                                                <button className='btn2' color='primary' type='submit'>Save</button>
                                                <Button className='btn1' type='button' onClick={resetForm}>Cancel</Button>
                                      </div>
                              </div>
                          </div>
                          
                      </Card>
                  </Col>
              </Form>
          </Container>
         <Container fluid={true} className='Container1'>
         <Col sm="12" xl="6" className='vendorcol2a'>
                        <Card className=''>
                            <div style={{ display: "flex" }}>
                                <div className='headercl'>
                                <div className="h5cus">
                                   <h5 style={{paddingTop:"25px", paddingLeft:"30px"}} className="h5title">Tax Design List</h5>
                                </div>
                                </div>
                                <div className='selectdel' style={{ display: "flex", alignItems: "center", justifyContent: "flex-end", width: "40%" }}>
                                    <Button color="danger" className='btn1 mt-4' onClick={handleDelete} disabled={selectedIds.length === 0}><IoTrash className="vendortrash1" />Delete</Button>
                                </div>
                            </div>
                        <CardBody>
                            <div className="table-responsive">
                                 <InputGroup className="mb-3">
                              <Input
                                  type="text"
                                  placeholder="Search"
                                  value={searchTerm}
                                  onChange={(e) => setSearchTerm(e.target.value)}
                                  className="form-control input-no-animation inputfoc"
                                  style={{borderColor: "#afafaf" }}
                              />
                              <InputGroupText >
                                  <FaSearch />
                              </InputGroupText>
                          </InputGroup>
                          <DataTable
                                columns={columns}
                                data={paginatedData}
                                selectableRows
                                selectableRowsSelected={selectedIds}
                                onSelectedRowsChange={handleCheckboxChange}
                                clearSelectedRows={clearRows}
                                pagination
                                paginationServer
                                customStyles={customStylesTable}
                                striped={false}
                                paginationTotalRows={categories.length}
                                paginationPerPage={rowsPerPage}
                                progressPending={loading}
                                autoWidth={true}
                                paginationComponentOptions={{
                                  noRowsPerPage: true,
                                }}
                                onChangePage={handlePageChange} 
                                highlightOnHover
                          />
                            </div>
                        </CardBody>
                        </Card>
                    </Col>
          </Container>
          <div>
          {isOpen && (
                <div className="popup">
                    <div className="popup-inner" style={{ width: '80%',  margin: 'auto' }} >
                  <div style={{justifyContent: 'space-between',display: 'flex'}}>
                    <div className="h5cus">
                      <h5 className="h5title">Tax Design Details</h5> 
                    </div>
                         <button className='popclsbtn' type='button' onClick={handleClose}><IoMdClose className='clsico' color='red'  /></button>
                  </div>  
                        <div className="table-container">
                            <table style={{width:"100%"}}>
                                <thead>
                                    <tr style={{height:"20px"}}>
                                      <th style={{textAlign:"center",width:"50px"}}>Index</th>
                                      <th style={{textAlign:"center"}}>Tax Element</th>
                                      <th style={{textAlign:"center"}}>Description</th>
                                      <th style={{textAlign:"center"}}>Load On Inventory</th>
                                      <th style={{textAlign:"center"}}>Tax Nature</th>
                                      <th style={{textAlign:"center"}}>Chargeable Basis</th>
                                      <th style={{textAlign:"center"}}>Calculation Factors</th>
                                      <th style={{textAlign:"center"}}>Tax Design Details Number</th>
                                      <th style={{textAlign:"center"}}>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                        {rows.map((row, index) => (
                                        <tr key={index} style={{height:"20px"}}>
                                          <td>
                                            <input
                                                type="text"
                                                value={row.index}
                                                onChange={(e) => handleInputChange(index, e)}
                                                onBlur={handleBlur}
                                                style={{height:"100%" , padding:0,width:"100%",textAlign:"center"}}
                                                // onChange={(e) => handleInputChange(index, e)}
                                                readOnly
                                            />
                                          </td>
                                            <td>
                                            <div className="table_td_div">
                                                <select
                                                    type="text"
                                                    name="tax_element"
                                                    value={row.tax_element}
                                                    style={{height:"100%" , padding:0,width:"100%",border:"none",color:"black"}}
                                                    onChange={(e) => handleInputChange(index, e)}
                                                    required
                                                >
                                                  <option value={''} disabled selected>Select Category</option>
                                                    {selectelementOptions.map((option)=>(
                                                        <option key={option.value} value={option.value}>{option.label}</option>
                                                    ))}
                                                </select>
                                                </div>
                                            </td>
                                            <td>
                                            <div className="table_td_div">
                                                  <input
                                                  type="text"
                                                  value={row.description}
                                                  onBlur={handleBlur}
                                                  style={{height:"100%" , padding:0,width:"100%"}}
                                                  // onChange={(e) => handleInputChange(index, e)}
                                                  readOnly
                                              />
                                            </div>    
                                            </td>
                                            <td>
                                            <div className="table_td_div">
                                                    <input
                                                        type="checkbox"
                                                        name="load_on_inventory"
                                                        checked={row.load_on_inventory}
                                                        onChange={(e) => handleCheckboxChange(null, e, index)}
                                                        style={{height:"100%" , padding:0,width:"100%"}}
                                                        />
                                                {row.load_on_inventory && (
                                                    <input
                                                        type="number"
                                                        onInput={(e) => {if ( /[eE]/.test(e.target.value)) {e.target.value = e.target.value.replace(/[eE ]/g, "");}}} onKeyDown={(event)=>{if (event.key === "e" || event.key === "E" || event.key === "+" || event.key === "-") {event.preventDefault();}handleEnter(event)}}
                                                        name="load_on_inventory"
                                                        placeholder="%"
                                                        style={{height:"100%" , padding:0,width:"100%"}}
                                                        value={row.load_on_inventory}
                                                        onChange={(e) => handleInputChange(index, e)}
                                                        required={row.load_on_inventory}
                                                    />
                                                )}
                                            </div>
                                        </td>

                                            <td>
                                              <div className="table_td_div">
                                                <select
                                                    type="text"
                                                    name="tax_nature"
                                                    style={{height:"100%" , padding:0,width:"100%",border:"none",color:"black"}}
                                                    value={row.tax_nature}
                                                    onChange={(e) => handleInputChange(index, e)}
                                                    required
                                                >
                                                    <option value={''} disabled selected>Select Category</option>
                                                    {selectnatureOptions.map((selectnatureOptions)=>(
                                                        <option key={selectnatureOptions.value} value={selectnatureOptions.value}>{selectnatureOptions.label}</option>
                                                    ))}
                                                </select>
                                              </div>
                                            </td>
                                            <td>
                                            <div className="table_td_div">
                                                <select
                                                    type="text"
                                                    name="chargeable_basis"
                                                    value={row.chargeable_basis}
                                                    style={{height:"100%" , padding:0,width:"100%",border:"none",color:"black"}}
                                                    onChange={(e) => handleInputChange(index, e)}
                                                    required
                                                >
                                                  <option value={''} disabled selected>Select Category</option>
                                                    {selectbasisOptions.map((selectbasisOptions)=>(
                                                        <option key={selectbasisOptions.value} value={selectbasisOptions.value}>{selectbasisOptions.label}</option>
                                                    ))}
                                                </select>
                                            </div>    
                                            </td>
                                            <td>
                                            <div className="table_td_div">
                                                <input
                                                    type="text"
                                                    name="calculation_factors"
                                                    value={row.calculation_factors}
                                                    maxLength='50'
                                                    style={{height:"100%" , padding:0,width:"100%"}}
                                                    onChange={(e) => handleInputChange(index, e)}
                                                    required
                                                />
                                            </div>
                                            </td>
                                            <td>
                                            <div className="table_td_div">
                                                <input
                                                    type="number"
                                                    name="tax_design_details_number"
                                                    value={row.tax_design_details_number}
                                                    style={{height:"100%" , padding:0,width:"100%"}}
                                                    onChange={(e) => handleInputChange(index, e)}
                                                    onInput={(e) => {if ( /[eE]/.test(e.target.value)) {e.target.value = e.target.value.replace(/[eE ]/g, "");}}} onKeyDown={(event)=>{if (event.key === "e" || event.key === "E" || event.key === "+" || event.key === "-") {event.preventDefault();}handleEnter(event)}}
                                                    required
                                                />
                                            </div>    
                                            </td>
                                            <td>
                                              <button style={{ cursor: 'pointer', opacity: 1,display:"flex", width:"100%", alignItems:"center", justifyContent:"center"}} title='Delete' type="button" className="vendordele1a" onClick={() => handleDeleteRow(index)}>
                                                  <IoTrash className="vendortrash" />
                                              </button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                                </table>
                                </div>
                                <div style={{display:"flex"}} className="mt-3">
                                    <div style={{width:"50%"}}>
                                        <Button onClick={addRow} color='primary' className='btn2a'>
                                            Add Row
                                        </Button>
                                    </div>
                                    <div className='popbtn' style={{width:"50%"}}>
                                        <Button className='btn2a' color='primary' type='button' onClick={handleSave}>Save</Button>{' '}
                                        {/* <Button className='btn1ab' type="button" onClick={handleCancelAbatement}>Close</Button> */}
                                    </div>
                                </div>
                    </div>
                </div>
            )}
            </div>
      </Fragment>
  );
};

export default Taxdesign;