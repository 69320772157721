import React, { Fragment, useEffect, useState, useRef } from 'react';
import { Container, Row, Col, Card, CardBody,TabContent, TabPane, Nav, NavItem, NavLink , Form, FormGroup, Label, Input, Table, CardHeader, Button,InputGroup ,InputGroupText} from 'reactstrap';
import Select from 'react-select';
import { Specifications, Division, Warehouse, Reference, UoM} from '../../../../Constant';
import { IoArrowBackOutline, IoArrowForward  } from "react-icons/io5";
import { Breadcrumbs , P} from '../../../../AbstractElements';
import HeaderCard from '../../../Common/Component/HeaderCard';
import { TabCardData } from '../../../Common/Data/Bonus-ui';
import Swal from 'sweetalert2';
import { IoTrash } from "react-icons/io5";
import DataTable from 'react-data-table-component';
import '../forms.css';
import { IoMdClose } from "react-icons/io";
import axiosInstance from '../../../../Auth/AxiosInstance';
import Highlighter from 'react-highlight-words';
import { FaRegEdit,FaSearch } from "react-icons/fa";
import classNames from "classnames";

function ItemL3() {
  const[datasmaterial,setDatasmaterial] = useState(null);
  const [activeTab, setActiveTab] = useState(1);
  const [itemcode, setItemcode] = useState('');
  const [categoryName, setCategoryName] = useState('');
  const [description, setDescription] = useState('');
  const [partnumber, setPartnumber] = useState('');
  const [under, setUnder] = useState(null);
  const [innerdia, setInnerdia] = useState('');
  const [outerdia, setOuterdia] = useState('');
  const [thickness, setThickness] = useState('');
  const [length, setLength] = useState('');
  const [spec, setSpec] = useState('');
  const [materialgrade, setMaterialgrade] = useState('');
  const [materials, setMaterials] = useState(null);
  const [datagrid,setDatagrid] = useState(false);
  const [categories, setCategories] = useState([]);
  const [clearRows, setClearRows] = useState(false);
  const [selectedIds, setSelectedIds] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [editId, setEditId] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [selectOptions, setSelectOptions] = useState([{ value: '1', label: '[] Primary' }]);
  const [nameError1 , setNameError1] = useState(null);
  const [descriptionError1, setDescriptionError1] = useState(null);
  const [nameError2 , setNameError2] = useState(null);
  const [nameError3 , setNameError3] = useState(null);
  const [descriptionError2, setDescriptionError2] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredCategories, setFilteredCategories] = useState([]);
  const [submitbtn, setSubmintbtn] =useState('Save');
  const [opt1 , setOpt1] = useState([]);
  const[decimalplaces,setDecimalplaces] = useState('');
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [decimalError2,setDecimalError2] = useState(null)
  const[invOptions,setInvOptions] = useState([]);
  const[subcategory,setSubcategory] = useState([]);
  const[itemOptions,setItemOptions] = useState([]);
  const[itemcategory,setItemcategory] = useState([]);
  const[itemSubcategory,setItemSubcategory] = useState([]);
  const[suboption,setSuboption] = useState([]);
  const[warehouse,setWarehouse] = useState([]);
  const[purchase,setPurchase] = useState([]);
  const[sale,setSale] = useState([]);
  const[hsn,setHsn] = useState([]);
  const[hsndata,setHsndata] = useState([]);
  const[uom,setUom] = useState([]);
  const[purchaseunit,setPurchaseunit]=useState([]);
  const[uomunit,setUomunit]=useState([]);
  const[productionunit,setProductionunit]=useState([]);
  const[saleunit,setSaleunit]=useState([]);
  const[materialseg,setMaterialseg] = useState([])

  const nameLen = 15;
  const desLen = 25;
  const [shouldFocusName, setShouldFocusName] = useState(false);
  const cancelButtonRef = useRef(null);
  const submitButtonRef = useRef(null);
  const [gstrows, setGstrows] = useState([
    {
        formqty:'',
        fromunit:'',
        toqty:'',
        tounit: '',
        
    }
])

  const rowsPerPage = 10;
  const customStylesTable = {
    header: {
      style: {
        minHeight: '56px',
        backgroundColor: '#f0f0f0',
        color: '#333',
      },
    },
    headRow: {
      style: {
        backgroundColor: '#f0f0f0',
      },
    },
    headCells: {
      style: {
        fontSize: '14px',
        fontWeight: 'bold',
        textAlign: 'left',
        color: '#000',
      },
    },
    rows: {
      style: {
        minHeight: '48px',
        borderBottom: '1px solid #ddd',
      },
    },
  };
  
  const customStyles = {
    control: (provided, state) => ({
        ...provided,
        borderColor: state.isFocused ? 'blue' : '#afafaf', 
        boxShadow: state.isFocused ? '0 0 0 1px blue' : 'none',
        '&:hover': {
            borderColor: state.isFocused ? 'blue' : 'black',
        },
    }),
};
  const paginatedData = Array.isArray(categories) ? categories.slice((currentPage - 1) * rowsPerPage, currentPage * rowsPerPage) : [];
  const columns = [
    {
      name: (<div className='dataTableDiv'>SN</div>),
      selector: (row, index) => index + 1 + (currentPage - 1) * rowsPerPage,
        
      // width:'10%',
      width:"70px",
      cell:(row, index) =>(
        <div className='dataTableDiv' style={{textAlign: 'left !important',display:"flex", justifyContent:"flex-start !important" ,paddingLeft:"4px"}}>{index + 1 + (currentPage - 1) * rowsPerPage}</div>
      )
    },
    {
      name:  (<div className='dataTableDiv'>Item code</div>),
      // width:"20%",
      selector: row => row.itemcode,
      cell: row => (
        <div style={{textAlign: 'left !important',display:"flex", justifyContent:"flex-start !important" ,paddingLeft:"4px"}}>
          <Highlighter
            highlightClassName="highlighted-text"
            className='dataTableDiv'
            searchWords={[searchTerm]}
            autoEscape={true}
            textToHighlight={row.itemcode}
        />
        </div>
      ),
    },
    {
      name:'',
      width:'5px',
    },
    {
      name:  (<div className='dataTableDiv'>Description</div>),
      width:"20%",
      selector: row => row.description,
      cell: row => (
        <div style={{textAlign: 'left !important',display:"flex", justifyContent:"flex-start !important" ,paddingLeft:"10px"}}>
          <Highlighter
            highlightClassName="highlighted-text"
            className='dataTableDiv'
            searchWords={[searchTerm]}
            autoEscape={true}
            textToHighlight={row.description}
        />
        </div>
      ),
    },
    {
      name:  (<div className='dataTableDiv'>Partnumber</div>),
      // width:"23%",

      selector: row => row.partnumber,
      cell: row => (
        <div style={{textAlign: 'left !important',display:"flex", justifyContent:"flex-start !important" ,paddingLeft:"10px"}}>
          <Highlighter
            highlightClassName="highlighted-text"
            className='dataTableDiv'
            searchWords={[searchTerm]}
            autoEscape={true}
            textToHighlight={row.partnumber}
        />
        </div>
      ),
      
    },
    {
      name:  (<div className='dataTableDiv'>Material Grade</div>),
      // width:"23%",

      selector: row => row.materialgrade,
      cell: row => (
        <div style={{textAlign: 'left !important',display:"flex", justifyContent:"flex-start !important" ,paddingLeft:"10px"}}>
          <Highlighter
            highlightClassName="highlighted-text"
            className='dataTableDiv'
            searchWords={[searchTerm]}
            autoEscape={true}
            textToHighlight={row.materialgrade}
        />
        </div>
      ),
      
    },
    {
      name:  (<div className='dataTableDiv'>UOM</div>),
      // width:"23%",

      selector: row => String(row.uom_name),
      cell: row => (
        <div style={{textAlign: 'left !important',display:"flex", justifyContent:"flex-start !important" ,paddingLeft:"10px"}}>
          <Highlighter
            highlightClassName="highlighted-text"
            className='dataTableDiv'
            searchWords={[searchTerm]}
            autoEscape={true}
            textToHighlight={ String(row.uom_name)}
        />
        </div>
      ),
      
    },
    {
      name: (<div className='dataTableDiv'>Action</div>),
      center: true,
      // width:'15%',
      cell: row => (
        <div className="buttonven">
          <button
            className="vendorupdate"
            color='primary'
            title='Edit'
            onClick={() => handleUpdateClick(row)}
          >
            <FaRegEdit className="vendortrash"/>
          </button>
          <button title='Delete' className="vendordele" onClick={() => handleDeleteCategory(row.id)}>
            <IoTrash className="vendortrash" />
          </button>
        </div>
      ),
    },
  ];



  useEffect(() => {
    if (searchTerm.trim() === '') {
      setFilteredCategories(categories || []);
    } else {
      const safeCategories = Array.isArray(categories) ? categories : [];
      const filteredData = safeCategories.filter(item =>
        item.name && item.name.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredCategories(filteredData);
    }
  }, [searchTerm, currentPage, categories]);

  useEffect(() => {
    fetchCategories(currentPage);
    fetchSelectOptions();
    fetchinvetoryOptions();
    fetchitemOptions();
    fetchsubitemOptions();
    fetchpurchase();
    fetchmaterialsegration();
    fetchhsn();
    fetchuom();
  }, [currentPage,searchTerm]); 

  const fetchCategories = async (page) => {
    setLoading(true);
    try {
      let response;
      if (searchTerm) {
          response = await axiosInstance.get(`inventory/item/search/?name=${searchTerm}`);
      } else {
          response = await axiosInstance.get(`inventory/item/get/`);
      }
      console.log(response.data,"responseresponseresponse")
      setCategories(response.data);
      setFilteredCategories(response.data); 
      console.log(response.data,'datas')
    } catch (error) {
      if (error.response && error.response.status === 404 && page > 1) {
        handlePageChange(page - 1);
      } else {
        console.error("Error fetching categories:", error);
      }
    }finally {
      setLoading(false);
    }
  };
  const handlePageChange = (page) => {
    setCurrentPage(page);
    fetchCategories(page);
  };
  const fetchSelectOptions = async (editId = null) => {
    try {
      const response = await axiosInstance.get('inventory/uom/get/');
      console.log(editId,'snckdsvv');
      const opt = response.data.map(category => ({
        value: category.id,
        label: category.name
      }));
      setOpt1(opt);
      console.log(opt,'options');
      const filteredOptions = response.data
      .filter(category => category.id !== editId)
      .map(category => ({
        value: category.id,
        label: category.name
      }));
      setSelectOptions([{ value: '1', label: '[] Primary' }, ...filteredOptions]);
    } catch (error) {
      console.error('Error fetching select options:', error);
    }
  };

  const fetchinvetoryOptions = async (editId = null) => {
    try {
      const response = await axiosInstance.get('inventory/itemgroup/get/');
      const filteredOptions = response.data
      .filter(category => category.id !== editId) // Exclude the category being edited
      .map(category => ({
        value: category.id,
        label: category.name,
        materialsegregation_namess : category.materialsegregation_name
      }));
      setInvOptions([ ...filteredOptions]);
    } catch (error) {
      console.error('Error fetching select options:', error);
    }
  }; 

  const fetchitemOptions = async (editId = null) => {
    try {
      const response = await axiosInstance.get('inventory/itemcategory/get/');
      const filteredOptions = response.data
      .filter(category => category.id !== editId) // Exclude the category being edited
      .map(category => ({
        value: category.id,
        label: category.name
      }));
      console.log(filteredOptions,"filteredOptions")
      setItemOptions([ ...filteredOptions]);
    } catch (error) {
      console.error('Error fetching select options:', error);
    }
  }; 

  const fetchsubitemOptions = async (editId = null) => {
    try {
      const response = await axiosInstance.get('inventory/itemsubcategory/get/');
      const filteredOptions = response.data
      .filter(category => category.id !== editId) // Exclude the category being edited
      .map(category => ({
        value: category.id,
        label: category.name
      }));
      console.log(filteredOptions,"filteredOptions")
      setSuboption([ ...filteredOptions]);
    } catch (error) {
      console.error('Error fetching select options:', error);
    }
  }; 

  const fetchpurchase = async (editId = null) => {
    try {
      const response = await axiosInstance.get('inventory/warehousecategory/get/');
      const filteredOptions = response.data
      .filter(category => category.id !== editId) // Exclude the category being edited
      .map(category => ({
        value: category.id,
        label: category.name
      }));
      console.log(filteredOptions,"filteredOptions")
      setWarehouse([ ...filteredOptions]);
    } catch (error) {
      console.error('Error fetching select options:', error);
    }
  }; 

  const fetchhsn = async (editId = null) => {
    try {
      const response = await axiosInstance.get('taxation/hsn/get/');
      const filteredOptions = response.data
      .filter(category => category.id !== editId) // Exclude the category being edited
      .map(category => ({
        value: category.id,
        label: category.name
      }));
      console.log(filteredOptions,"filteredOptions")
      setHsn([ ...filteredOptions]);
    } catch (error) {
      console.error('Error fetching select options:', error);
    }
  }; 
  


  const fetchmaterialsegration = async (editId = null) => {
    try {
      const response = await axiosInstance.get('inventory/materialsegregation/get/');
      const filteredOptions = response.data
      .filter(category => category.id !== editId) // Exclude the category being edited
      .map(category => ({
        value: category.id,
        label: category.name
      }));
      console.log(filteredOptions,"filteredOptions")
      setMaterialseg([ ...filteredOptions]);
    } catch (error) {
      console.error('Error fetching select options:', error);
    }
  }; 
  
  
  const fetchuom = async (editId = null) => {
    try {
      const response = await axiosInstance.get('inventory/uom/get/');
      const filteredOptions = response.data
      .filter(category => category.id !== editId) // Exclude the category being edited
      .map(category => ({
        value: category.id,
        label: category.name
      }));
      console.log(filteredOptions,"filteredOptions")
      setUom([ ...filteredOptions]);
    } catch (error) {
      console.error('Error fetching select options:', error);
    }
  }; 

  
  const handleSubmit = async (e) => {
    console.log(uomunit,"uomunituomunituomunituomunit")
    
    
    e.preventDefault();
    const payload = {
      itemcode : itemcode === ''? alert("itemcode should not be null") : itemcode,
      description : description,
      partnumber : partnumber,
      innerdia: innerdia,
      outerdia: outerdia,
      thickness: thickness,
      length: length,
      spec: spec,
      materialgrade: materialgrade,
      itemgroup: itemcategory.value == null ? alert("Itemgroup should not be null") : itemcategory.value ,  // Replace with a valid `ItemGroup` ID
      itemcategory: subcategory ? subcategory.value : null,  // Replace with a valid `ItemCategory` ID
      itemsubcategory: itemSubcategory ? itemSubcategory.value : null,  // Replace with a valid `ItemSubCategory` ID
      purchasewharehouse: purchase ? purchase.value : null,  // Replace with a valid `WarehouseCategory` ID
      salewarehouse: sale ? sale.value : null,  // Replace with a valid `WarehouseCategory` ID
      hsn: hsndata ? hsndata.value : null,  // Replace with a valid `HSN` ID
      uom: uomunit.value ==  null ? alert("UOM should not be null") : uomunit.value,
      purchaseunit: purchaseunit ? purchaseunit.value : null,
      productionunit: productionunit ? productionunit.value : null,
      saleunit: saleunit ? saleunit.value : null,
      data_grid_view :gstrows && (gstrows[0].formqty !== '' && gstrows[0].fromunit !== '' && gstrows[0].toqty !== '' && gstrows[0].tounit !== '' )? gstrows : (alert("Unit conversion  should not be null"), null) ,
      
    };
    console.log(purchase,"purchaseeee")
    console.log(itemSubcategory,"itemSubcategory")
    setNameError1(null);
    setDescriptionError1(null);
    try {
      if (isEditing) {
        await axiosInstance.put(`inventory/item/${editId}/`, payload);
        setIsEditing(false);
      } else {
        await axiosInstance.post('inventory/item/create/', payload);
        setErrorMessage(null);
      }
      fetchCategories(currentPage);
      fetchSelectOptions();
      fetchinvetoryOptions();
      fetchitemOptions();
      fetchsubitemOptions();
      fetchpurchase();
      fetchhsn();
      fetchuom();
      resetForm();
    } catch (error) {
      console.error('Error:', error);
      if (error.response && error.response.data) {
        const nameError = error.response.data.name && Array.isArray(error.response.data.name) ? error.response.data.name[0] : null;
        const descriptionError = error.response.data.description && Array.isArray(error.response.data.description) ? error.response.data.description[0] : null;
        const existsError = error.response.data.error && Array.isArray(error.response.data.error) ? error.response.data.error[0] : null;
        console.log(nameError, 'Name Error');
        console.log(descriptionError, 'Description Error');
        if (nameError && descriptionError) {
        } else if (nameError) {
          setNameError1(nameError)
        } else if (descriptionError) {
          setDescriptionError1(descriptionError);
        } else if (existsError) {
          setErrorMessage(existsError);
 
        }
      } else {
        setErrorMessage('An error occurred while submitting the form.');
      }
    }
  };
  

  const handleUpdateClick = (category) => {
    setNameError1(null);
    setNameError3(null);
    setEditId(category.id);
    setNameError2(null);
    setDescriptionError2(null);
    setDescription('');
    setIsEditing(false);
    setItemcode(category.itemcode);
    setDescription(category.description);
    setPartnumber(category.partnumber);
    setInnerdia(category.innerdia);
    setOuterdia(category.outerdia);
    setThickness(category.thickness);
    setLength(category.length);
    setSpec(category.spec);
    setMaterialgrade(category.materialgrade);
    setItemcategory({value:category.itemgroup, label:category.itemgroup_name})
    setSubcategory({value:category.itemcategory, label:category.itemcategory_name});
    setItemSubcategory({value: category.itemsubcategory, label:category.itemsubcategory_name});
    setPurchase({value:category.purchasewharehouse, label:category.purchasewarehouse_name});
    setSale({value:category.salewarehouse, label:category.salewarehouse_name});
    setHsndata({value:category.hsn, label:category.hsn_name});
    setUomunit({value:category.uom, label:category.uom_name});
    setPurchaseunit({value:category.purchaseunit, label:category.purchaseunit_name});
    setProductionunit({value:category.productionunit, label:category.productionunit_name});
    setSaleunit({value:category.saleunit, label:category.saleunit_name});
    setGstrows(category.data_grid_view)
    const itemGrp = invOptions.find(option=> option.value === category.itemgroup);
    setDatasmaterial(itemGrp.materialsegregation_namess);

    setUnder(null);
    setNameError1(null);
    setClearRows(!clearRows);
    setSubmintbtn('Save')
    setDescriptionError1(null);
    setErrorMessage('');
    setNameError1(null);
    setDescriptionError1(null);
   
   
    setSubmintbtn('Update');
    setIsEditing(true);
    fetchSelectOptions(category.id);
    setErrorMessage('');
  };

  const resetForm = () => {
    setNameError2(null);
    setGstrows([
      {
          formqty:'',
          fromunit:'',
          toqty:'',
          tounit: '',
          
      }
  ]);

    setNameError3(null);
    setIsEditing(false);
    setItemcode('');
    setDescription('');
    setPartnumber('');
    setInnerdia('');
    setOuterdia('');
    setThickness('');
    setLength('');
    setSpec('');
    setMaterialgrade('');
    setDatasmaterial('')
    setSubcategory([]);
    setItemcategory([]);
    setItemSubcategory([]);
    setPurchase([]);
    setSale([]);
    setHsndata([]);
    setUomunit([]);
    setPurchaseunit([]);
    setProductionunit([]);
    setSaleunit([]);
    setUnder(null);
    setNameError1(null);
    setClearRows(!clearRows);
    setSubmintbtn('Save')
    setDescriptionError1(null);
    setEditId(null);
    setErrorMessage('');

  
  };
 const handleBack = () =>{
  console.log(activeTab)
  setActiveTab(activeTab - 1)
  console.log(activeTab)

 }
 const handleNext = () =>{
  setActiveTab(activeTab + 1)

 }
  const handleCheckboxChange = (selectedRows) => {
    setSelectedIds(selectedRows.selectedRows.map(row => row.id));
  };
  const handleDelete = async () => {
    try {
      const response = await axiosInstance.get('inventory/uom/get/');
      const categories = response.data;

      const categoryMap = new Map(categories.map(cat => [cat.id, cat.name]));

      const selectedNames = selectedIds.map(id => categoryMap.get(id)).filter(name => name);

      const escapeHtml = (text) => {
        return text.replace(/[&<>"']/g, (match) => {
          const escape = {
            '&': '&amp;',
            '<': '&lt;',
            '>': '&gt;',
            '"': '&quot;',
            "'": '&#039;'
          };
          return escape[match];
        });
      };

      // const namesText = selectedNames.map(name => `<strong>${escapeHtml(name)}</strong>`).join(', ');

      // const result = await Swal.fire({
      //   title: 'Are you sure?',
      //   html: `You are about to delete the following categories: ${namesText}. You won't be able to revert this!`,
      //   icon: 'warning',
      //   showCancelButton: true,
      //   confirmButtonColor: '#3085d6',
      //   cancelButtonColor: '#d33',
      //   confirmButtonText: 'Yes, delete it!'
      // });

      await axiosInstance.delete('inventory/item/delete/', {
        data: { ids: selectedIds },
      });
      fetchCategories(currentPage);
      setSelectedIds([]);
      fetchSelectOptions();
      
      resetForm();
    } catch (error) {
      console.error('Error:', error.response.data);
      const errorMessage = JSON.stringify(error.response.data.detail, null, 2);
      Swal.fire('Error!',errorMessage , 'error');
    }
  };

const addRow = () => {
  setGstrows([...gstrows, { field1: '', field2: '', field3: '' }]);
  
};

const removeRow = (index) => {
    const newRows = [...gstrows];
    console.log(newRows,"newRowsnewRowsnewRows")
    newRows.splice(index,1);
    //  delete newRows[index]; // Remove the row at the specified index
    setGstrows(newRows);
    if(newRows.length === 0){
      setGstrows([
        {
            formqty:'',
            fromunit:'',
            toqty:'',
            tounit: '',
            
        }
    ]);

    }

 
    
};


const handleSave = ()=>{

  if(gstrows[0].formqty === '' || isNaN(gstrows[0].formqty) ){
    alert("Formqty  should not be null")
  }
  
  else if(gstrows[0].fromunit === ''){
    alert("Fromunit  should not be null")
  }
  else if(gstrows[0].toqty === ''|| isNaN(gstrows[0].toqty)){
    alert("Toqty  should not be null")
  }
  else if(gstrows[0].tounit === ''){
    alert("Tounit  should not be null")

  }
  // if( || gstrows[0].fromunit === '' && gstrows[0].toqty === '' || gstrows[0].tounit === '' ){alert("Unit conversion  should not be null")} 
  else{
    setDatagrid(false);

  }

  
}

const handleCancelGSTTax = () =>{
  setDatagrid(false);
};

  const handleDeleteCategory = async (id) => {
    // const result = await Swal.fire({
    //   title: 'Are you sure?',
    //   text: `You won't be able to revert this!`,
    //   icon: 'warning',
    //   showCancelButton: true,
    //   confirmButtonColor: '#3085d6',
    //   cancelButtonColor: '#d33',
    //   confirmButtonText: 'Yes, delete it!'
    // });

    // if (result.isConfirmed) 
    {
      try {
        await axiosInstance.delete(`inventory/item/${id}/`);
        fetchCategories(currentPage);
      } catch (error) {
        console.error('Error deleting category:', error.response.data);
        const errorMessage = JSON.stringify(error.response.data.detail, null, 2);
        Swal.fire('Error!', errorMessage, 'error');
      }

      setSelectedIds([]);
      fetchSelectOptions();
      resetForm();
    }
  };

  const validateName = (value) => {
    console.log(value,'kjhvckjsdnv');
    
  //  if (value.match('  ')) {
  //     setNameError1('Continuous spaces are not allowed ');
  //     return false;
  //   }  else if(value.length === 30){
  //     setNameError2(null);
  //   }else {
  //     setNameError1(null);
  //     return true;
  //   }
  };
const handleNameChange = (e) => {
      let value = e.target.value;
      value = value.replace(/\s+/g,' ').trimStart();
      setItemcode(value);
      // validateName(value); 
  };
 const handleDescriptions = (e) => {  let value = e.target.value;
    // value = value.replace(/\s+/g,' ').trimStart();
    setDescription(value);
    // validateName(value);
  
};
const handlePartnumber = (e) => {  let value = e.target.value;
  // value = value.replace(/\s+/g,' ').trimStart();
  setPartnumber(value);
  // validateName(value);

};
const handleinnerdia = (e)=>{
  let value = e.target.value;
  setInnerdia(value)
  
};
const handleouterdia = (e)=>{
  let value = e.target.value;
  setOuterdia(value)
  
};
const handlethickness = (e)=>{
  let value = e.target.value;
  setThickness(value)
  
};
const handlelength = (e)=>{
  let value = e.target.value;
  setLength(value)
  
};
const handlespec = (e)=>{
  let value = e.target.value;
  setSpec(value)
  
};
const handlematerialgrade = (e)=>{
  let value = e.target.value;
  setMaterialgrade(value)
  
};



const handleGSTInputChange = (index, event) => {
  console.log("naganaganaganaganaganaga")
  const { name, value } = event.target;
  const updatedRows = [...gstrows];
  if(name === 'formqty' ||name === 'toqty'){
    updatedRows[index][name] = parseInt(value,10)
  }else{
    updatedRows[index][name] = value;
  }
  
  setGstrows(updatedRows);
};


const handleEnter = (event) => {
  if (event.target && nameError1 && (event.key === 11 || event.key === 13)) {
    event.target.focus();  
  } 
  else if (event.keyCode === 13 && event.target.form) {
    if (event.target.tagName === 'INPUT' || event.target.tagName === 'TEXTAREA') {
      event.target.value = event.target.value.trim();
    }
    if (dropdownOpen) {
      return; 
    } else {
      const form = event.target.form;
      const index = Array.prototype.indexOf.call(form.elements, event.target);
      const nextElement = form.elements[index + 1];
      if (nextElement) {
        nextElement.focus();
      }
      event.preventDefault();
    }
  }
  const { value } = event.target;
  if (
    (event.key === ' ' && value.length === 0) || 
    (event.key === ' ' && value.endsWith(' ')) || 
    (event.key === ' ' && value.includes('  '))
  ) {
    event.preventDefault();
    return;
  }
};

const handleArrowNavigation = (event, buttonType) => {
  if (event.key === 'ArrowRight' && buttonType === 'save' && cancelButtonRef.current) {
    cancelButtonRef.current.focus();
  } else if (event.key === 'ArrowLeft' && buttonType === 'cancel' && submitButtonRef.current) {
    submitButtonRef.current.focus();
  }
};
  const handleBlur = (e) => {
    setNameError2(null);
    setDescriptionError2(null);
    if (e.target.tagName === 'INPUT' || e.target.tagName === 'TEXTAREA') {
      e.target.value = e.target.value.trim();  // Ensures the value is trimmed properly
      
      if (nameError1) {
          e.target.focus();
      }else if (descriptionError1) {
        e.target.focus(); 
      }
    }
  };
  const handleDescriptionFocus = () => {
    // Perform name validation when switching to the description field
    if(isEditing){
      const otherCategories = categories.filter(category => category.id !== editId);
      console.log(otherCategories,'is editing');
      if (Array.isArray(otherCategories) && otherCategories.some((item) => item.itemcode.replace(/\s+/g,'').toLowerCase() === itemcode.replace(/\s+/g,'').toLowerCase())) {
        setShouldFocusName(true);
        setNameError3('Item with this name already exists.');
        setTimeout(() => {
          const nameInput = document.getElementById('nameInput');
          if (nameInput) {
            nameInput.focus(); // Set focus on the input with id 'nameInput'
          }
      }, 0);
      }
      else {
        setNameError3(null);
      }
      
    }else{
      if(Array.isArray(categories) && categories.some((item) => item.itemcode.replace(/\s+/g,'').toLowerCase() === itemcode.replace(/\s+/g,'').trim().toLowerCase())){
        setShouldFocusName(true);
        setNameError3('Item with this name already exists.');
        setTimeout(() => {
          const nameInput = document.getElementById('nameInput');
          if (nameInput) {
            nameInput.focus(); // Set focus on the input with id 'nameInput'
          }
        }, 0);
        return false;
      }else {
        setNameError3(null);
      }

    }
  };
  const handleItemGroupchange =(option)=>{
    setItemcategory(option);
    setDatasmaterial(option.materialsegregation_namess);
  }
  return (
    <Fragment>
      <Breadcrumbs mainTitle="Item" parent="Procument" title="Vendor Category" />
  <Form className="theme-form" id='form' onSubmit={handleSubmit}>
          <Col sm="12" xl="6" className='vendorcol2a'>
                        <Card>
                            <div className='formdiv1ba'>
                                <div className='rowForm1'>
                                   
                                    <div  className='grid_container'>
                                    <FormGroup className='custom-margin'>
                                                    <Label style={{fontWeight:"600"}} className="col-form-label pt-0" >Item Code</Label>
                                                    <Input autoComplete="off"  onFocus={handleDescriptionFocus} style={{color:"black",borderColor: "#afafaf",  height:"40px" }} maxLength='15' onBlur={handleBlur}  id="nameInput" className={classNames("form-control", "inputfoc", {"is-invalid": nameError1,})} type="text" onKeyDown={handleEnter}  value={itemcode} onChange={handleNameChange} />
                                                    {nameError1 && (
                                                    <div className="alert alert-danger">
                                                        {nameError1}
                                                    </div>

                                                )}
                                                {nameError2 && (
                                                    <div className="alert alert-danger">
                                                        {nameError2}
                                                    </div>
                                                )}
                                                {nameError3 && (
                                                    <div className="alert alert-danger">
                                                        {nameError3}
                                                    </div>
                                                )}
                                                </FormGroup>
                                              
                                                <FormGroup className='custom-margin'>
                                                    <Label style={{fontWeight:"600"}} className="col-form-label pt-0" >Description</Label>
                                                    <Input  autoComplete="off"  onFocus={handleDescriptionFocus}  style={{color:"black", borderColor: "#afafaf",  height:"40px" }} onBlur={handleBlur} maxLength="100" id="nameInput" className={classNames("form-control", "inputfoc", {"is-invalid": nameError1,})} type="text" onKeyDown={handleEnter}  value={description} onChange={handleDescriptions} />
                                                </FormGroup>
                                                {decimalError2 && (
                                                    <div className="alert alert-danger">
                                                        {decimalError2}
                                                    </div>
                                                )}
                                                <FormGroup className='custom-margin'>
                                                    <Label style={{fontWeight:"600"}} className="col-form-label pt-0" >PartNumber</Label>
                                                    <Input  autoComplete="off"  onFocus={handleDescriptionFocus} style={{color:"black", borderColor: "#afafaf",  height:"40px" }} onBlur={handleBlur} maxLength="25" id="nameInput" className={classNames("form-control", "inputfoc", {"is-invalid": nameError1,})} type="text" onKeyDown={handleEnter}  value={partnumber} onChange={handlePartnumber} />
                                                </FormGroup>
                                                {decimalError2 && (
                                                    <div className="alert alert-danger">
                                                        {decimalError2}
                                                    </div>
                                                )}

                                        
                                        </div>
                                   
                                </div>
                            </div>
                        </Card>
                    </Col>
    {TabCardData.map((item, i) =>
        <Col sm="12" xl="6" className='vendorcol2a' key={i}>
            <Card>
                <HeaderCard title={''} />
                <CardBody>
                    <div className="tabbed-card">
                        <Nav className={item.navClass}>
                            <NavItem>

                                <NavLink className={activeTab === 1 ? 'active' : ''} onClick={() => setActiveTab(1)}>
                                    {item.homeIcon ? item.homeIcon : ''} {Specifications}
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink className={activeTab === 2 ? 'active' : ''} onClick={() => setActiveTab(2)}>
                                    {item.glassIcon ? item.glassIcon : ''} {Division}
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink className={activeTab === 3 ? 'active' : ''} onClick={() => setActiveTab(3)}>
                                    {item.contactIcon ? item.contactIcon : ''} {Warehouse}
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink className={activeTab === 4 ? 'active' : ''} onClick={() => setActiveTab(4)}>
                                    {item.contactIcon ? item.contactIcon : ''} {Reference}
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink className={activeTab === 5 ? 'active' : ''} onClick={() => setActiveTab(5)}>
                                    {item.contactIcon ? item.contactIcon : ''} {UoM}
                                </NavLink>
                            </NavItem>
                        </Nav>
                                    <TabContent activeTab={activeTab.toString()}>
                                
                                      
                                      <TabPane tabId="1">
                                          <P attrPara={{ className: 'mb-0' }} > 
                                                    <div style={{display:"Flex", gap:"20px", justifyContent:"space-around"}}>
                                                    <FormGroup className='custom-margin itemspec'>
                                                      <Label style={{fontWeight:"600"}} className="col-form-label pt-0" >Inner Dia</Label>
                                                      <Input  autoComplete="off" onFocus={handleDescriptionFocus}  style={{color:"black", borderColor: "#afafaf",  height:"40px" }} onBlur={handleBlur} maxLength="10" id="nameInput" className={classNames("form-control", "inputfoc", {"is-invalid": nameError1,})} type="text" onKeyDown={handleEnter}  value={innerdia} onChange={handleinnerdia} />
                                                  </FormGroup>
                                                  {decimalError2 && (
                                                      <div className="alert alert-danger">
                                                          {decimalError2}
                                                      </div>
                                                  )}
                                                  <FormGroup className='custom-margin itemspec'>
                                                      <Label style={{fontWeight:"600"}} className="col-form-label pt-0" >Outer Dia</Label>
                                                      <Input autoComplete="off" onFocus={handleDescriptionFocus}  style={{color:"black", borderColor: "#afafaf",  height:"40px" }} onBlur={handleBlur} maxLength="10" id="nameInput" className={classNames("form-control", "inputfoc", {"is-invalid": nameError1,})} type="text" onKeyDown={handleEnter}  value={outerdia} onChange={handleouterdia} />
                                                  </FormGroup>
                                                  {decimalError2 && (
                                                      <div className="alert alert-danger">
                                                          {decimalError2}
                                                      </div>
                                                  )}
                                                  <FormGroup className='custom-margin itemspec'>
                                                      <Label style={{fontWeight:"600"}} className="col-form-label pt-0" >Thickness</Label>
                                                      <Input  autoComplete="off" onFocus={handleDescriptionFocus}  style={{color:"black", borderColor: "#afafaf",  height:"40px" }} onBlur={handleBlur} maxLength="10" id="nameInput" className={classNames("form-control", "inputfoc", {"is-invalid": nameError1,})} type="text" onKeyDown={handleEnter}  value={thickness} onChange={handlethickness} />
                                                  </FormGroup>
                                                  {decimalError2 && (
                                                      <div className="alert alert-danger">
                                                          {decimalError2}
                                                      </div>
                                                  )}
                                                  <FormGroup className='custom-margin itemspec'>
                                                      <Label style={{fontWeight:"600"}} className="col-form-label pt-0" >Length</Label>
                                                      <Input  autoComplete="off" onFocus={handleDescriptionFocus}   style={{color:"black", borderColor: "#afafaf",  height:"40px" }} onBlur={handleBlur} maxLength="10" id="nameInput" className={classNames("form-control", "inputfoc", {"is-invalid": nameError1,})} type="text" onKeyDown={handleEnter}  value={length} onChange={handlelength} />
                                                  </FormGroup>
                                                  {decimalError2 && (
                                                      <div className="alert alert-danger">
                                                          {decimalError2}
                                                      </div>
                                                  )}
                                                  <FormGroup className='custom-margin itemspec'>
                                                      <Label style={{fontWeight:"600"}} className="col-form-label pt-0" >Spec</Label>
                                                      <Input  autoComplete="off"  onFocus={handleDescriptionFocus}  style={{color:"black", borderColor: "#afafaf",  height:"40px" }} onBlur={handleBlur} maxLength="25" id="nameInput" className={classNames("form-control", "inputfoc", {"is-invalid": nameError1,})} type="text" onKeyDown={handleEnter}  value={spec} onChange={handlespec} />
                                                  </FormGroup>
                                                  {decimalError2 && (
                                                      <div className="alert alert-danger">
                                                          {decimalError2}
                                                      </div>
                                                  )}
                                                  
                                                  
                                                  <FormGroup className='custom-margin itemspec'>
                                                      <Label style={{fontWeight:"600"}} className="col-form-label pt-0" >Material Grade</Label>
                                                      <Input  autoComplete="off"  onFocus={handleDescriptionFocus}  style={{color:"black", borderColor: "#afafaf",  height:"40px" }} onBlur={handleBlur} maxLength="25" id="nameInput" className={classNames("form-control", "inputfoc", {"is-invalid": nameError1,})} type="text" onKeyDown={handleEnter}  value={materialgrade} onChange={handlematerialgrade} />
                                                  </FormGroup>
                                                  {decimalError2 && (
                                                      <div className="alert alert-danger">
                                                          {decimalError2}
                                                      </div>
                                                  )}
                                                    </div>
                                          </P>
                                      </TabPane>
                                      <TabPane tabId="2">
                                          <P attrPara={{ className: 'mb-0' }}>
                                             <div style={{display:"Flex", gap:"20px", justifyContent:"space-around"}}>
                                             <FormGroup className='itemdiv'>
                                            <Label style={{fontWeight:"600"}} className="col-form-label pt-0">Item Group</Label>
                                            <Select
                                                value={itemcategory}
                                                onMenuOpen={() => {
                                                  setDropdownOpen(true);
                                                }}
                                                onMenuClose={() => {
                                                  setDropdownOpen(false);
                                                }}
                                                styles={customStyles}
                                                onChange={(option) => {
                                                  handleItemGroupchange(option);
                                              }}

                                                onKeyDown={handleEnter}
                                                onBlur={handleBlur}
                                                options={invOptions}
                                                filterOption={(option, inputValue) => 
                                                  option.label.toLowerCase().startsWith(inputValue.toLowerCase())
                                                }
                                                // formatOptionLabel={(option, { inputValue }) => 
                                                //   getHighlightedText(option.label, inputValue)
                                                // }
                                                
                                            />
                                        </FormGroup>
                                        {errorMessage && (
                                            <div className="alert alert-danger">
                                                {errorMessage}
                                            </div>
                                        )}
                                         
                                        <FormGroup className='itemdiv'>
                                            <Label style={{fontWeight:"600"}} className="col-form-label pt-0">Item Category</Label>
                                            <Select
                                                value={subcategory}
                                                onMenuOpen={() => {
                                                  setDropdownOpen(true);
                                                }}
                                                onMenuClose={() => {
                                                  setDropdownOpen(false);
                                                }}
                                                styles={customStyles}
                                                onChange={(option) => setSubcategory(option)}
                                                onKeyDown={handleEnter}
                                                onBlur={handleBlur}
                                                options={itemOptions}
                                                filterOption={(option, inputValue) => 
                                                  option.label.toLowerCase().startsWith(inputValue.toLowerCase())
                                                }
                                                // formatOptionLabel={(option, { inputValue }) => 
                                                //   getHighlightedText(option.label, inputValue)
                                                // }
                                                
                                            />
                                        </FormGroup>
                                        {errorMessage && (
                                            <div className="alert alert-danger">
                                                {errorMessage}
                                            </div>
                                        )}
                                        <FormGroup className='itemdiv'>
                                            <Label style={{fontWeight:"600"}} className="col-form-label pt-0">Item Sub-Category</Label>
                                            <Select
                                                value={itemSubcategory}
                                                onMenuOpen={() => {
                                                  setDropdownOpen(true);
                                                }}
                                                onMenuClose={() => {
                                                  setDropdownOpen(false);
                                                }}
                                                styles={customStyles}
                                                onChange={(option) => setItemSubcategory(option)}
                                                onKeyDown={handleEnter}
                                                onBlur={handleBlur}
                                                options={suboption}
                                                filterOption={(option, inputValue) => 
                                                  option.label.toLowerCase().startsWith(inputValue.toLowerCase())
                                                }
                                                // formatOptionLabel={(option, { inputValue }) => 
                                                //   getHighlightedText(option.label, inputValue)
                                                // }
                                                
                                            />
                                        </FormGroup>
                                        {errorMessage && (
                                            <div className="alert alert-danger">
                                                {errorMessage}
                                            </div>
                                        )}
                                        <FormGroup className='custom-margin itemdiv'>
                                            <Label style={{fontWeight:"600"}} className="col-form-label pt-0" >Material Segregation</Label>
                                            {/* <Select
                                                value={materials}
                                                onMenuOpen={() => {
                                                  setDropdownOpen(true);
                                                }}
                                                onMenuClose={() => {
                                                  setDropdownOpen(false);
                                                }}
                                                styles={customStyles}
                                                onChange={(option) => setMaterials(option)}
                                                onKeyDown={handleEnter}
                                                onBlur={handleBlur}
                                                options={materialseg}
                                                filterOption={(option, inputValue) => 
                                                  option.label.toLowerCase().startsWith(inputValue.toLowerCase())
                                                }
                                                // formatOptionLabel={(option, { inputValue }) => 
                                                //   getHighlightedText(option.label, inputValue)
                                                // }
                                                required
                                                
                                            /> */}
                                              <Input  autoComplete="off"  style={{color:"black", borderColor: "#afafaf",  height:"40px" }} onBlur={handleBlur} maxLength="30" id="nameInput" className={classNames("form-control", "inputfoc", {"is-invalid": nameError1,})} type="text" onKeyDown={handleEnter}  value={datasmaterial} readOnly  />


                                        </FormGroup>
                                        {decimalError2 && (
                                            <div className="alert alert-danger">
                                                {decimalError2}
                                            </div>
                                        )}

                                            </div></P>
                                      </TabPane>
                                      <TabPane tabId="3">
                                          <P attrPara={{ className: 'mb-0' }}>
                                            <div style={{display:"Flex", gap:"20px", justifyContent:"space-around"}}>
                                            <FormGroup className='itemware'>
                                                    <Label style={{fontWeight:"600"}} className="col-form-label pt-0">Purchase Warehouse</Label>
                                                    <Select
                                                        value={purchase}
                                                        onMenuOpen={() => {
                                                          setDropdownOpen(true);
                                                        }}
                                                        onMenuClose={() => {
                                                          setDropdownOpen(false);
                                                        }}
                                                        styles={customStyles}
                                                        onChange={(option) => setPurchase(option)}
                                                        onKeyDown={handleEnter}
                                                        onBlur={handleBlur}
                                                        options={warehouse}
                                                        filterOption={(option, inputValue) => 
                                                          option.label.toLowerCase().startsWith(inputValue.toLowerCase())
                                                        }
                                                        // formatOptionLabel={(option, { inputValue }) => 
                                                        //   getHighlightedText(option.label, inputValue)
                                                        // }
                                                        
                                                    />
                                                </FormGroup>
                                                {errorMessage && (
                                                    <div className="alert alert-danger">
                                                        {errorMessage}
                                                    </div>
                                                )}
                                                <FormGroup className='itemware'>
                                                    <Label style={{fontWeight:"600"}} className="col-form-label pt-0">Sale Warehouse</Label>
                                                    <Select
                                                        value={sale}
                                                        onMenuOpen={() => {
                                                          setDropdownOpen(true);
                                                        }}
                                                        onMenuClose={() => {
                                                          setDropdownOpen(false);
                                                        }}
                          
                                                        styles={customStyles}
                                                        onChange={(option) => setSale(option)}
                                                        onKeyDown={handleEnter}
                                                        onBlur={handleBlur}
                                                        options={warehouse}
                                                        filterOption={(option, inputValue) => 
                                                          option.label.toLowerCase().startsWith(inputValue.toLowerCase())
                                                        }
                                                        // formatOptionLabel={(option, { inputValue }) => 
                                                        //   getHighlightedText(option.label, inputValue)
                                                        // }
                                                        
                                                    />
                                                </FormGroup>
                                                {errorMessage && (
                                                    <div className="alert alert-danger">
                                                        {errorMessage}
                                                    </div>
                                                )}

                                            </div>
                                          </P>
                                      </TabPane>
                                      <TabPane tabId="4">
                                          <P attrPara={{ className: 'mb-0' }}>
                                            <FormGroup style={{width:"80%"}}>
                                                  <Label style={{fontWeight:"600"}} className="col-form-label pt-0">HSN code</Label>
                                                  <Select
                                                      value={hsndata}
                                                      onMenuOpen={() => {
                                                        setDropdownOpen(true);
                                                      }}
                                                      onMenuClose={() => {
                                                        setDropdownOpen(false);
                                                      }}
                                                      styles={customStyles}
                                                      onChange={(option) => setHsndata(option)}
                                                      onKeyDown={handleEnter}
                                                      onBlur={handleBlur}
                                                      options={hsn}
                                                      filterOption={(option, inputValue) => 
                                                        option.label.toLowerCase().startsWith(inputValue.toLowerCase())
                                                      }
                                                      // formatOptionLabel={(option, { inputValue }) => 
                                                      //   getHighlightedText(option.label, inputValue)
                                                      // }
                                                      
                                                  />
                                              </FormGroup>
                                              {errorMessage && (
                                                  <div className="alert alert-danger">
                                                      {errorMessage}
                                                  </div>
                                              )}
                                                </P>
                                      </TabPane>
                                      <TabPane tabId="5">
                                          <P attrPara={{ className: 'mb-0' }}> 
  
  

                      <div style={{display:"Flex", gap:"20px", justifyContent:"space-around"}}>
                                            <FormGroup className='itemdiv'>
                        <Label style={{fontWeight:"600"}} className="col-form-label pt-0">UoM</Label>
                        <Select
                            value={uomunit}
                            onMenuOpen={() => {
                              setDropdownOpen(true);
                            }}
                            onMenuClose={() => {
                              setDropdownOpen(false);
                            }}
                            styles={customStyles}
                            onChange={(option) => setUomunit(option)}
                            onKeyDown={handleEnter}
                            onBlur={handleBlur}
                            options={uom}
                            filterOption={(option, inputValue) => 
                              option.label.toLowerCase().startsWith(inputValue.toLowerCase())
                            }
                            // formatOptionLabel={(option, { inputValue }) => 
                            //   getHighlightedText(option.label, inputValue)
                            // }
                            
                        />
                    </FormGroup>
                    {errorMessage && (
                        <div className="alert alert-danger">
                            {errorMessage}
                        </div>
                    )}
                    <FormGroup className='itemdiv'>
                        <Label style={{fontWeight:"600"}} className="col-form-label pt-0">Purchase Unit</Label>
                        <Select
                            value={purchaseunit}
                            onMenuOpen={() => {
                              setDropdownOpen(true);
                            }}
                            onMenuClose={() => {
                              setDropdownOpen(false);
                            }}
                            styles={customStyles}
                            onChange={(option) => setPurchaseunit(option)}
                            onKeyDown={handleEnter}
                            onBlur={handleBlur}
                            options={uom}
                            filterOption={(option, inputValue) => 
                              option.label.toLowerCase().startsWith(inputValue.toLowerCase())
                            }
                            // formatOptionLabel={(option, { inputValue }) => 
                            //   getHighlightedText(option.label, inputValue)
                            // }
                            
                        />
                    </FormGroup>
                    {errorMessage && (
                        <div className="alert alert-danger">
                            {errorMessage}
                        </div>
                    )}
                    <FormGroup className='itemdiv'>
                        <Label style={{fontWeight:"600"}} className="col-form-label pt-0">Production Unit</Label>
                        <Select
                            value={productionunit}
                            onMenuOpen={() => {
                              setDropdownOpen(true);
                            }}
                            onMenuClose={() => {
                              setDropdownOpen(false);
                            }}
                            styles={customStyles}
                            onChange={(option) => setProductionunit(option)}
                            onKeyDown={handleEnter}
                            onBlur={handleBlur}
                            options={uom}
                            filterOption={(option, inputValue) => 
                              option.label.toLowerCase().startsWith(inputValue.toLowerCase())
                            }
                            // formatOptionLabel={(option, { inputValue }) => 
                            //   getHighlightedText(option.label, inputValue)
                            // }
                            
                        />
                    </FormGroup>
                    {errorMessage && (
                        <div className="alert alert-danger">
                            {errorMessage}
                        </div>
                    )}
                    <FormGroup  className='itemdiv'>
                        <Label style={{fontWeight:"600"}} className="col-form-label pt-0">Sale Unit</Label>
                        <Select
                            value={saleunit}
                            onMenuOpen={() => {
                              setDropdownOpen(true);
                            }}
                            onMenuClose={() => {
                              setDropdownOpen(false);
                            }}
                            styles={customStyles}
                            onChange={(option) => setSaleunit(option)}
                            onKeyDown={handleEnter}
                            onBlur={handleBlur}
                            options={uom}
                            filterOption={(option, inputValue) => 
                              option.label.toLowerCase().startsWith(inputValue.toLowerCase())
                            }
                            // formatOptionLabel={(option, { inputValue }) => 
                            //   getHighlightedText(option.label, inputValue)
                            // }
                            
                        />
                    </FormGroup>
                    {errorMessage && (
                        <div className="alert alert-danger">
                            {errorMessage}
                        </div>
                    )}



                        </div>

                        <button className='btn2' onClick={() => setDatagrid(true)} type='button' >
                         Unit conversion
                    </button>
                      </P>
                  </TabPane>
                  {datagrid ?  <div className="popup">
                                        <div className='popup-inner'>
                                        <div className='popcls'>
                                            <div className='popclsdiv1'>
                                                <h5>Unit Conversion</h5>
                                            </div>
                                            <div className='popclsdiv2'>
                                                <button className='popclsbtn' type='buttom' onClick={handleCancelGSTTax}><IoMdClose className='clsico' color='red'/></button>
                                            </div>
                                        </div>
                                            
                                            <div className="table-container">
                                                <table style={{width:"100%"}}>
                                                    <thead>
                                                        <tr style={{height:"20px"}}>
                                                            <th style={{textAlign:"center"}}>Form qty</th>
                                                            <th style={{textAlign:"center"}}>From unit</th>
                                                            <th style={{textAlign:"center",width: "10%", border: "transparent" }}></th>
                                                            <th style={{textAlign:"center"}}>To qty</th>
                                                            <th style={{textAlign:"center"}}>To unit</th>
                                                            
                                                           
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {gstrows.map((row, index) => (
                                                            <tr key={index} style={{height:"20px"}}>
                                                                <td >
                                                                    <div className="table_td_div">
                                                                    <input
                                                                        type="number"
                                                                        name="formqty"
                                                                     
                                                                        style={{height:"100%",color:"black"}}
                                                                        value={row.formqty}
                                                                        // onChange={(e) => {
                                                                        //   if (e.target.value.length <= 15) { // Set the max length to 5
                                                                        //       handleGSTInputChange(index, e);
                                                                        //   };}}
                                                                        onChange={(e) => handleGSTInputChange(index, e)}
                                                                        
                                                                    />
                                                                       
                                                                    
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div className="table_td_div">
                                                                    <input
                                                                        type="text"
                                                                        name="fromunit"
                                                                        
                                                                        style={{height:"100%",color:"black"}}
                                                                        value={row.fromunit}
                                                                        // onChange={(e) => handleGSTInputChange(index, e)}
                                                                        onChange={(e) => {
                                                                          if (e.target.value.length <= 15) { // Set the max length to 5
                                                                              handleGSTInputChange(index, e);
                                                                          };}}
                                                                        
                                                                    />
                                                                       
                                                                  
                                                                    </div>
                                                                </td>
                                                                <td style={{width: "10%",border: "transparent",}} >
                                                                  <span style={{fontSize : "25px" , display:"flex", justifyContent:"center" ,alignItems:"center", marginBottom:"10px"}}>=</span>
                                                                  
                                                                </td>
                                                                <td>
                                                                <div className="table_td_div">
                                                                    <input
                                                                        type="number"
                                                                        name="toqty"
                                                                        
                                                                        style={{height:"100%",color:"black"}}
                                                                        value={row.toqty}
                                                                        onChange={(e) => handleGSTInputChange(index, e)}
   
                                                                    />
                                                                       
                                                                   
                                                                </div>
                                                                </td>
                                                                <td>
                                                                    <div className="table_td_div">
                                                                        <input
                                                                            type="text"
                                                                            name="tounit"
                                                                            style={{height:"100%", padding:"0px",color:"black"}}
                                                                            value={row.tounit}
                                                                            // onChange={(e) => handleGSTInputChange(index, e)}
                                                                            onChange={(e) => {
                                                                              if (e.target.value.length <= 15) { // Set the max length to 5
                                                                                  handleGSTInputChange(index, e);
                                                                              };}}
                                                                            
                                                                        />
                                                                    </div>
                                                                </td>
                                                                
                                                                <td>
                                                                    <button  style={{display:"flex", width:"100%", alignItems:"center", justifyContent:"center"}} title='Delete' type="button" className="vendordele1a" onClick={() => removeRow(index)}>
                                                                        <IoTrash className="vendortrash" />
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div style={{display:"flex"}} className="mt-3">
                                                <div style={{width:"50%"}}>
                                                    <Button onClick={addRow} color='primary' className='btn2a'>
                                                        Add Row
                                                    </Button>
                                                </div>
                                                <div className='popbtn' style={{width:"50%"}}>
                                                    <Button className='btn2a' color='primary' type='button' onClick={handleSave}>Save</Button>{' '}
                                                    
                                                </div>
                                            </div>
                                        </div>
                  </div>
          : null}



                 
                </TabContent>
                <div className="mb-3" style={{gap:"10px",display:"flex", justifyContent:"flex-end"}} >
                    <button className='btn2' onClick={handleBack} type='button' disabled={activeTab === 1}>
                        <IoArrowBackOutline />&nbsp; Previous
                    </button>
                    <button className='btn2' onClick={handleNext} type="button" disabled={activeTab === 5}>
                        Next &nbsp;<IoArrowForward />
                    </button>
                </div>
                        
                    </div>
                </CardBody>
            </Card>
        </Col>
    )}
     <div className="mt-3" style={{gap:"10px",display:"flex", justifyContent:"flex-end", marginBottom:"20px"}}>
                        <button className='btn2' color='primary' ref={submitButtonRef} onKeyDown={(event) => handleArrowNavigation(event, 'save')} type='submit'>
                            {submitbtn}
                        </button>
                        <button className='btn1' ref={cancelButtonRef} onKeyDown={(event) => handleArrowNavigation(event, 'cancel')}onClick={resetForm} type='reset'>
                            Cancel
                        </button>
                        
                    </div>
  </Form> 

  
  <Col sm="12" xl="6" className='vendorcol1a'>
                        <Card className=''>
                            <div style={{ display: "flex" }}>
                                <div className='headercl'>
                                <div className="h5cus">
                                    <h5 style={{paddingTop:"25px",paddingLeft:"30px"}} className="h5title">Item List </h5>
                                </div>
                                </div>
                                <div className='selectdel' style={{ display: "flex", alignItems: "center", justifyContent: "flex-end", width: "40%" }}>
                                    <Button color="danger" className='btn1 mt-4' onClick={handleDelete} disabled={selectedIds.length === 0}><IoTrash className="vendortrash1" />Delete</Button>
                                </div>
                            </div>
                        <CardBody>
                            <div className="table-responsive">
                                <InputGroup className="mb-3">
                                    <Input
                                        type="text"
                                        placeholder="Search"
                                        value={searchTerm}
                                        onChange={(e) => setSearchTerm(e.target.value)}
                                        className="form-control input-no-animation inputfoc inputform"
                                        style={{borderColor: "#afafaf",fontSize:"12px" }}
                                    />
                                    <InputGroupText >
                                        <FaSearch />
                                    </InputGroupText>
                                </InputGroup>
                                <DataTable
                                    columns={columns}
                                    data={paginatedData}
                                    selectableRows
                                    selectableRowsSelected={selectedIds}
                                    onSelectedRowsChange={handleCheckboxChange}
                                    clearSelectedRows={clearRows}
                                    pagination
                                    paginationServer
                                    customStyles={customStylesTable}
                                    striped={false}
                                    paginationTotalRows={categories.length}
                                    paginationPerPage={rowsPerPage}
                                    progressPending={loading}
                                    autoWidth={true}
                                    paginationComponentOptions={{
                                        noRowsPerPage: true, // Disable option to change rows per page
                                    }}
                                    onChangePage={handlePageChange} // Handle pagination
                                    highlightOnHover
                                />
                            </div>
                        </CardBody>
                        </Card>
                    </Col>
</Fragment>
  );
}

export default ItemL3;
