 
import { Breadcrumbs ,H2, H3, H4, H5,H6} from '../../../../../AbstractElements';
import '../../forms.css';
 import React, { Fragment, useEffect, useState, useRef } from 'react';
import { Container, Row, Col, Card, CardBody, Form, FormGroup, Label, Input, Table, CardHeader, Button,InputGroup ,InputGroupText, CardColumns} from 'reactstrap';
import Select from 'react-select';
 import Swal from 'sweetalert2';
import { IoTrash } from "react-icons/io5";
import DataTable from 'react-data-table-component';
import axiosInstance from '../../../../../Auth/AxiosInstance';
import Highlighter from 'react-highlight-words';
import { FaRegEdit,FaSearch } from "react-icons/fa";
import { IoMdClose } from "react-icons/io";
 
function Withhold_Tax_Assign () {
    const [taxCode,setTaxCode]=useState(null);
    const [description, setDescription] = useState('');
    const [natureofasseee,setNatureofassee]=useState([]);
    const [taxcodeOption,setTaxcodeOption]=useState([]);
    const [assignCoaOption,setAssignCoaOption]=useState([]);
    const [submittedRows, setSubmittedRows] = useState([]);
    const [categories, setCategories] = useState([]);
    const [clearRows, setClearRows] = useState(false);
    const [selectedIds, setSelectedIds] = useState([]);
    const [editId, setEditId] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);
    const [nameError1 , setNameError1] = useState(null);
    const [descriptionError1, setDescriptionError1] = useState(null);
    const [nameError2 , setNameError2] = useState(null);
    const [nameError3 , setNameError3] = useState(null);
    const [descriptionError2, setDescriptionError2] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [loading, setLoading] = useState(true);
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredCategories, setFilteredCategories] = useState([]);
    const [submitbtn, setSubmintbtn] =useState('Save');
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const cancelButtonRef = useRef(null);
    const submitButtonRef = useRef(null);
    const [whtaxfocus, setWhtaxfocus] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [dropdownSalOpen, setDropdownSalOpen] = useState(false);
    const rowsPerPage = 10;
    const includetaxopt = [
      { value: '1', label: 'Yes' },
      { value: '0', label: 'No' }
    ];
    const [fixedRow, setFixedRow] = useState([ 
      { 
          natureofassessee: '' ,  // Assuming the ID of the NatureOfAssessee object is 1
          fromdate:''  ,
          todate: '',
          singletransaction:''  ,
          aggregatetransaction: '' ,
          includetax: '' ,  // 1 for 'Yes'
          taxpercentage:''  ,
          pannonavailability: '' ,
          assgincoa:  '',
      }]);
      const addRow = () => {
      const newRow ={ 
        natureofassessee: '' ,  // Assuming the ID of the NatureOfAssessee object is 1
        fromdate:''  ,
        todate: '',
        singletransaction:''  ,
        aggregatetransaction: '' ,
        includetax: '' ,  // 1 for 'Yes'
        taxpercentage:''  ,
        pannonavailability: '' ,
        assgincoa:  '',
    }
        setFixedRow([...fixedRow,newRow ]);
     };
    const removeRow = (index) => {
    const newRows = [...fixedRow];
    newRows.splice(index, 1); // Remove the row at the specified index
    setFixedRow(newRows);
    if(newRows.length === 0){
        setFixedRow([
            { 
              natureofassessee: '' ,  // Assuming the ID of the NatureOfAssessee object is 1
              fromdate:''  ,
              todate: '',
              singletransaction:''  ,
              aggregatetransaction: '' ,
              includetax: '' ,  // 1 for 'Yes'
              taxpercentage:''  ,
              pannonavailability: '' ,
              assgincoa:  '',
            }
        ]);
    }}
    
    const handleInputChange = (index, event) => {
    const { name, value } = event.target;
    const updatedRows = [...fixedRow];
    if(name === 'singletransaction' ||name === 'aggregatetransaction'){
      updatedRows[index][name] = parseInt(value,10)
    }
    else if (name === 'taxpercentage' || name === 'pannonavailability') {
      updatedRows[index][name] = parseFloat(value);
    }
    else{
      updatedRows[index][name] = value;
    }
    setFixedRow(updatedRows);
  };
   const handleSelectChange = (option) => {
   setTaxCode(option); // Set the selected option
  
    setDescription(option.description); // Auto-fill description field
  
  };
    
  const handleCancelWhTax =()=>{
    setFixedRow([{
          natureofassessee: '' ,  // Assuming the ID of the NatureOfAssessee object is 1
          fromdate:''  ,
          todate: '',
          singletransaction:''  ,
          aggregatetransaction: '' ,
          includetax: '' ,  // 1 for 'Yes'
          taxpercentage:''  ,
          pannonavailability: '' ,
          assgincoa:  '',
        }
    ]);
    setWhtaxfocus(false);
    };

    const handleSaveWh = () => { 
       const whEmptyFieldsMessages = fixedRow
      .map((index) => {
        const missingFields = [];
        if (fixedRow[0].natureofassessee === '') missingFields.push("Natureofassessee");
        if (fixedRow[0].fromdate === '' || isNaN(Date.parse(fixedRow[0].fromdate))) missingFields.push("Fromdate");
        if (fixedRow[0].todate === '' || isNaN(Date.parse(fixedRow[0].todate))) missingFields.push("Todate");
        if (fixedRow[0].singletransaction === '' || isNaN(fixedRow[0].singletransaction)) missingFields.push("Singletransaction");
        if (fixedRow[0].includetax === '' ||isNaN(fixedRow[0].includetax)) missingFields.push("Includetax");
        if (fixedRow[0].aggregatetransaction === '' || isNaN(fixedRow[0].aggregatetransaction)) missingFields.push("Aggregatetransaction");
        if (fixedRow[0].taxpercentage === '' || isNaN(fixedRow[0].taxpercentage)) missingFields.push("Taxpercentage");
        if (fixedRow[0].pannonavailability === '' || isNaN(fixedRow[0].pannonavailability)) missingFields.push("Pannonavailability");
        if (fixedRow[0].assgincoa === '') missingFields.push("Assgincoa");

        if (missingFields.length > 0) {
            return `Row ${index + 1} missing fields: ${missingFields.join(", ")}`;
        }
        return null;
      })
      .filter(Boolean);

      if (whEmptyFieldsMessages.length > 0) {
          alert(`WH_Tax Assign Detials:\n${whEmptyFieldsMessages.join("\n")}`);
      } 
       else {
         setWhtaxfocus(false);
      }
   };
   
  const paginatedData = Array.isArray(categories) ? categories.slice((currentPage - 1) * rowsPerPage, currentPage * rowsPerPage) : [];
  console.log(filteredCategories);
    const columns = [
      {
        name: (<div className='dataTableDiv'>SN</div>),
        selector: (row, index) => index + 1 + (currentPage - 1) * rowsPerPage,
        // width:'10%',
        width:"70px",
        cell:(row, index) =>(
          <div className='dataTableDiv' style={{textAlign: 'left !important',display:"flex", justifyContent:"flex-start !important" ,paddingLeft:"4px"}}>{index + 1 + (currentPage - 1) * rowsPerPage}</div>
        )
      },
      {
        name: (<div className='dataTableDiv'>Tax code</div>),
        // width:"20%",
        width:"100px",
        selector: row => row.taxcode_name,
        cell: row => (
          <div style={{textAlign: 'left !important',display:"flex", justifyContent:"flex-start !important" ,paddingLeft:"4px"}}>
            <Highlighter
              highlightClassName="highlighted-text"
              className='dataTableDiv'
              searchWords={[searchTerm]}
              autoEscape={true}
              textToHighlight={row.taxcode_name}
          />
          </div>
        ),
      },
      
      {
        name: (<div className='dataTableDiv'>Nature of assessee</div>),
        // width:"20%",
        width:"200px",
        selector: row => row.data_grid_view[0].natureofassessee_name,
        // sortable:true,
        cell: row => (
          <div style={{textAlign: 'left !important',display:"flex", justifyContent:"flex-start !important" ,paddingLeft:"4px"}}>
            <Highlighter
              highlightClassName="highlighted-text"
              className='dataTableDiv'
              searchWords={[searchTerm]}
              autoEscape={true}
              textToHighlight={row.data_grid_view[0].natureofassessee_name.toString()}
          />
          </div>
        ),
      },
      {
        name: (<div className='dataTableDiv'>From Date</div>),
        // width:"20%",
        width:"150px",
        selector: row => row.data_grid_view[0].fromdate,
        cell: row => (
          <div style={{textAlign: 'left !important',display:"flex", justifyContent:"flex-start !important" ,paddingLeft:"4px"}}>
            <Highlighter
              highlightClassName="highlighted-text"
              className='dataTableDiv'
              searchWords={[searchTerm]}
              autoEscape={true}
              textToHighlight={row.data_grid_view[0].fromdate.toString()}
          />
          </div>
        ),
      },
      {
        name:'',
        width:'5px',
      },
      {
        name: (<div className='dataTableDiv'>To Date</div>),
        // width:"20%",
        width:"150px",
        selector: row => row.data_grid_view[0].todate,
        cell: row => (
          <div style={{textAlign: 'left !important',display:"flex", justifyContent:"flex-start !important" ,paddingLeft:"12px"}}>
            <Highlighter
              highlightClassName="highlighted-text"
              className='dataTableDiv'
              searchWords={[searchTerm]}
              autoEscape={true}
              textToHighlight={row.data_grid_view[0].todate.toString() }
          />
          </div>
        ),
      },
      {
        name: (<div className='dataTableDiv'>Single Transaction</div>),
        // width:"23%",
        width:"150px",
        selector: row => row.data_grid_view[0].singletransaction,
        cell: row => (
          <div style={{textAlign: 'left !important',display:"flex", justifyContent:"flex-start !important" ,paddingLeft:"12px"}}>
            <Highlighter
              highlightClassName="highlighted-text"
              className='dataTableDiv'
              searchWords={[searchTerm]}
              autoEscape={true}
              textToHighlight={row.data_grid_view[0].singletransaction.toString()}
          />
          </div>
        ),
        
      },
      {
        name: (<div className='dataTableDiv'>Aggregate Transaction</div>),
        // width:"23%",
        width:"150px",
        selector: row => row.data_grid_view[0].aggregatetransaction,
        cell: row => (
          <div style={{textAlign: 'left !important',display:"flex", justifyContent:"flex-start !important" ,paddingLeft:"12px"}}>
            <Highlighter
              highlightClassName="highlighted-text"
              className='dataTableDiv'
              searchWords={[searchTerm]}
              autoEscape={true}
              textToHighlight={row.data_grid_view[0].aggregatetransaction.toString()}
          />
          </div>
        ),
        
      },
      {
        name: (<div className='dataTableDiv'>Include Tax</div>),
        // width:"23%",
        width:"100px",
        selector: row => row.data_grid_view[0].includetax,
        cell: row => (
          <div style={{textAlign: 'left !important',display:"flex", justifyContent:"flex-start !important" ,paddingLeft:"12px"}}>
            <Highlighter
              highlightClassName="highlighted-text"
              className='dataTableDiv'
              searchWords={[searchTerm]}
              autoEscape={true}
              textToHighlight={row.data_grid_view[0].includetax === 1 ? "Yes" : "No"}
          />
          </div>
        ),
        
      },
      {
        name: (<div className='dataTableDiv'>Tax Percentage</div>),
        // width:"20%",
        width:"110px",
        selector: row => row.data_grid_view[0].taxpercentage,
        cell: row => (
          <div style={{textAlign: 'left !important',display:"flex", justifyContent:"flex-start !important" ,paddingLeft:"15px"}}>
            <Highlighter
              highlightClassName="highlighted-text"
              className='dataTableDiv'
              searchWords={[searchTerm]}
              autoEscape={true}
              textToHighlight={row.data_grid_view[0].taxpercentage.toString()}
          />
          </div>
        ),
      },
      {
        name: (<div className='dataTableDiv'>PAN non-availability %</div>),
        // width:"20%",
        width:"100px",
        selector: row => row.data_grid_view[0].pannonavailability,
        cell: row => (
          <div style={{textAlign: 'left !important',display:"flex", justifyContent:"flex-start !important" ,paddingLeft:"15px"}}>
            <Highlighter
              highlightClassName="highlighted-text"
              className='dataTableDiv'
              searchWords={[searchTerm]}
              autoEscape={true}
              textToHighlight={parseFloat(row.data_grid_view[0].pannonavailability).toString()}
          />
          </div>
        ),
      },
      {
        name: (<div className='dataTableDiv'>Assign COA</div>),
        // width:"20%",
        width:"100px",
        selector: row => row.data_grid_view[0].assigncoa_name,
        cell: row => (
          <div style={{textAlign: 'left !important',display:"flex", justifyContent:"flex-start !important" ,paddingLeft:"15px"}}>
            <Highlighter
              highlightClassName="highlighted-text"
              className='dataTableDiv'
              searchWords={[searchTerm]}
              autoEscape={true}
              textToHighlight={row.data_grid_view[0].assigncoa_name.toString()}
          />
          </div>
        ),
      },
      {
        name:  (<div className='dataTableDiv'>Action</div>),
        center: true,
        // width:'15%',
        cell: row => (
          <div className="buttonven">
            <button
              className="vendorupdate"
              color='primary'
              title='Edit'
              type='button'
              onClick={() => handleUpdateClick(row)}
            >
              <FaRegEdit className="vendortrash"/>
            </button>
            <button title='Delete' className="vendordele" onClick={() => handleDeleteCategory(row.id)}>
              <IoTrash className="vendortrash" />
            </button>
          </div>
        ),
      }
    ];
    const customStylesTable = {
      header: {
        style: {
          minHeight: '40px',
          backgroundColor: '#f0f0f0',
          color: '#333',
        },
      },
      headRow: {
        style: {
          backgroundColor: '#f0f0f0',
          minHeight: '40px'
        },
      },
      headCells: {
        style: {
          fontSize: '12px',
          fontWeight: 'bold',
          textAlign: 'left',
          color: '#000',
        },
      },
      rows: {
        style: {
          minHeight: '40px',
          borderBottom: '1px solid #ddd',
        },
        cells: {
          style: {
            textAlign: 'left',
            fontSize:"12px"
          },
        },
      },
    };
    
    const customStyles = {
      control: (provided, state) => ({
          ...provided,
          minHeight: '30px',
          borderColor: state.isFocused ? 'blue' : '#afafaf', // Change border color on focus
          boxShadow: state.isFocused ? '0 0 0 1px blue' : 'none', // Optional: Add shadow on focus
          '&:hover': {
              borderColor: state.isFocused ? 'blue' : 'black', // Keep the same color on hover
          },
      }),
      placeholder: (provided) => ({
          ...provided,
          fontSize: '12px', // Change the font size for the placeholder
          color: '#aaa', // Optional: Change placeholder color
        }),
      valueContainer: (provided) => ({
          ...provided,
          height: '30px', // Adjust the container height
          padding: '0 6px',
        }),
        input: (provided) => ({
          ...provided,
          margin: '0px', // Remove default margin for the input inside the select
        }),
        indicatorsContainer: (provided) => ({
          ...provided,
          height: '30px', // Adjust the indicator container height
        }),
  };
 
    useEffect(() => {
      fetchCategories(currentPage);
      fetchTaxCodeOptions();
      fetchNatureofAssessOptions();
      fetchAssignCoaOptions();
    }, [currentPage,searchTerm]); 

    useEffect(() => {
      if (searchTerm.trim() === '') {
        setFilteredCategories(categories || []); // Ensure categories is an array or empty array
      } else {
        const safeCategories = Array.isArray(categories) ? categories : [];
        const filteredData = safeCategories.filter(item =>
          item.name && item.name.toLowerCase().includes(searchTerm.toLowerCase())
        );
        setFilteredCategories(filteredData);
      }
      fetchCategories(currentPage);
      // fetchSelectOptions();
      fetchTaxCodeOptions();
      fetchNatureofAssessOptions();
      fetchAssignCoaOptions();
    }, [searchTerm, currentPage]);
  
    const fetchCategories = async (page) => {
      setLoading(true);
      try {
        let response;
        if (searchTerm) {
            response = await axiosInstance.get( `taxation/whtaxassign/search/?name=${searchTerm}`);
            console.log(response,"dataforresponsedataforresponsedataforresponse")
        } else {
            response = await axiosInstance.get(`taxation/whtaxassign/get/`);
            console.log(response,"manikandan");
        }
        setCategories(response.data);
        setFilteredCategories(response.data); 
      } catch (error) {
        if (error.response && error.response.status === 404 && page > 1) {
          handlePageChange(page - 1);
        } else {
          console.error("Error fetching categories:", error);
        }
      }finally {
        setLoading(false);
      }
    }; 

    const handlePageChange = (page) => {
      setCurrentPage(page);
      fetchCategories(page);
    };
    
    const fetchTaxCodeOptions = async (editId = null) => {
      try {
        const response = await axiosInstance.get('/taxation/withholdtax/get/');
        console.log(editId,'snckdsvv');
        const opt = response.data.map(category => ({
          value: category.id,
          label: category.name,
          description: category.description
        }));
        // setOpt1(opt);
        console.log(opt,'options');
        const filteredOptions = response.data
        .filter(category => category.id !== editId)
        .map(category => ({
          value: category.id,
          label: category.name,
          description: category.description
        }));
        setTaxcodeOption([...filteredOptions]);
      } catch (error) {
        console.error('Error fetching select options:', error);
      }
    };

    const fetchNatureofAssessOptions = async (editId = null) => {
      try {
        const response = await axiosInstance.get('/taxation/natureofassessee/get/');   
        console.log(response,"manisanjaimanisan")     
        const filteredOptions = response.data
        .filter(category => category.id !== editId) // Exclude the category being edited
        .map(category => ({
          value: category.id,
          label: category.name
        }));
        setNatureofassee([ ...filteredOptions]);
      } catch (error) {
        console.error('Error fetching select options:', error);
      }
     };

     const fetchAssignCoaOptions = async (editId = null) => {
      try {
        const response = await axiosInstance.get('/generalledger/coaledger/get/');
        console.log(editId,'snckdsvv');
         
        const filteredOptions = response.data
        .filter(category => category.id !== editId) // Exclude the category being edited
        .map(category => ({
          value: category.id,
          label: category.name
        }));
        setAssignCoaOption([ ...filteredOptions]);
      } catch (error) {
        console.error('Error fetching select options:', error);
      }
    }; 
  
    const handleSubmit = async (e) => {
       e.preventDefault(); 
      const payload = {
        taxcode :taxCode ? taxCode.value : null,
        data_grid_view: fixedRow && (fixedRow[0].natureofassessee !== ''  && fixedRow[0].fromdate !== '' && fixedRow[0].todate !== '' && fixedRow[0].singletransaction !== '' && fixedRow[0].aggregatetransaction !== '' && fixedRow[0].includetax !== '' && fixedRow[0].taxpercentage !== '' && fixedRow[0].pannonavailability !== '' && fixedRow[0].assgincoa !== '' ) ? fixedRow : alert("WH_Assign Detials should not be null"),
     
      };
      setNameError1(null);
      setDescriptionError1(null);
      try {
        if (isEditing) {
          await axiosInstance.put(`taxation/whtaxassign/${editId}/`, payload); 
          setIsEditing(false);
        } else {
          await axiosInstance.post('taxation/whtaxassign/create/', payload);
          setErrorMessage(null);
        }
        fetchTaxCodeOptions();
        fetchNatureofAssessOptions();
        fetchAssignCoaOptions();
        fetchCategories(currentPage);
        resetForm();
        } catch (error) {
        console.error('Error:', error);
        if (error.response && error.response.data) {
          const nameError = error.response.data.name && Array.isArray(error.response.data.name) ? error.response.data.name[0] : null;
          const descriptionError = error.response.data.description && Array.isArray(error.response.data.description) ? error.response.data.description[0] : null;
          const existsError = error.response.data.error && Array.isArray(error.response.data.error) ? error.response.data.error[0] : null;
          console.log(nameError, 'Name Error');
          console.log(descriptionError, 'Description Error');
          if (nameError && descriptionError) {
          } else if (nameError) {
            setNameError1(nameError)
          } else if (descriptionError) {
            setDescriptionError1(descriptionError);
          } else if (existsError) {
            setErrorMessage(existsError);
   
          }
        } else {
          setErrorMessage('An error occurred while submitting the form.');
        }
      }
   
      };
  
    const handleUpdateClick = async (category) => {        
      setSubmintbtn('Save');
      setTaxCode(category.taxcode_name);
      setTaxCode({ value: category.taxcode, label: category.taxcode_name });
      const categorys = taxcodeOption.find(option=> option.value === category.taxcode);
      setDescription(categorys.description); 
      fetchTaxCodeOptions(category.id);
      fetchAssignCoaOptions(category.id);
      fetchNatureofAssessOptions(category.id); 
      setSubmintbtn('Update');
      setEditId(category.id);
      setClearRows(!clearRows);
      setIsEditing(true);
      setFixedRow([]);

      setFixedRow(
          category.data_grid_view.map((assign) => ({
              natureofassessee: assign.natureofassessee,
              fromdate: assign.fromdate,
              todate: assign.todate,
              singletransaction: assign.singletransaction,
              aggregatetransaction: assign.aggregatetransaction,
              includetax: assign.includetax,
              taxpercentage: assign.taxpercentage,
              pannonavailability: assign.pannonavailability,
              assgincoa: assign.assgincoa
          }))
      );
      
      };
  
  
    const resetForm = () => {
      setNameError2(null);
      setNameError3(null);
      setDescriptionError2(null);
      setTaxCode(null);
      setDescription('');
      setIsEditing(false);
      setNameError1(null);
      setClearRows(!clearRows);
      setSubmintbtn('Save')
      setDescriptionError1(null);
      // setEditId(categories.id);
      setErrorMessage('');
      setFixedRow([ 
        { natureofassessee: '' ,  // Assuming the ID of the NatureOfAssessee object is 1
          fromdate:''  ,
          todate: '',
          singletransaction:''  ,
          aggregatetransaction: '' ,
          includetax: '' ,  // 1 for 'Yes'
          taxpercentage:''  ,
          pannonavailability: '' ,
          assgincoa:  '',
        }]);
    };
  
    const handleCheckboxChange = (selectedRows) => {
      setSelectedIds(selectedRows.selectedRows.map(row => row.id));
    };

    const handleDelete = async () => {
      try {
          await axiosInstance.delete('/taxation/whtaxassign/delete/', {
            data: { ids: selectedIds },
          });
          fetchCategories(currentPage);
          fetchTaxCodeOptions();
          fetchNatureofAssessOptions();
          fetchAssignCoaOptions();
          setSelectedIds([]);
          resetForm();
        // }
      } catch (error) {
        console.error('Error:', error.response.data);
        const errorMessage = JSON.stringify(error.response.data.detail, null, 2);
        Swal.fire('Error!',errorMessage , 'error');
      }
    };
  
    const handleDeleteCategory = async (id) => {
      
        try {
          await axiosInstance.delete(`taxation/whtaxassign/${id}/`);
          fetchCategories(currentPage);
        } catch (error) {
          console.error('Error deleting category:', error.response.data);
          // const errorMessage = JSON.stringify(error.response.data.detail, null, 2);
          Swal.fire('Error!', errorMessage, 'error');
        }
  
        setSelectedIds([]);
        fetchTaxCodeOptions();
        fetchNatureofAssessOptions();
        fetchAssignCoaOptions();
        resetForm();
      // }
    };
  
    const getHighlightedText = (text, highlight) => {
      const escapedHighlight = highlight.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&');
      const parts = text.split(new RegExp(`(${escapedHighlight})`, 'gi'));
      return (
        <span>
          {parts.map((part, index) =>
            part.toLowerCase() === highlight.toLowerCase() ? (
              <span key={index} style={{fontWeight:'bold' }}>{part}</span>
            ) : (
              part
            )
          )}
        </span>
      );
    };
  
    const handleEnter = (event) => {
    if (event.target && nameError1 && (event.key === 11 || event.key === 13)) {
      event.target.focus();  
    } 
    else if (event.keyCode === 13 && event.target.form) {
      if (event.target.tagName === 'INPUT' || event.target.tagName === 'TEXTAREA') {
        event.target.value = event.target.value.trim();
      }
      if (dropdownOpen) {
        return; 
      } else {
        const form = event.target.form;
        const index = Array.prototype.indexOf.call(form.elements, event.target);
        const nextElement = form.elements[index + 1];
        if (nextElement) {
          nextElement.focus();
        }
        event.preventDefault();
      }
    }
    const { value } = event.target;
    if (
      (event.key === ' ' && value.length === 0) || 
      (event.key === ' ' && value.endsWith(' ')) || 
      (event.key === ' ' && value.includes('  '))
    ) {
      event.preventDefault();
      return;
    }
  };
  
  const handleArrowNavigation = (event, buttonType) => {
    if (event.key === 'ArrowRight' && buttonType === 'save' && cancelButtonRef.current) {
      cancelButtonRef.current.focus();
    } else if (event.key === 'ArrowLeft' && buttonType === 'cancel' && submitButtonRef.current) {
      submitButtonRef.current.focus();
    }
  };
    const handleBlur = (e) => {
      setNameError2(null);
      setDescriptionError2(null);
      if (e.target.tagName === 'INPUT' || e.target.tagName === 'TEXTAREA') {
        e.target.value = e.target.value.trim();  // Ensures the value is trimmed properly
        
        if (nameError1) {
            e.target.focus();
        }else if (descriptionError1) {
          e.target.focus(); 
        }
      }
    };   
 

  return (
    <Fragment>
    <Breadcrumbs mainTitle="WH_TaxAssign" parent="Taxation" title="WH_TaxAssign" />
        <Container fluid={true} className='Container1'>   
        <Form className="theme-form" onSubmit={handleSubmit} >
            <Col >
                <Card>
                    <div className='formdiv1ba'>
                        <div className='rowForm1'>
                            <div className='formcol1'>
                             <FormGroup className='formin1'>
                                             <Label style={{fontWeight:"500"}} className="col-form-label">Tax Code</Label>
                                              <Select
                                                  className="forminput"
                                                  value={taxCode}
                                                  styles={customStyles}
                                                  onChange={handleSelectChange}
                                                  onMenuOpen={() => {
                                                    setDropdownSalOpen(true);
                                                  }}
                                                  onMenuClose={() => {
                                                    setDropdownSalOpen(false);
                                                  }}

                                                  options={taxcodeOption}
                                                  filterOption={(option, inputValue) => 
                                                    option.label.toLowerCase().startsWith(inputValue.toLowerCase())
                                                  }
                                                  formatOptionLabel={(option, { inputValue }) => 
                                                    getHighlightedText(option.label, inputValue)
                                                  }
                                                  onKeyDown={handleEnter}
                                                  required 
                                              />
                                        </FormGroup>
                                        <FormGroup className='formin1'>
                                            <Label style={{fontWeight:"500"}} className="col-form-label">Description</Label>
                                            <Input   
                                              onBlur={handleBlur}
                                              onChange={handleSelectChange}
                                              className="forminput"
                                              type="text"
                                              value={description}
                                              style={{color:"black",borderColor: "#afafaf"}}
                                              onKeyDown={handleEnter}
                                              readOnly
                                              />
                                      
                                        </FormGroup>
                            </div>
                        </div>
                        
                    </div>
                    <div style={{display:"flex",justifyContent:"left",marginLeft:"25px",marginBottom:"20px",marginTop:"-30px"}}  >
                    <div className="mt-3">
                        <button onClick={()=>setWhtaxfocus(true)} type='button'   color='primary' className='btn2a' >
                            WH_Tax Assign Detials
                        </button>
                    </div>
                   
                </div>  
                <div  style={{gap:"10px",display:"flex", justifyContent:"flex-end", marginBottom:"20px",marginRight:"20px"}}>
                        <button className='btn2'  color='primary'  ref={submitButtonRef}   type='submit' /* || !isSaveEnabled  (form_date === '') || (to_date === '') || (single_Transaction === '') || (aggre_trans=== '') || (taxPercent === '') || (panNonAval === '')  || (assignCoa === null) || (includetax === '')*/ >{''}
                            {submitbtn}
                        </button>
                        <button className='btn1' ref={cancelButtonRef} onKeyDown={(event) => handleArrowNavigation(event, 'cancel')} onClick={resetForm}>
                            Cancel
                        </button>
                        
                    </div>
                
                </Card>
            </Col>
            
            {whtaxfocus ?             
             <div className="popup">
             <div className='popup-inner' style={{ width: '80%',  margin: 'auto' }} >
                  
                 <div className='popcls'>
                    <div className='popclsdiv1'>
                        <h5>Withhold Tax Assign</h5>
                    </div>
                    <div className='popclsdiv2'>
                        <button className='popclsbtn' type='buttom' onClick={handleCancelWhTax}><IoMdClose className='clsico' color='red'/></button>
                    </div>
                 </div>
                 <div className="table-container">
                     <table style={{width:"100%"}}>
                         <thead>
                             <tr style={{height:"20px"}}>
                                
                               <th style={{textAlign:"center"}}>Nature of assessee</th>
                               <th style={{textAlign:"center"}}>From Date</th>
                               <th style={{textAlign:"center"}}>To Date</th>
                               <th style={{textAlign:"center"}}>Single Transaction</th>
                               <th style={{textAlign:"center"}}>Aggregate Transaction</th>
                               <th style={{textAlign:"center"}}>Include Tax</th>
                               <th style={{textAlign:"center"}}>Tax Percentage</th>
                               <th style={{textAlign:"center"}}>PAN non-availability %</th>
                               <th style={{textAlign:"center"}}>Assign COA</th>
                               <th style={{textAlign:"center"}}>Action</th>
                             </tr>
                         </thead>
                         <tbody>
                             {fixedRow.map((row, index) => (
                                 <tr key={index} style={{height:"20px"}}>
                                     <td >
                                         <div className="table_td_div">
                                     
                                         <select
                                             type="text"
                                             id="natureofassessee"
                                             name="natureofassessee"
                                             style={{height:"100%",color:"black"}}
                                             value={row.natureofassessee}
                                             onChange={(e) => handleInputChange(index, e)}
                                             required
                                         >
                                             <option value={''} disabled selected>Select Category</option>
                                             {natureofasseee.map((natureofasseee)=>(
                                                 <option key={natureofasseee.value} value={natureofasseee.value}>{natureofasseee.label}</option>
                                             ))}
                                         </select>
                                         </div>
                                     </td>
                                     <td>
                                         <div className="table_td_div">
                                             <input
                                                 type="date"
                                                 name="fromdate"
                                                 style={{height:"100%" ,padding:'0px',color:"black"}}
                                                 value={row.fromdate}
                                                 onChange={(e) => handleInputChange(index, e)}
                                                 required
                                             />
                                         </div>
                                     </td>
                                     <td>
                                         <div className="table_td_div">
                                             <input
                                                 type="date"
                                                 name="todate"
                                                 value={row.todate}
                                                 style={{height:"100%",padding:'0px',color:"black"}}
                                                 onChange={(e) => handleInputChange(index, e)}
                                                 required
                                             />
                                         </div>
                                     </td>
                                     <td>
                                         <div className="table_td_div" style={{ width: "150px" }}>
                                             <input
                                                 type="number"
                                                 name="singletransaction"
                                                 style={{height:"100%",color:"black"}}
                                                 value={row.singletransaction}
                                                 onChange={(e) => handleInputChange(index, e)}
                                                 required
                                             />
                                         </div>
                                     </td>
                                     <td>
                                         <div className="table_td_div" style={{ width: "150px" }}>
                                             <input
                                                 type="number"
                                                 name="aggregatetransaction"
                                                 value={row.aggregatetransaction}
                                                 style={{height:"100%",color:"black"}}
                                                 onChange={(e) => handleInputChange(index, e)}
                                                 required  
                                             />
                                         </div>
                                     </td>
                                     <td >
                                         <div className="table_td_div">
                                         <select
                                             type="text"
                                             name="includetax"
                                             style={{height:"100%",color:"black"}}
                                             value={row.includetax}
                                             onChange={(e) => handleInputChange(index, e)}
                                             required 
                                         >
                                             <option value={''} disabled selected>Select Category</option>
                                             {includetaxopt.map((includetax)=>(
                                                 <option key={includetax.value} value={includetax.value}>{includetax.label}</option>
                                             ))}
                                         </select>
                                         </div>
                                     </td>
                                     <td>
                                         <div className="table_td_div" style={{ width: "150px" }}>
                                             <input
                                                 type="number"
                                                 placeholder='%'
                                                 name="taxpercentage"
                                                 value={row.taxpercentage}
                                                 style={{height:"100%",color:"black"}}
                                                 onChange={(e) => handleInputChange(index, e)}
                                                 required
                                             />
                                         </div>
                                     </td>
                                     <td>
                                         <div  className="table_td_div" style={{ width: "150px" }}>
                                             <input
                                                 type="number"
                                                 placeholder='%'
                                                 name="pannonavailability"
                                                 value={row.pannonavailability}
                                                 style={{height:"100%",color:"black"}}
                                                 onChange={(e) => handleInputChange(index, e)}
                                                 required
                                             />
                                         </div>
                                     </td>
                                     <td>
                                     <div className="table_td_div">
                                     <select
                                             type="text"
                                             name="assgincoa"
                                             style={{height:"100%" ,color:"black"}}
                                             value={row.assgincoa}
                                             onChange={(e) => handleInputChange(index, e)}
                                             required
                                         >
                                             <option value={''} disabled selected>Select Category</option>
                                             {assignCoaOption.map((assignCoaOption)=>(
                                                 <option key={assignCoaOption.value} value={assignCoaOption.value}>{assignCoaOption.label}</option>
                                             ))}
                                         </select>
                                     </div>
                                     </td>
                                     <td>
                                      <button 
                                          title='Delete' 
                                          className="vendordele1" 
                                          onClick={() => removeRow(index)} 
                                          type="button"
                                      >
                                          <IoTrash className="vendortrash" />
                                      </button>
                                    </td>
                                 </tr>
                             ))}
                         </tbody>
                     </table>
                 </div>
                 <div style={{display:"flex"}} className="mt-3">
                     <div style={{width:"50%"}}>
                         <Button onClick={addRow} color='primary' className='btn2a'>
                             Add Row
                         </Button>
                     </div>
                     <div className='popbtn' style={{width:"50%"}}>
                         <button className='btn2a' color='primary' type='button' onClick={handleSaveWh} >Save</button>{' '}
                         {/* <Button className='btn1ab' type="button" onClick={handleCancelWhTax}>Close</Button> */}
                     </div>
                 </div>
             </div>
         </div>
         
         :null  }   
           </Form>   
          <Col >
          <Card>
            <div style={{ display: "flex" }}>
              <div className='headercl'>
              <div className="h5cus">
                  <h5 style={{paddingTop:"25px",paddingLeft:"30px"}} className="h5title">WH_TaxAssign List </h5>
                </div>
              </div>
              <div className='selectdel' style={{ display: "flex", alignItems: "center", justifyContent: "flex-end", width: "40%" }}>
              <Button title='Delete' color="danger" className='btn1 mt-4' onClick={handleDelete} disabled={selectedIds.length === 0}><IoTrash className="vendortrash1" />Delete</Button>
              </div>
            </div>
            <CardBody>
              <div className="table-responsive">
                <InputGroup className="mb-3">
                  <Input
                      type="text"
                      placeholder="Search"
                      style={{color:"black",borderColor: "#afafaf",fontSize:"12px" }}
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                      className="form-control input-no-animation inputfoc forminput"
                  />
                  <InputGroupText >
                      <FaSearch />
                  </InputGroupText>
                </InputGroup> 
                <DataTable
                  columns={columns}
                  data={paginatedData}
                  selectableRows
                  selectableRowsSelected={selectedIds}
                  onSelectedRowsChange={handleCheckboxChange}
                  clearSelectedRows={clearRows}
                  pagination
                  paginationServer
                  // reorderable={true}
                  customStyles={customStylesTable}
                  striped={false}
                  paginationTotalRows={filteredCategories.length}
                  paginationPerPage={rowsPerPage}
                  progressPending={loading}
                  autoWidth={true}
                  paginationComponentOptions={{
                    noRowsPerPage: true,
                  }}
                  onChangePage={handlePageChange}
                  highlightOnHover
                  // defaultSortFieldId={defaultSortField}
                  // defaultSortAsc={defaultSortAsc}
                />             
              </div>
              
            </CardBody>
          </Card>
        </Col>    
                  
     </Container>
    
    </Fragment>
  )
}

export default Withhold_Tax_Assign
