import React, { Fragment, useEffect, useState, useRef } from 'react';
import { Container, Row, Col, Card, CardBody, Form, FormGroup, Label, Input, Table, CardHeader, Button,InputGroup ,InputGroupText} from 'reactstrap';
import Select from 'react-select';
import { Breadcrumbs } from '../../../../AbstractElements';
import HeaderCard from '../../../Common/Component/HeaderCard';
import Swal from 'sweetalert2';
import { IoTrash } from "react-icons/io5";
import DataTable from 'react-data-table-component';
import '../forms.css';
import axiosInstance from '../../../../Auth/AxiosInstance';
import Highlighter from 'react-highlight-words';
import { FaRegEdit,FaSearch } from "react-icons/fa";
import classNames from "classnames";

function COALedger() {
  const [name, setName] = useState('');
  const [account, setAccount] = useState('');
  const [description, setDescription] = useState('');
  const [group, setGroup] = useState(null);
  const [coagroup, setCoagroup] = useState(null);
  const [categories, setCategories] = useState([]);
  const [clearRows, setClearRows] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editId, setEditId] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [selectOptions, setSelectOptions] = useState([{ value: '1', label: '[] Primary' }]);
  const [coagroupOptions, setCoagroupOptions] = useState([]);
  const [nameError1 , setNameError1] = useState(null);
  const [accountError1, setAccountError1] = useState(null);
  const [descriptionError1, setDescriptionError1] = useState(null);
  const [natureofgrp, setNatureofgrp] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [nameError2 , setNameError2] = useState(null);
  const [nameError3 , setNameError3] = useState(null);
  const [descriptionError2, setDescriptionError2] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [filteredCategories, setFilteredCategories] = useState([]);
  const [submitbtn, setSubmintbtn] =useState('Save');
  const [opt1 , setOpt1] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [shouldFocusName, setShouldFocusName] = useState(false);
  const [loading, setLoading] = useState(true);
  const cancelButtonRef = useRef(null);
  const submitButtonRef = useRef(null);

  const rowsPerPage = 10;
  const customStylesTable = {
    header: {
      style: {
        minHeight: '40px',
        backgroundColor: '#f0f0f0',
        color: '#333',
      },
    },
    headRow: {
      style: {
        backgroundColor: '#f0f0f0',
        minHeight: '40px'
      },
    },
    headCells: {
      style: {
        fontSize: '12px',
        fontWeight: 'bold',
        textAlign: 'left',
        color: '#000',
      },
    },
    rows: {
      style: {
        minHeight: '40px',
        borderBottom: '1px solid #ddd',
      },
    },
    cells: {
      style: {
        textAlign: 'left',
        fontSize:"12px"
      },
    },
  };
  
  const customStyles = {
    control: (provided, state) => ({
        ...provided,
        minHeight: '30px',
        borderColor: state.isFocused ? 'blue' : '#afafaf', // Change border color on focus
        boxShadow: state.isFocused ? '0 0 0 1px blue' : 'none', // Optional: Add shadow on focus
        '&:hover': {
            borderColor: state.isFocused ? 'blue' : 'black', // Keep the same color on hover
        },
    }),
    placeholder: (provided) => ({
        ...provided,
        fontSize: '12px', // Change the font size for the placeholder
        color: '#aaa', // Optional: Change placeholder color
      }),
    valueContainer: (provided) => ({
        ...provided,
        height: '30px', // Adjust the container height
        padding: '0 6px',
      }),
      input: (provided) => ({
        ...provided,
        margin: '0px', // Remove default margin for the input inside the select
      }),
      indicatorsContainer: (provided) => ({
        ...provided,
        height: '30px', // Adjust the indicator container height
      }),
};
  const paginatedData = Array.isArray(categories) ? categories.slice((currentPage - 1) * rowsPerPage, currentPage * rowsPerPage) : [];
  const columns = [
    {
      name: (<div className='dataTableDiv'>SN</div>),
      selector: (row, index) => index + 1 + (currentPage - 1) * rowsPerPage,
      // width:'10%',
      width:"70px",
      cell:(row, index) =>(
        <div className='dataTableDiv' style={{textAlign: 'left !important',display:"flex", justifyContent:"flex-start !important" ,paddingLeft:"4px"}}>{index + 1 + (currentPage - 1) * rowsPerPage}</div>
      )
    },
    {
      name: (<div className='dataTableDiv'>Ledger Account</div>),
      // width:"20%",
      selector: row => String(row.account),
      cell: row => (
        <div style={{textAlign: 'left !important',display:"flex", justifyContent:"flex-start !important" ,paddingLeft:"4px"}}>
          <Highlighter
            highlightClassName="highlighted-text"
            className='dataTableDiv'
            searchWords={[searchTerm]}
            autoEscape={true}
            textToHighlight={String(row.account)}
        />
        </div>
      ),
    },
    {
      name: (<div className='dataTableDiv'>Ledger Name</div>),
      // width:"20%",
      selector: row => row.name,
      cell: row => (
        <div style={{textAlign: 'left !important',display:"flex", justifyContent:"flex-start !important" ,paddingLeft:"4px"}}>
          <Highlighter
            highlightClassName="highlighted-text"
            className='dataTableDiv'
            searchWords={[searchTerm]}
            autoEscape={true}
            textToHighlight={row.name}
        />
        </div>
      ),
    },
    {
      name:'',
      width:'5px',
    },
    {
      name: (<div className='dataTableDiv'>Ledger Group</div>),
      // width:"20%",
      selector: row => row.group_name,
      cell: row => (
        <div style={{textAlign: 'left !important',display:"flex", justifyContent:"flex-start !important" ,paddingLeft:"12px"}}>
          <Highlighter
            highlightClassName="highlighted-text"
            className='dataTableDiv'
            searchWords={[searchTerm]}
            autoEscape={true}
            textToHighlight={row.group_name}
        />
        </div>
      ),
    },
    // {
    //   name: (<div className='dataTableDiv'>Nature of COA Group</div>),
    //   // width:"23%",
    //   // width:"300px",
    //   selector: row => row.coagroup_name,
    //   cell: row => (
    //     <div style={{textAlign: 'left !important',display:"flex", justifyContent:"flex-start !important" ,paddingLeft:"12px"}}>
    //       <Highlighter
    //         highlightClassName="highlighted-text"
    //         className='dataTableDiv'
    //         searchWords={[searchTerm]}
    //         autoEscape={true}
    //         textToHighlight={row.coagroup_name}
    //     />
    //     </div>
    //   ),
      
    // },
    {
      name: (<div className='dataTableDiv'>Action</div>),
      center: true,
      // width:'15%',
      cell: row => (
        <div className="buttonven">
          <button
            className="vendorupdate"
            color='primary'
            title='Edit'
            onClick={() => handleUpdateClick(row)}
          >
            <FaRegEdit className="vendortrash"/>
          </button>
          <button title='Delete' className="vendordele" onClick={() => handleDeleteCategory(row.id)}>
            <IoTrash className="vendortrash" />
          </button>
        </div>
      ),
    },
  ];


  useEffect(() => {
    if (searchTerm.trim() === '') {
      setFilteredCategories(categories || []);
    } else {
      const safeCategories = Array.isArray(categories) ? categories : [];
      const filteredData = safeCategories.filter(item =>
        item.name && item.name.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredCategories(filteredData);
    }
  }, [searchTerm, currentPage, categories]);

  useEffect(() => {
    fetchCategories(currentPage);
    fetchSelectOptions();
    fetchCoagroupOptions();
  }, [currentPage,searchTerm]); 

  const fetchCategories = async (page) => {
    setLoading(true);
    try {
      let response;
      if (searchTerm) {
          response = await axiosInstance.get(`generalledger/coaledger/search/?name=${searchTerm}`);
      } else {
          response = await axiosInstance.get(`generalledger/coaledger/get/`);
      }
      setCategories(response.data);
      setFilteredCategories(response.data); 
    } catch (error) {
      if (error.response && error.response.status === 404 && page > 1) {
        handlePageChange(page - 1);
      } else {
        console.error("Error fetching categories:", error);
      }
    }finally {
      setLoading(false);
    }
  };
  const handlePageChange = (page) => {
    setCurrentPage(page);
    fetchCategories(page);
  };
  const fetchSelectOptions = async (editId = null) => {
    try {
      const response = await axiosInstance.get('generalledger/ledgergroup/get/');
      console.log(editId,'snckdsvv');
      const opt = response.data.map(category => ({
        value: category.id,
        label: category.name,
        description: category.coagroup_name
      }));
      setOpt1(opt);
      console.log(opt,'options');
      const filteredOptions = response.data
      .filter(category => category.id !== editId)
      .map(category => ({
        value: category.id,
        label: category.name,
        natureofcoagrp: category.coagroup_name
      }));
      setSelectOptions([...filteredOptions]);
    } catch (error) {
      console.error('Error fetching select options:', error);
    }
  };
  const fetchCoagroupOptions = async (editId = null) => {
    try {
      const response = await axiosInstance.get('generalledger/ledgergroup/get/');
      const filteredOptions = response.data
      .filter(category => category.id !== editId)
      .map(category => ({
        value: category.id,
        label: category.coagroup_name,
        description: category.description
      }));
      setCoagroupOptions([ ...filteredOptions]);
    } catch (error) {
      console.error('Error fetching select options:', error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const payload = {
      name,
      account,
      description,
      group: group ? group.value : null,
    };
    setNameError1(null);
    setDescriptionError1(null);
    try {
      
      if (isEditing) {
        await axiosInstance.put(`generalledger/coaledger/${editId}/`, payload);
        setIsEditing(false);
      } else {
        await axiosInstance.post('generalledger/coaledger/create/', payload);
        setErrorMessage(null);
      }
      fetchCategories(currentPage);
      fetchSelectOptions();
      fetchCoagroupOptions();
      resetForm();
      // Swal.fire({
      //   title: 'Success!',
      //   text: 'COA_Ledger has been saved successfully.',
      //   icon: 'success',
      //   confirmButtonText: 'OK'
      // });
    } catch (error) {
      console.error('Error:', error);
      if (error.response && error.response.data) {
        const nameError = error.response.data.name && Array.isArray(error.response.data.name) ? error.response.data.name[0] : null;
        const descriptionError = error.response.data.account && Array.isArray(error.response.data.account) ? error.response.data.account[0] : null;
        const existsError = error.response.data.error && Array.isArray(error.response.data.error) ? error.response.data.error[0] : null;
        console.log(nameError, 'Name Error');
        console.log(descriptionError, 'Description Error');
        if (nameError && descriptionError) {
        } else if (nameError) {
          setNameError1(nameError)
        } else if (descriptionError) {
            setAccountError1(descriptionError);
        } else if (existsError) {
          setErrorMessage(existsError);
 
        }
      } else {
        setErrorMessage('An error occurred while submitting the form.');
      }
    }
  };

  const handleUpdateClick = (category) => {
    setNameError1(null);
    setNameError3(null);
    setAccount(category.account);
    setEditId(category.id);
    setNameError2(null);
    setDescriptionError2(null);
    setName('');
    setDescription('');
    setIsEditing(false);
    setCoagroup(null);
    setNatureofgrp('');
    setNameError1(null);
    setClearRows(!clearRows);
    setSubmintbtn('Save');
    setDescriptionError1(null);
    setErrorMessage('');
    setNameError1(null);
    setGroup(null);
    setDescriptionError1(null);
    setName(category.name);
    setDescription(category.description);
    setSubmintbtn('Update');
    console.log(category.coagroup_name ,'group nature');
    setGroup({ value: category.group, label: category.group_name });
    setCoagroup({ value: category.coagroup, label: category.coagroup_name});
    setNatureofgrp(category.coagroup_name);
    setIsEditing(true);
    fetchSelectOptions(category.id);
    fetchCoagroupOptions(category.id);
    setErrorMessage('');
  };

  const resetForm = () => {
    setNatureofgrp('');
    setNameError2(null);
    setNameError3(null);
    setDescriptionError2(null);
    setName('');
    setAccount('');
    setCoagroup(null);
    setDescription('');
    setIsEditing(false);
    setGroup(null);
    setNameError1(null);
    setAccountError1(null);
    setClearRows(!clearRows);
    setSubmintbtn('Save')
    setDescriptionError1(null);
    setEditId(null);
    setErrorMessage('');
  };

  const handleCheckboxChange = (selectedRows) => {
    setSelectedIds(selectedRows.selectedRows.map(row => row.id));
  };
  const handleDelete = async () => {
    try {
        await axiosInstance.delete('generalledger/coaledger/delete/', {
          data: { ids: selectedIds },
        });
        fetchCategories(currentPage);
        setSelectedIds([]);
        fetchSelectOptions();
        fetchCoagroupOptions();
        resetForm();
    } catch (error) {
      console.error('Error:', error.response.data);
      const errorMessage = JSON.stringify(error.response.data.detail, null, 2);
      Swal.fire('Error!',errorMessage , 'error');
    }
  };

  const handleDeleteCategory = async (id) => {
    // const result = await Swal.fire({
    //   title: 'Are you sure?',
    //   text: `You won't be able to revert this!`,
    //   icon: 'warning',
    //   showCancelButton: true,
    //   confirmButtonColor: '#3085d6',
    //   cancelButtonColor: '#d33',
    //   confirmButtonText: 'Yes, delete it!'
    // });

    // if (result.isConfirmed)
     {
      try {
        await axiosInstance.delete(`generalledger/coaledger/${id}/`);
        fetchCategories(currentPage);
      } catch (error) {
        console.error('Error deleting category:', error.response.data);
        const errorMessage = JSON.stringify(error.response.data.detail, null, 2);
        Swal.fire('Error!', errorMessage, 'error');
      }

      setSelectedIds([]);
      fetchCoagroupOptions();
      fetchSelectOptions();
      resetForm();
    }
  };

  const getHighlightedText = (text, highlight) => {
    if (!text || typeof text !== 'string') {
        return <span>{text}</span>;
    }
    if (!highlight || typeof highlight !== 'string') {
        return <span>{text}</span>; 
    }
    const escapedHighlight = highlight.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&');
    const parts = text.split(new RegExp(`(${escapedHighlight})`, 'gi'));
    return (
        <span>
            {parts.map((part, index) =>
                part.toLowerCase() === highlight.toLowerCase() ? (
                    <span key={index} style={{ fontWeight: 'bold' }}>{part}</span>
                ) : (
                    part
                )
            )}
        </span>
    );
  };

  const validateName = (value) => {
    console.log(value,'kjhvckjsdnv');
    
   if (value.match('  ')) {
      setNameError1('Continuous spaces are not allowed ');
      return false;
    }  else if(value.length === 30){
      setNameError2(null);
    }else {
      setNameError1(null);
      return true;
    }
  };
   const validateAccount = (value) => {
    if(value.length<6){
        setAccountError1('Account number must be a 6-digit number.');
    }else{
        setAccountError1(null);
    }
   }
  const handleNameChange = (e) => {
      let value = e.target.value;
      value = value.replace(/\s+/g,' ').trimStart();
      setName(value);
      validateName(value); 
  };
  const handleAccountChange = (e) => {
    let value = e.target.value;
    value = value.replace(/\s+/g,' ').trimStart();
    if (/^\d*$/.test(value)) {
      setAccount(value);
    }
    // validateAccount(value); 
};
const handleEnter = (event) => {
  if (event.target && nameError1 && (event.key === 11 || event.key === 13)) {
    event.target.focus();  
  } 
  else if (event.keyCode === 13 && event.target.form) {
    if (event.target.tagName === 'INPUT' || event.target.tagName === 'TEXTAREA') {
      event.target.value = event.target.value.trim();
    }
    if (dropdownOpen) {
      return; 
    } else {
      const form = event.target.form;
      const index = Array.prototype.indexOf.call(form.elements, event.target);
      const nextElement = form.elements[index + 1];
      if (nextElement) {
        nextElement.focus();
      }
      event.preventDefault();
    }
  }
  const { value } = event.target;
  if (
    (event.key === ' ' && value.length === 0) || 
    (event.key === ' ' && value.endsWith(' ')) || 
    (event.key === ' ' && value.includes('  '))
  ) {
    event.preventDefault();
    return;
  }
};

const handleArrowNavigation = (event, buttonType) => {
  if (event.key === 'ArrowRight' && buttonType === 'save' && cancelButtonRef.current) {
    cancelButtonRef.current.focus();
  } else if (event.key === 'ArrowLeft' && buttonType === 'cancel' && submitButtonRef.current) {
    submitButtonRef.current.focus();
  }
};
  const handleBlur = (e) => {
    setNameError2(null);
    setDescriptionError2(null);
    if (e.target.tagName === 'INPUT' || e.target.tagName === 'TEXTAREA') {
      e.target.value = e.target.value.trim();  // Ensures the value is trimmed properly
      
      if (nameError1) {
          e.target.focus();
      }else if (descriptionError1) {
        e.target.focus(); 
      }
    }
  };
  const handleDescriptionFocus = () => {
    // Perform name validation when switching to the description field
    if(isEditing){
      const otherCategories = categories.filter(category => category.id !== editId);
      console.log(otherCategories,'is editing');
      if (Array.isArray(otherCategories) && otherCategories.some((item) => item.account.toString() === account)) {
        setShouldFocusName(true);
        setAccountError1('Ledger account with this account already exists.');
        setTimeout(() => {
          const accountInput = document.getElementById('accountInput');
          if (accountInput) {
            accountInput.focus(); // Set focus on the input with id 'nameInput'
          }
      }, 0);
      }else if (Array.isArray(otherCategories) && otherCategories.some((item) => item.name.replace(/\s+/g,'').toLowerCase() === name.replace(/\s+/g,'').toLowerCase())) {
        setShouldFocusName(true);
        setNameError3('COA_Ledger with this name already exists.');
        setTimeout(() => {
          const nameInput = document.getElementById('nameInput');
          if (nameInput) {
            nameInput.focus(); // Set focus on the input with id 'nameInput'
          }
      }, 0);
      }
      else {
        setNameError3(null);
        setAccountError1(null);
      }
    }else{
      if(Array.isArray(categories) && categories.some((item) => item.account.toString() === account)){
        setShouldFocusName(true);
        setAccountError1('Ledger account with this account already exists.');
        setTimeout(() => {
          const accountInput = document.getElementById('accountInput');
          if (accountInput) {
            accountInput.focus(); // Set focus on the input with id 'nameInput'
          }
        }, 0);
        return false;
      }else if(Array.isArray(categories) && categories.some((item) => item.name.replace(/\s+/g,'').toLowerCase() === name.replace(/\s+/g,'').trim().toLowerCase())){
        setShouldFocusName(true);
        setNameError3('COA_Ledger with this name already exists.');
        setTimeout(() => {
          const nameInput = document.getElementById('nameInput');
          if (nameInput) {
            nameInput.focus(); // Set focus on the input with id 'nameInput'
          }
        }, 0);
        return false;
      }else if((account.length<6)&& (account !== '')){
        setShouldFocusName(true);
        setAccountError1('Account number must be a 6-digit number.');
        setTimeout(() => {
            const nameInput = document.getElementById('nameInput');
            if (nameInput) {
              nameInput.focus(); // Set focus on the input with id 'nameInput'
            }
          }, 0);
          return false;
        
    }else {
        setNameError3(null);
        setAccountError1(null);
      }

    }
  };
  const saveGroup = (option) => {
    setGroup(option);
    console.log(option.natureofcoagrp,"Seletced option")
    setNatureofgrp(option.natureofcoagrp);
    // Add any saving logic here, e.g., an API call or local storage
    console.log('Saved group:', option);
};
  return (
    <Fragment>
      <Breadcrumbs mainTitle="General Ledger" parent="General Ledger" title="COA_Ledger" />
      <Container fluid={true} className='Container1'>
        <div className='vendordiv1'>
        <Col sm="12" xl="6" className='vendorcol1'>
          <Card>
            <div style={{ display: "flex" }}>
              <div className='headercl'>
              <div className="h5cus">
                  <h5 style={{paddingTop:"25px",paddingLeft:"30px"}} className="h5title">COA_Ledger List </h5>
                </div>
              </div>
              <div className='selectdel' style={{ display: "flex", alignItems: "center", justifyContent: "flex-end", width: "40%" }}>
              <Button title='Delete' color="danger" className='btn1 mt-4' onClick={handleDelete} disabled={selectedIds.length === 0}><IoTrash className="vendortrash1" />Delete</Button>
              </div>
            </div>
            <CardBody>
              <div className="table-responsive">
                <InputGroup className="mb-3">
                  <Input
                      type="text"
                      placeholder="Search"
                      style={{color:"black",borderColor: "#afafaf",fontSize:"12px" }}
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                      className="form-control input-no-animation inputfoc forminput"
                  />
                  <InputGroupText >
                      <FaSearch />
                  </InputGroupText>
                </InputGroup>
                <DataTable
                  columns={columns}
                  data={paginatedData}
                  selectableRows
                  selectableRowsSelected={selectedIds}
                  onSelectedRowsChange={handleCheckboxChange}
                  clearSelectedRows={clearRows}
                  pagination
                  paginationServer
                  reorderable={true}
                  customStyles={customStylesTable}
                  striped={false}
                  paginationTotalRows={categories.length}
                  paginationPerPage={rowsPerPage}
                  progressPending={loading}
                  autoWidth={true}
                  paginationComponentOptions={{
                    noRowsPerPage: true,
                  }}
                  onChangePage={handlePageChange}
                  highlightOnHover
                />
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col sm="12" xl="6" className='vendorcol2'>
                <Card>
                <div className="h5cus">
                  <h5 style={{paddingTop:"25px",paddingLeft:"30px"}} className="h5title">COA_Ledger </h5>
                </div>
                <CardBody style={{paddingTop:"15px"}}>
                <Form className="theme-form" id='form' onSubmit={handleSubmit}>
                    <FormGroup>
                        <Label style={{fontWeight:"600"}} className="col-form-label pt-0" >Ledger account</Label>
                        <Input autoComplete="off"  style={{color:"black",borderColor: "#afafaf"  }} onBlur={handleBlur} maxLength="6" id="accountInput" className={classNames("form-control", "inputfoc","forminput", {"is-invalid": nameError1,})} type="text" onKeyDown={handleEnter}  value={account} onChange={handleAccountChange} required/>
                    </FormGroup>
                    {accountError1 && (
                        <div className="alert alert-danger" style={{marginTop:"-10px"}}>
                            {accountError1}
                        </div>
                    )}
                    <FormGroup className='custom-margin'>
                        <Label style={{fontWeight:"600"}} className="col-form-label pt-0" >Ledger name</Label>
                        <Input onFocus={handleDescriptionFocus} autoComplete="off"  style={{color:"black",borderColor: "#afafaf" }} onBlur={handleBlur} maxLength="50" id="nameInput" className={classNames("form-control", "inputfoc","forminput", {"is-invalid": nameError1,})} type="text" onKeyDown={handleEnter}  value={name} onChange={handleNameChange} required/>
                    </FormGroup>
                    {nameError1 && (
                        <div className="alert alert-danger" style={{marginTop:"-10px"}}>
                            {nameError1}
                        </div>
                    )}
                    {nameError2 && (
                        <div className="alert alert-danger">
                            {nameError2}
                        </div>
                    )}
                    {nameError3 && (
                        <div className="alert alert-danger">
                            {nameError3}
                        </div>
                    )}
                    <div className="mb-2 itsubmt">
                        <Label style={{fontWeight:"600"}} className="col-form-label">Ledger Group</Label>
                        <Select
                            value={group}
                            onFocus={handleDescriptionFocus}
                            onMenuOpen={() => {
                              setDropdownOpen(true);
                            }}
                            onMenuClose={() => {
                              setDropdownOpen(false);
                            }}
                            styles={customStyles}
                            onChange={saveGroup}
                            onKeyDown={handleEnter}
                            onBlur={handleBlur}
                            options={selectOptions}
                            filterOption={(option, inputValue) => 
                              option.label.toLowerCase().startsWith(inputValue.toLowerCase())
                            }
                            formatOptionLabel={(option, { inputValue }) => 
                              getHighlightedText(option.label, inputValue)
                            }
                            required
                        />
                    </div>
                   {/* <div className="mb-2"> */}
                   <FormGroup className='custom-margin'>
                        <Label style={{fontWeight:"600"}} className="col-form-label pt-0" >Nature of COA Group</Label>
                        <Input
                         style={{ color: "black", borderColor: "#afafaf" }}
                         onChange={saveGroup}
                         onKeyDown={handleEnter}
                         onBlur={handleBlur}
                         className={classNames("form-control", "inputfoc", "forminput", { "is-invalid": nameError1 })}
                         type="text"
                         value={natureofgrp}
                         readOnly
                     />
                    </FormGroup>
                    {/* </div> */}
                    {errorMessage && (
                        <div className="alert alert-danger">
                            {errorMessage}
                        </div>
                    )}
                    <div className="mt-3" style={{gap:"10px",display:"flex", justifyContent:"flex-end"}}>
                        <button className='btn2' color='primary' ref={submitButtonRef} onKeyDown={(event) => handleArrowNavigation(event, 'save')} type='submit' disabled={nameError1 || nameError2 || nameError3 || descriptionError1 || descriptionError2 || ((name === '')||(account === '')||(group === null))}>
                            {submitbtn}
                        </button>
                        <button className='btn1' ref={cancelButtonRef} type='button' onKeyDown={(event) => handleArrowNavigation(event, 'cancel')} onClick={resetForm}>
                            Cancel
                        </button>
                        
                    </div>
                </Form>
                </CardBody>
                </Card>
            </Col>
        </div>
      </Container>
    </Fragment>
  );
}

export default COALedger;
