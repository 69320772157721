import React, { Fragment, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import SvgIcon from '../../Components/Common/Component/SvgIcon';
import CustomizerContext from '../../_helper/Customizer';

const MENUITEMS = [
  {
    menutitle: "sidebar",
    Items: [
      { title: "Procurement", icon: "sample-page", type: "link", path: "/sitemaster" },
      { title: "Inventory", icon: "sample-page", type: "link", path: "/inventorysite" },
      { title: "General Ledger", icon: "sample-page", type: "link", path: "/generalledgersite" },
      { title: "Taxation", icon: "sample-page", type: "link", path: "/taxationsite" },
    ],
  },
];

const SidebarMenuItems = ({ setMainMenu, sidebartoogle, setNavActive, activeClass }) => {
  const { layout } = useContext(CustomizerContext);
  const layout1 = localStorage.getItem('sidebar_layout') || layout;
  const CurrentPath = window.location.pathname;
  const { t } = useTranslation();

  const toggletNavActive = (item) => {
    // Toggle logic for activating sidebar items
    item.active = !item.active;
    setMainMenu({ mainmenu: MENUITEMS });
  };

  return (
    <>
      {MENUITEMS.map((Item, i) => (
        <Fragment key={i}>
          <li className="sidebar-main-title">
            <div>
              <h6>{t(Item.menutitle)}</h6>
            </div>
          </li>
          {Item.Items.map((menuItem, i) => (
            <li className="sidebar-list" key={i}>
              <Link
                to={menuItem.path}
                className={`sidebar-link sidebar-title link-nav ${CurrentPath.includes(menuItem.path) ? 'active' : ''}`}
                onClick={() => toggletNavActive(menuItem)}
              >
                <SvgIcon className="stroke-icon" iconId={`stroke-${menuItem.icon}`} />
                <SvgIcon className="fill-icon" iconId={`fill-${menuItem.icon}`} />
                <span>{t(menuItem.title)}</span>
              </Link>
            </li>
          ))}
        </Fragment>
      ))}
    </>
  );
};

export default SidebarMenuItems;
