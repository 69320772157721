import React, { useState } from 'react';
import { Container, Row, Col, TabContent, TabPane } from 'reactstrap';
import LoginTab from './Tabs/LoginTab';

const Logins = () => {
  const [selected, setSelected] = useState('simpleLogin');

  return (
    <Container fluid={true} className='p-0 login-page'>
      <Row>
        <Col xs='12' style={{height:"100%"}}>
          <div className='login-card'>
            <div style={{height:"450px" , display:"flex", alignItems:"center", justifyContent:"center",alignContent:"center"}}>
            <div className='login-main login-tab'>
              {/* <NavAuth callbackNav={callbackNav} selected={selected} /> */}
              <TabContent activeTab={selected} className='content-login'>
                <TabPane className='fade show' tabId={selected === 'simpleLogin' ? 'simpleLogin' : 'jwt'}>
                  <LoginTab selected={selected} />
                </TabPane>
              </TabContent>
            </div>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Logins;
