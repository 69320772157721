import React, {Fragment, useEffect, useState, useRef} from "react";
import { Container, Row, Col, Card, CardBody, Form, FormGroup, Label, Table, Input, Button,InputGroup ,InputGroupText,TabContent, TabPane, Nav, NavItem, NavLink  } from 'reactstrap';
import Select from 'react-select';
import { Breadcrumbs ,H2, H3, H4, H5,H6,P} from '../../../../AbstractElements';
import { IoArrowBackOutline, IoArrowForward  } from "react-icons/io5";
import Swal from 'sweetalert2';
import { IoTrash } from "react-icons/io5";
import DataTable from 'react-data-table-component';
import '../forms.css';
import axiosInstance from '../../../../Auth/AxiosInstance';
import Highlighter from 'react-highlight-words';
import { FaRegEdit,FaSearch } from "react-icons/fa";
import { IoMdClose } from "react-icons/io";
import { TabCardData } from '../../../Common/Data/Bonus-ui';
import { ComingSoon } from "../../../../Constant";

function Vendor(){
    const [vendorCode, setVendorCode] = useState('');
    const [address, setAddress] = useState('');
    const [activeTab, setActiveTab] = useState(1);
    const [city, setCity] = useState('');
    const [wrstate, setWrstate] = useState('');
    const [country, setCountry] = useState('');
    const [pin, setPin] = useState('');
    const [contactPerson, setContactPerson] = useState('');
    const [cp_telephone, setCp_telephone] = useState('');
    const [cp_mobile, setCp_mobile] = useState('');
    const [cp_email, setCp_email] = useState('');
    const [accountsPerson, setAccountPerson] = useState('');
    const [ap_telephone, setAp_telephone] = useState('');
    const [ap_mobile, setAp_mobile] = useState('');
    const [ap_email, setAp_email] = useState('');
    const [vendorLocation, setVendorLocation] = useState(null);
    const [gstin, setGstin] = useState('');
    const [transporterid, setTransporterid] = useState('');
    const [paymentTerms, setPaymentTerms] = useState('');
    const [paymentMode, setPaymentMode] = useState('');
    const [creditDays, setCreditDays] = useState('');
    const [paymentBase, setPaymentBase] = useState([]);
    const [accountName, setAccountName] = useState('');
    const [accountNumber, setAccountNumber] = useState('');
    const [ifsc, setIfsc] = useState('');
    const [bankName, setBankName] = useState('');
    const [pan, setPan] = useState('');
    const [rateType, setRateType] = useState('');
    const [vendorcategory, setVendorCategory] = useState(null);
    const [transporter, setTransporter] = useState([]);
    const [venCategoryopt, setVenCategoryopt] = useState([]);
    const [vendorName, setVendorName] = useState('');
    const [vendorGroup, setVendorGroup] = useState(null);
    const [venGroupopt, setVenGroupopt] = useState([]);
    const [registrationType, setRegistrationType] = useState(null);
    const [regTypeOpt, setRegTypeOpt] = useState([]);
    const [clearRows, setClearRows] = useState(false);
    const [assesTerritory, setAssesTerritory] = useState(null);
    const [assesterOpt, setAssesteropt] = useState([]);
    const [paymentFrom, setPaymentFrom] = useState([]);
    const [currency, setCurrency] = useState([]);
    const [currencyopt, setCurrencyopt] = useState(null);
    const [whcategory, setWhcategory] = useState([]);
    const [gstcategory, setGstcategory] = useState([]);
    const [whtaxtype, setWhtaxtype] = useState([]);
    const [gsttaxtype, setGsttaxtype] = useState([]);
    const [whtaxcode, setWhtaxcode] = useState([]);
    const [gsttaxcluster, setGsttaxcluster] = useState([]);
    const [calwhtax, setCalwhtax] = useState([]);
    const [natureasses, setNatureasses] = useState([]);
    const [natureopt, setNatureopt] = useState([]);
    const [selectedIds, setSelectedIds] = useState([]);
    const [dropdownCatOpen, setDropdownCatOpen] = useState(false);
    const [dropdownRegOpen, setDropdownRegOpen] = useState(false);
    const [dropdownAssesOpen, setDropdownassesOpen] = useState(false);
    const [dropdownTransOpen, setDropdownTransOpen] = useState(false);
    const [dropdownFromOpen, setDropdownFromOpen] = useState(false);
    const [dropdownCurrOpen, setDropdownCurrOpen] = useState(false);
    const [dropdownWhholdOpen, setDropdownWhholdOpen] = useState(false);
    const [dropdownNatuAssOpen, setDropdownNatuAssOpen] = useState(false);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [nameError1 , setNameError1] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [nameError2 , setNameError2] = useState(null);
    const [nameError3 , setNameError3] = useState(null);
    const [isEditing, setIsEditing] = useState(false);
    const [whtaxfocus, setWhtaxfocus] = useState(false);
    const [gsttaxfocus, setGsttaxfocus] = useState(false);
    const [shouldFocusName, setShouldFocusName] = useState(false);
    const [descriptionError1, setDescriptionError1] = useState(null);
    const [descriptionError2, setDescriptionError2] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);
    const [filteredVendor, setFilteredVendor] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [loading, setLoading] = useState(true);
    const [editId, setEditId] = useState(null);
    const [vendors, setVendors] = useState([]);
    const cancelButtonRef = useRef(null);
    const submitButtonRef = useRef(null);
    const vendorNameRef = useRef(null); // Ref for VendorName input
    const vendorCodeRef = useRef(null); // Ref for VendorCode input
    const rowsPerPage = 10;
    const paginatedData = Array.isArray(vendors) ? vendors.slice((currentPage - 1) * rowsPerPage, currentPage * rowsPerPage) : [];
    const [addedRows, setAddedRows] = useState([]);
    const [savedRows, setSavedRows] = useState([]);
    const [addedGstRows, setAddedGstRows] = useState([]);
    const [filteredTaxClusters, setFilteredTaxClusters] = useState([]);
    const [whrows, setWhrows] = useState([
        { 
            category: '',
            type: '',
            taxcode:'',
            from_date: '',
            to_date: '',
            saved: true
        }
    ]);
    const [gstrows, setGstrows] = useState([
        {
            category:'',
            type:'',
            taxcluster:'',
            from_date: '',
            to_date: '',
        }
    ])

    const addRow = () => {
        const newRow = { category: '', type: '', taxcode: '', description: '', from_date: '', to_date: '' };
        setWhrows((prevRows) => [...prevRows, newRow]);
        setAddedRows((prevAddedRows) => [...prevAddedRows, newRow]);
    };
    const addRowGst = () => {
        const newRow = { category: '', type: '', taxcode: '', from_date: '', to_date: '' };
        setGstrows((prevRows) => [...prevRows, newRow]);
        setAddedGstRows((prevAddedGstRows)=>[...prevAddedGstRows,newRow]);
    };
    
    const removeRow = (index) => {
        const newRows = [...whrows];
        newRows.splice(index, 1); // Remove the row at the specified index
        setWhrows(newRows);
        if(newRows.length === 0){
            setWhrows([
                { 
                    category: '',
                    type: '',
                    taxcode:'',
                    description:'',
                    from_date: '',
                    to_date: '',
                }
            ]);
        }
        
    };
    const removeRowGst = (index) => {
        const newRows = [...gstrows];
        newRows.splice(index, 1); // Remove the row at the specified index
        setGstrows(newRows);
        if(newRows.length === 0){
            setGstrows([
                {
                    category:'',
                    type:'',
                    taxcluster:'',
                    description:'',
                    from_date: '',
                    to_date: '',
                }
            ]);
        }
        
    };

    const handleCheckboxChange = (selectedRows) => {
        setSelectedIds(selectedRows.selectedRows.map(row => row.id));
    };
    const handleInputChange = (index, event) => {
        const { name, value } = event.target;
        const updatedRows = [...whrows];
        updatedRows[index][name] = value;
    
        if (name === "taxcode") {
            const selectedWhrow = whtaxcode.find(option => option.value === Number(value));
            updatedRows[index].description = selectedWhrow ? selectedWhrow.description : "";
        }
    
        setWhrows(updatedRows);
    };
    const handleGSTInputChange = (index, e) => {
        const { name, value } = e.target;
        const updatedRows = [...gstrows];
        updatedRows[index][name] = value;
        // Update the row value
        setGstrows(updatedRows);
        if (name === 'category' || name === 'type') {
            const selectedCategory = updatedRows[index].category;
            const selectedType = updatedRows[index].type;
        }
        if(name == "taxcluster"){
            const id = updatedRows[index].taxcluster;
            const taxcluster_id = +id;
            const selectedGstrow = filteredTaxClusters.find(option=>option.value === taxcluster_id);
            if(selectedGstrow){
                updatedRows[index].description = selectedGstrow.description;
            }
        }
    };
    const transporteropt = [
        { value: 1, label: 'Yes' },
        { value: 0, label: 'No' }
      ];
    const calwhtaxopt = [
        { value: 1, label: 'Yes' },
        { value: 0, label: 'No' }
    ]
    const customStylesTable = {
        header: {
            style: {
            minHeight: '40px', // Adjust header height
            backgroundColor: '#f0f0f0', // Light grey background for the header
            color: '#333', // Text color
            },
        },
        headRow: {
            style: {
            backgroundColor: '#f0f0f0',
            minHeight: '40px'
                // Grey color for the table header row
            },
        },
        headCells: {
            style: {
            fontSize: '12px', // Font size for header cells
            fontWeight: 'bold',
            textAlign: 'left',
            color: '#000', // Header text color
            },
        },
        rows: {
            style: {
            minHeight: '40px', // Minimum height of rows
            borderBottom: '1px solid #ddd', // Bottom border for rows
            },
        },
        cells: {
            style: {
            textAlign: 'left',
            fontSize:"12px"
            },
        },
    };
    const columns = [
        {
          name: (<div className='dataTableDiv'>SN</div>),
          selector: (row, index) => index + 1 + (currentPage - 1) * rowsPerPage,
          width:'70px',
          cell:(row, index) =>(
            <div className='dataTableDiv' style={{textAlign: 'left !important',display:"flex", justifyContent:"flex-start !important" ,paddingLeft:"4px"}}>{index + 1 + (currentPage - 1) * rowsPerPage}</div>
          )
        },
        {
          name: (<div className='dataTableDiv'>Vendor Code</div>),
          selector: row => row.vendor_code,
          // width: '300px',
          cell: row => (
            <div style={{textAlign: 'left !important',display:"flex", justifyContent:"flex-start !important" ,paddingLeft:"4px"}}>
              <Highlighter
                highlightClassName="highlighted-text"
                className='dataTableDiv'
                searchWords={[searchTerm]}
                autoEscape={true}
                textToHighlight={row.vendor_code}
            />
            </div>
          ),
        },
        {
          name:'',
          width:'5px',
        },
        {
          name: (
            <div className='dataTableDiv'>Vendor name</div>
          ),
          selector: row => row.vendor_name,
          cell: row => (
            <div style={{textAlign: 'left !important',display:"flex", justifyContent:"flex-start !important" ,paddingLeft:"12px"}}>
              <Highlighter
                highlightClassName="highlighted-text"
                className='dataTableDiv'
                searchWords={[searchTerm]}
                autoEscape={true}
                textToHighlight={row.vendor_name}
            />
            </div>
          ),
        },
        {
          name:(<div className='dataTableDiv'>Vendor location</div>),
          selector: row => row.vendor_location_name,
          cell: row => (
            <div style={{textAlign: 'left !important',display:"flex", justifyContent:"flex-start !important" ,paddingLeft:"12px"}}>
              <Highlighter
                highlightClassName="highlighted-text"
                className='dataTableDiv'
                searchWords={[searchTerm]}
                autoEscape={true}
                textToHighlight={row.vendor_location_name}
            />
            </div>
          ),
        },
        {
          name: (<div className='dataTableDiv'>City</div>),
          selector: row => row.city,
          cell: row => (
            <div style={{textAlign: 'left !important',display:"flex", justifyContent:"flex-start !important" ,paddingLeft:"12px"}}>
              <Highlighter
                highlightClassName="highlighted-text"
                className='dataTableDiv'
                searchWords={[searchTerm]}
                autoEscape={true}
                textToHighlight={row.city}
            />
            </div>
          ),
        },
        {
          name: (<div className='dataTableDiv'>State</div>),
          selector: row => row.state,
          cell: row => (
            <div style={{textAlign: 'left !important',display:"flex", justifyContent:"flex-start !important" ,paddingLeft:"12px"}}>
              <Highlighter
                highlightClassName="highlighted-text"
                className='dataTableDiv'
                searchWords={[searchTerm]}
                autoEscape={true}
                textToHighlight={row.state}
            />
            </div>
          ),
          
        },
        {
          name: (<div className='dataTableDiv'>Action</div>),
          center: true,
          width:'100px',
          cell: row => (
            <div className="buttonven">
              <button
                className="vendorupdate"
                color='primary'
                title='Edit'
                onClick={() => handleUpdateClick(row)}
              >
                <FaRegEdit className="vendortrash"/>
              </button>
              <button title='Delete' className="vendordele" onClick={() => handleDeleteCategory(row.id)}>
                <IoTrash className="vendortrash" />
              </button>
            </div>
          ),
        },
      ];
    
    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            minHeight: '30px',
            borderColor: state.isFocused ? 'blue' : '#afafaf', // Change border color on focus
            boxShadow: state.isFocused ? '0 0 0 1px blue' : 'none', // Optional: Add shadow on focus
            '&:hover': {
                borderColor: state.isFocused ? 'blue' : 'black', // Keep the same color on hover
            },
        }),
        menu: (provided) => ({
            ...provided,
            fontSize: '12px', // Adjust the font size of the dropdown menu options
            fontFamily:'Rubik, sans-serif',
          }),
        placeholder: (provided) => ({
            ...provided,
            fontSize: '12px', // Change the font size for the placeholder
            color: '#aaa', // Optional: Change placeholder color
          }),
        valueContainer: (provided) => ({
            ...provided,
            height: '30px', // Adjust the container height
            padding: '0 6px',
          }),
          input: (provided) => ({
            ...provided,
            margin: '0px', // Remove default margin for the input inside the select
          }),
          indicatorsContainer: (provided) => ({
            ...provided,
            height: '30px', // Adjust the indicator container height
          }),
    };
    const handlePageChange = (page) => {
        setCurrentPage(page);
        fetchVendors(page);
    };
    useEffect(() => {
        fetchVendCatOptions();
        fetchVendGroOptions();
        fetchRegTypeOptions();
        fetchAssterOptions();
        fetchCurrencyOptions();
        fetchWHCategoryOptions();
        fetchNatureassesOptions();
        fetchWHTypeOptions();
        fetchGSTCategoryOptions();
        fetchGSTTypeOptions();
        fetchGSTTaxClusterOption();
        fetchWHTaxCodeeOptions();
        fetchPaymentbaseOptions();
        fetchVendors();
      }, [searchTerm, currentPage]);

      const fetchVendors =async (page)=>{
        setLoading(true);
        try {
        let response;
        if (searchTerm) {
            response = await axiosInstance.get(`vendor/vendor/search/?name=${searchTerm}`);
        } else {
            response = await axiosInstance.get(`vendor/vendor/get/`);
        }
        setVendors(response.data);
        } catch (error) {
        if (error.response && error.response.status === 404 && page > 1) {
            handlePageChange(page - 1); 
        } else {
            console.error("Error fetching categories:", error);
        }
        }finally {
        setLoading(false);
        }
      };

    //   const checkEmptyFields = () => {
    //     // Check for empty fields in `whrows`
  
    //     // Alert the messages if there are any missing fields
    //     if (whEmptyFieldsMessages.length > 0) {
    //       alert(`Withhold Tax Data:\n${whEmptyFieldsMessages.join("\n")}`);
    //     }
    //     if (gstEmptyFieldsMessages.length > 0) {
    //       alert(`GST Tax Data:\n${gstEmptyFieldsMessages.join("\n")}`);
    //     }
        
    //     // Return true if no fields are empty
    //     return whEmptyFieldsMessages.length === 0 && gstEmptyFieldsMessages.length === 0;
    //   };
      

      const handleSubmit = async (e) => {
        e.preventDefault();
        const hasEmptyFields = whrows.some(row =>
            !row.category || !row.type || !row.taxcode || !row.from_date || !row.to_date
        );
        const whEmptyFieldsMessages = whrows
            .map((row, index) => {
            const missingFields = [];
            if (!row.category) missingFields.push("WHT category");
            if (!row.type) missingFields.push("WHT type");
            if (!row.taxcode) missingFields.push("WHT taxcode");
            if (!row.from_date) missingFields.push("from date");
            if (!row.to_date) missingFields.push("to date");
        
            if (missingFields.length > 0) {
                return `Row ${index + 1} missing fields: ${missingFields.join(", ")}`;
            }
            return null;
            })
            .filter(Boolean); // Remove null entries
    
      // Check for empty fields in `gstrows`
      const gstEmptyFieldsMessages = gstrows
        .map((row, index) => {
          const missingFields = [];
          if (!row.category) missingFields.push("GST category");
          if (!row.type) missingFields.push("GST type");
          if (!row.taxcluster) missingFields.push("GST tax cluster");
          if (!row.from_date) missingFields.push("from date");
          if (!row.to_date) missingFields.push("to date");
    
          if (missingFields.length > 0) {
            return `Row ${index + 1} missing fields: ${missingFields.join(", ")}`;
          }
          return null;
        })
        .filter(Boolean); // Remove null entries
    
        const hasEmptyFieldsGst = gstrows.some(row =>
            !row.category || !row.type || !row.taxcluster || !row.from_date || !row.to_date
        );
        if(vendorLocation === null || vendorGroup === null || currency.length === 0 || hasEmptyFields || hasEmptyFieldsGst){
            if(vendorLocation === null){
                alert("Please Choose the Vendor location on Grouping.");
            }
            if(vendorGroup === null){
                alert("Please select the Vendor Group on Grouping.");
            }
            if(vendorcategory === null){
                alert("Please select the Vendor Category on Grouping.")
            }
            if(currency.length === 0){
                alert("Please select the Currency on Payment terms.")
            }
            if (whEmptyFieldsMessages.length > 0) {
                alert(`Withhold Tax Data:\n${whEmptyFieldsMessages.join("\n")}`);
            }
            if (gstEmptyFieldsMessages.length > 0) {
                alert(`GST Tax Data:\n${gstEmptyFieldsMessages.join("\n")}`);
            }
            
        }else {
            const payload = {
                vendor_code: vendorCode,
                vendor_name: vendorName,
                address: address,
                city: city,
                state: wrstate,
                country: country,
                pincode: pin,
                contact_person: contactPerson,
                cp_telephone: cp_telephone,
                cp_mobile: cp_mobile,
                cp_email: cp_email,
                accounts_person: accountsPerson,
                ap_telephone: ap_telephone,
                gstin: gstin,
                ap_mobile: ap_mobile,
                ap_email: ap_email,
                vendor_location: vendorLocation,
                vendor_group: vendorGroup? vendorGroup.value : null,
                vendor_category: vendorcategory? vendorcategory.value : null,
                registration_type: registrationType? registrationType.value :null,
                transport_agency: transporter? transporter.value : null,
                transport_id: transporterid,
                assessee_territory: assesTerritory? assesTerritory.value : null,
                payment_terms: paymentTerms,
                payment_mode: paymentMode,
                credit_days: creditDays,
                payment_base: paymentFrom? paymentFrom.value : null,
                currency: currency? currency.value : null,
                account_name: accountName,
                account_no: accountNumber,
                ifsc: ifsc,
                bank_name: bankName,
                pan: pan,
                assessee_nature: natureasses? natureasses.value : null,
                withhold_tax: calwhtax.value,
                wht_grid_view: whrows,
                gst_grid_view: gstrows
            };
            setNameError1(null);
            setDescriptionError1(null);
            try {
              if (isEditing) {
                await axiosInstance.put(`vendor/vendor/${editId}/`, payload);
                setIsEditing(false);
              } else {
                await axiosInstance.post('vendor/vendor/create/', payload);
                setErrorMessage(null);
              }
            } catch (error) {
              console.error('Error:', error);
              if (error.response && error.response.data) {
                const nameError = error.response.data.name && Array.isArray(error.response.data.name) ? error.response.data.name[0] : null;
                const descriptionError = error.response.data.description && Array.isArray(error.response.data.description) ? error.response.data.description[0] : null;
                const existsError = error.response.data.error && Array.isArray(error.response.data.error) ? error.response.data.error[0] : null;
                console.log(nameError, 'Name Error');
                console.log(descriptionError, 'Description Error');
                if (nameError && descriptionError) {
                  setNameError1(nameError);
                  setDescriptionError1(descriptionError);
                } else if (nameError) {
                  setNameError1(nameError)
                } else if (descriptionError) {
                  setDescriptionError1(descriptionError);
                } else if (existsError) {
                  setErrorMessage(existsError);
         
                }
              } else {
                setErrorMessage('An error occurred while submitting the form.');
              }
            }
            fetchAssterOptions();
            fetchVendCatOptions();
            fetchVendGroOptions();
            fetchRegTypeOptions();
            fetchCurrencyOptions();
            fetchWHCategoryOptions();
            fetchNatureassesOptions();
            fetchWHTypeOptions();
            fetchGSTCategoryOptions();
            fetchGSTTypeOptions();
            fetchGSTTaxClusterOption();
            fetchWHTaxCodeeOptions();
            fetchPaymentbaseOptions();
            fetchVendors();
            // fetchCategoryOptions();
            resetform();
            setActiveTab(1);
        }
        
      };
    
    const handleUpdateClick = (vendor) =>{
        resetform();
        // fetchGSTTaxClusterOption1();
        setIsEditing(true);
        setEditId(vendor.id);
        setVendorCode(vendor.vendor_code);
        setVendorName(vendor.vendor_name);
        setAddress(vendor.address);
        setCity(vendor.city);
        setWrstate(vendor.state);
        setCountry(vendor.country);
        setPin(vendor.pin);
        setAp_email(vendor.ap_email);
        setAp_mobile(vendor.ap_mobile);
        setAp_telephone(vendor.ap_telephone);
        setBankName(vendor.bank_name);
        setContactPerson(vendor.contact_person);
        setCp_email(vendor.cp_email);
        setCp_mobile(vendor.cp_mobile);
        setCp_telephone(vendor.cp_telephone);
        setCreditDays(vendor.credit_days);
        setGstin(vendor.gstin);
        setAccountPerson(vendor.accounts_person);
        setIfsc(vendor.ifsc);
        setPan(vendor.pan);
        setPin(vendor.pincode);
        setTransporterid(vendor.transport_id);
        setPaymentMode(vendor.payment_mode);
        setAccountName(vendor.account_name);
        setAccountNumber(vendor.account_no);
        setPaymentTerms(vendor.payment_terms);
        setVendorLocation(vendor.vendor_location);
        setAssesTerritory({value: vendor.assessee_territory, label: vendor.assessee_territory_name});
        setVendorGroup({value: vendor.vendor_group, label: vendor.vendor_group_name});
        setVendorCategory({value: vendor.vendor_category, label: vendor.vendor_category_name});
        setRegistrationType({value: vendor.registration_type, label: vendor.registration_type_name});
        setPaymentFrom({value: vendor.payment_base, label: vendor.payment_base_name});
        setCurrency({value: vendor.currency, label: vendor.currency_name});
        setNatureasses({value: vendor.assessee_nature, label: vendor.assessee_nature_name});
        if (vendor.transport_agency === 1) {
            setTransporter({value: vendor.transport_agency, label: "Yes"});
          } else if (vendor.transport_agency === 0) {
            setTransporter({value: vendor.transport_agency, label: "No"});
          }
        if (vendor.vendor_location === 1) {
            setRateType('domesticVendor');
          } else if (vendor.vendor_location === 0) {
            setRateType('foreignVendor');
          }
        setCalwhtax({value: vendor.withhold_tax, label: vendor.withhold_tax_name});
        setWhrows(
            vendor.wht_grid_view.map((wht) => {
                const taxcodeOption = whtaxcode.find(option => option.value === wht.taxcode);
                return {
                    category: wht.category,
                    type: wht.type,
                    taxcode: wht.taxcode,
                    description: taxcodeOption ? taxcodeOption.description : '', // Set description here
                    from_date: wht.from_date,
                    to_date: wht.to_date,                
                };
            })
        );
        // vendor.gst_grid_view.map((gst) => ({
        //     fetchGSTTaxClusterOption(gst.category,gst.type);
        // }))
        // Set gst_grid_view data to gstrows
        setGstrows(
            vendor.gst_grid_view.map((gst) => ({
                category: gst.category,
                type: gst.type,
                taxcluster: gst.taxcluster, // Ensure this key exists in gst object
                description: gst.taxcluster_description || '', // Set default to empty string if undefined
                from_date: gst.from_date,
                to_date: gst.to_date,
                saved: true
            }))
        );
        console.log('gst_grid_view:', vendor.gst_grid_view);

        
    };
    const fetchVendCatOptions = async (editId = null) => {
        try {
          const response = await axiosInstance.get('vendor/categories/get/');
          console.log(editId,'snckdsvv');
          const filteredOptions = response.data
          .filter(category => category.id !== editId) // Exclude the category being edited
          .map(category => ({
            value: category.id,
            label: category.name
          }));
          setVenCategoryopt([ ...filteredOptions]);
        } catch (error) {
          console.error('Error fetching select options:', error);
        }
    }; 
    const fetchVendGroOptions = async (editId = null) => {
        try {
          const response = await axiosInstance.get('vendor/group/get/');
          console.log(editId,'snckdsvv');
          const filteredOptions = response.data
          .filter(category => category.id !== editId) // Exclude the category being edited
          .map(category => ({
            value: category.id,
            label: category.name
          }));
          setVenGroupopt([ ...filteredOptions]);
        } catch (error) {
          console.error('Error fetching select options:', error);
        }
    }; 
    const fetchRegTypeOptions = async (editId = null) => {
        try {
          const response = await axiosInstance.get('taxation/registrationtype/get/');
          console.log(editId,'snckdsvv');
          const filteredOptions = response.data
          .filter(category => category.id !== editId) // Exclude the category being edited
          .map(category => ({
            value: category.id,
            label: category.name
          }));
          setRegTypeOpt([ ...filteredOptions]);
        } catch (error) {
          console.error('Error fetching select options:', error);
        }
    }; 
    const fetchPaymentbaseOptions = async (editId = null) => {
        try {
          const response = await axiosInstance.get('vendor/paymentbase/get/');
          console.log(editId,'snckdsvv');
          const filteredOptions = response.data
          .filter(category => category.id !== editId) // Exclude the category being edited
          .map(category => ({
            value: category.id,
            label: category.name
          }));
          setPaymentBase([ ...filteredOptions]);
        } catch (error) {
          console.error('Error fetching select options:', error);
        }
    };
    const fetchAssterOptions = async (editId = null) => {
        try {
          const response = await axiosInstance.get('taxation/assesseeterritory/get/');
          console.log(editId,'snckdsvv');
          const filteredOptions = response.data
          .filter(category => category.id !== editId) // Exclude the category being edited
          .map(category => ({
            value: category.id,
            label: category.name
          }));
          setAssesteropt([ ...filteredOptions]);
        } catch (error) {
          console.error('Error fetching select options:', error);
        }
    }; 
    const fetchCurrencyOptions = async (editId = null) => {
        try {
          const response = await axiosInstance.get('generalledger/generalcurrency/get/');
          console.log(editId,'snckdsvv');
          const filteredOptions = response.data
          .filter(category => category.id !== editId) // Exclude the category being edited
          .map(category => ({
            value: category.id,
            label: category.name
          }));
          setCurrencyopt([ ...filteredOptions]);
        } catch (error) {
          console.error('Error fetching select options:', error);
        }
    }; 
    const fetchWHCategoryOptions = async (editId = null) => {
        try {
          const response = await axiosInstance.get('taxation/whtaxcategory/get/');
          console.log(editId,'snckdsvv');
          const filteredOptions = response.data
          .filter(category => category.id !== editId) // Exclude the category being edited
          .map(category => ({
            value: category.id,
            label: category.name
          }));
          setWhcategory([ ...filteredOptions]);
          console.log([ ...filteredOptions],'wh category option');
        } catch (error) {
          console.error('Error fetching select options:', error);
        }
    }; 
    const fetchGSTCategoryOptions = async (editId = null) => {
        try {
          const response = await axiosInstance.get('taxation/gsttaxcategory/get/');
          console.log(editId,'snckdsvv');
          const filteredOptions = response.data
          .filter(category => category.id !== editId) // Exclude the category being edited
          .map(category => ({
            value: category.id,
            label: category.name
          }));
          setGstcategory([ ...filteredOptions]);
          console.log([ ...filteredOptions],'wh category option');
        } catch (error) {
          console.error('Error fetching select options:', error);
        }
    }; 
    const fetchWHTypeOptions = async (editId = null) => {
        try {
          const response = await axiosInstance.get('taxation/whtaxtype/get/');
          console.log(editId,'snckdsvv');
          const filteredOptions = response.data
          .filter(category => category.id !== editId) // Exclude the category being edited
          .map(category => ({
            value: category.id,
            label: category.name
          }));
          setWhtaxtype([ ...filteredOptions]);
          console.log([ ...filteredOptions],'wh type option');
        } catch (error) {
          console.error('Error fetching select options:', error);
        }
    }; 
    const fetchWHTaxCodeeOptions = async (editId = null) => {
        try {
          const response = await axiosInstance.get('taxation/withholdtax/get/');
          console.log(editId,'snckdsvv');
          const filteredOptions = response.data
          .filter(category => category.id !== editId) // Exclude the category being edited
          .map(category => ({
            value: category.id,
            label: category.name,
            description: category.description
          }));
          setWhtaxcode([ ...filteredOptions]);
          console.log([ ...filteredOptions],'wh type option');
        } catch (error) {
          console.error('Error fetching select options:', error);
        }
    }; 
    const fetchGSTTypeOptions = async (editId = null) => {
        try {
          const response = await axiosInstance.get('taxation/gsttaxtype/get/');
          console.log(editId,'snckdsvv');
          const filteredOptions = response.data
          .filter(category => category.id !== editId) // Exclude the category being edited
          .map(category => ({
            value: category.id,
            label: category.name,
          }));
          setGsttaxtype([ ...filteredOptions]);
          console.log([ ...filteredOptions],'wh type option');
        } catch (error) {
          console.error('Error fetching select options:', error);
        }
    }; 
    const fetchGSTTaxClusterOption = async () => {
        try {
          const response = await axiosInstance.get('taxation/taxcluster/get/');
          const filteredOptions = response.data
            .map((cluster) => ({
              value: cluster.id,
              label: cluster.name,
              description: cluster.description,
              GSTCategory: cluster.GSTCategory,
              GSTType: cluster.GSTType,
            }));
            
          setFilteredTaxClusters(filteredOptions);
          console.log(filteredOptions,"+_+-==-=-===-=-=-=-=-= cluster ++_+_+_+ option")
        } catch (error) {
          console.error('Error fetching tax clusters:', error);
        }
      };
    // const fetchGSTTaxClusterOption = async (categoryId, typeId) => {
    //     try {
    //       const response = await axiosInstance.get('taxation/taxcluster/get/');
    //       const filteredOptions = response.data
    //         .filter((cluster) => cluster.GSTCategory === parseInt(categoryId) && cluster.GSTType === parseInt(typeId))
    //         .map((cluster) => ({
    //           value: cluster.id,
    //           label: cluster.name,
    //           description: cluster.description,
    //         }));
      
    //       setFilteredTaxClusters(filteredOptions);
    //       console.log(filteredOptions,"+_+-==-=-===-=-=-=-=-= cluster option")
    //     } catch (error) {
    //       console.error('Error fetching tax clusters:', error);
    //     }
    //   };
      
    const fetchNatureassesOptions = async (editId = null) => {
        try {
          const response = await axiosInstance.get('taxation/natureofassessee/get/');
          console.log(editId,'snckdsvv');
          const filteredOptions = response.data
          .filter(category => category.id !== editId) // Exclude the category being edited
          .map(category => ({
            value: category.id,
            label: category.name
          }));
          setNatureopt([ ...filteredOptions]);
        } catch (error) {
          console.error('Error fetching select options:', error);
        }
    }; 
    const getHighlightedText = (text, highlight) => {
        if (!text || typeof text !== 'string') {
            return <span>{text}</span>;
        }
        if (!highlight || typeof highlight !== 'string') {
            return <span>{text}</span>; 
        }
        const escapedHighlight = highlight.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&');
        const parts = text.split(new RegExp(`(${escapedHighlight})`, 'gi'));
        return (
            <span>
                {parts.map((part, index) =>
                    part.toLowerCase() === highlight.toLowerCase() ? (
                        <span key={index} style={{ fontWeight: 'bold' }}>{part}</span>
                    ) : (
                        part
                    )
                )}
            </span>
        );
    };
    const handleEnter = (event) => {
        if (event.target && nameError1 && (event.key === 11 || event.key === 13)) {
          event.target.focus();  
        } 
        else if (event.keyCode === 13 && event.target.form) {
          if (event.target.tagName === 'INPUT' || event.target.tagName === 'TEXTAREA') {
            event.target.value = event.target.value.trim();
          }
          if (dropdownOpen) {
            return; 
          }else if(dropdownCatOpen){
            return;
          }else if(dropdownRegOpen){
            return;
          }else if(dropdownAssesOpen){
            return;
          }else if(dropdownTransOpen){
            return;
          }else if(dropdownFromOpen){
            return;
          }else if(dropdownCurrOpen){
            return;
          }else if(dropdownWhholdOpen){
            return;
          }else if(dropdownNatuAssOpen){
            return;
          }else {
            const form = event.target.form;
            const index = Array.prototype.indexOf.call(form.elements, event.target);
            const nextElement = form.elements[index + 1];
            if (nextElement) {
              nextElement.focus();
            }
            event.preventDefault();
          }
        }
        const { value } = event.target;
        if (
          (event.key === ' ' && value.length === 0) || 
          (event.key === ' ' && value.endsWith(' ')) || 
          (event.key === ' ' && value.includes('  '))
        ) {
          event.preventDefault();
          return;
        }
    };
    const handleBlur = (e) => {
        setNameError2(null);
        setDescriptionError2(null);
        if (e.target.tagName === 'INPUT' || e.target.tagName === 'TEXTAREA') {
          e.target.value = e.target.value.trim();  // Ensures the value is trimmed properly
          
          if (nameError1) {
              e.target.focus();
          }else if (descriptionError1) {
            e.target.focus(); 
          }
        }
        if(nameError1){
            setNameError1('Vendor name with this name already exists.');
            console.log('Vendor name with this name already exists.')
            setTimeout(() => {
              const nameInput = document.getElementById('nameInput');
              if (nameInput) {
                nameInput.focus(); // Set focus on the input with id 'nameInput'
              }
            }, 0);
            return false;
          }else {
            setNameError1(null);
        }
      };

    const validateVendorCode = () => {
        const otherVendors = isEditing ? vendors.filter(v => v.id !== editId) : vendors;
        const isDuplicateCode = otherVendors.some(
            item => item.vendor_code.replace(/\s+/g, '').toLowerCase() === vendorCode.replace(/\s+/g, '').toLowerCase()
        );

        if (isDuplicateCode) {
            setNameError2('Vendor code with this code already exists.');
            vendorCodeRef.current.focus(); // Focus back to code field if error
            setTimeout(() => {
                const nameInput = document.getElementById('codeInput');
                if (nameInput) {
                    nameInput.focus(); // Set focus on the input with id 'nameInput'
                }
            }, 0);
            return false;
        } else {
            setNameError2(null);
            return true;
        }
    };
    const validateVendorName = () => {
        const otherVendors = isEditing ? vendors.filter(v => v.id !== editId) : vendors;
        const isDuplicateName = otherVendors.some(
            item => item.vendor_name.replace(/\s+/g, '').toLowerCase() === vendorName.replace(/\s+/g, '').toLowerCase()
        );
        
        if (isDuplicateName) {
            setNameError1('Vendor name with this name already exists.');
            vendorNameRef.current.focus(); // Focus back to name field if error
            setTimeout(() => {
                const nameInput = document.getElementById('nameInput');
                if (nameInput) {
                nameInput.focus(); // Set focus on the input with id 'nameInput'
                }
            }, 0);
            return false;
        } else {
            setNameError1(null);
            return true;
        }
    };

    const handleDescriptionFocus = () => {
        const isNameValid = validateVendorName();
        const isCodeValid = validateVendorCode();
        
        if (!isNameValid || !isCodeValid) {
            return; // Prevent focus change if there's an error
        }
    };

    const handleNext = () => {
        console.log(TabCardData.length,"HandleNext hi");
        setActiveTab(activeTab+1);
      };
    
      // Handler to move to the previous tab
      const handleBack = () => {
        if (activeTab > 1) {
          setActiveTab((prevTab) => prevTab - 1);  // Decrement activeTab as a number
          console.log("HandleBack");
        }
      };
    const handleArrowNavigation = (event, buttonType) => {
        if (event.key === 'ArrowRight' && buttonType === 'save' && cancelButtonRef.current) {
          cancelButtonRef.current.focus();
        } else if (event.key === 'ArrowLeft' && buttonType === 'cancel' && submitButtonRef.current) {
          submitButtonRef.current.focus();
        }
    };
    const handleRateTypeChange = (type) => {
        setRateType(type);
        if (type === 'foreignVendor') {
            setVendorLocation(0);
        } else {
            setVendorLocation(1);
        }
    };
    // const handleSave = ()=>{
    //     const gstEmptyFieldsMessages = gstrows
    //     .map((row, index) => {
    //       const missingFields = [];
    //       if (!row.category) missingFields.push("GST category");
    //       if (!row.type) missingFields.push("GST type");
    //       if (!row.taxcluster) missingFields.push("GST tax cluster");
    //       if (!row.from_date) missingFields.push("from date");
    //       if (!row.to_date) missingFields.push("to date");
    
    //       if (missingFields.length > 0) {
    //         return `Row ${index + 1} missing fields: ${missingFields.join(", ")}`;
    //       }
    //       return null;
    //     })
    //     .filter(Boolean); // Remove null entries
    //     if (gstEmptyFieldsMessages.length > 0) {
    //         alert(`GST Tax Data:\n${gstEmptyFieldsMessages.join("\n")}`);
    //     }else{
    //         setGsttaxfocus(false);
    //     }
    // }
    const handleSaveWh = () => {
        const whEmptyFieldsMessages = whrows
            .map((row, index) => {
                const missingFields = [];
                if (!row.category) missingFields.push("WHT category");
                if (!row.type) missingFields.push("WHT type");
                if (!row.taxcode) missingFields.push("WHT taxcode");
                if (!row.from_date) missingFields.push("from date");
                if (!row.to_date) missingFields.push("to date");
    
                if (missingFields.length > 0) {
                    return `Row ${index + 1} missing fields: ${missingFields.join(", ")}`;
                }
                return null;
            })
            .filter(Boolean);
    
        if (whEmptyFieldsMessages.length > 0) {
            alert(`Withhold Tax Data:\n${whEmptyFieldsMessages.join("\n")}`);
        } else {
            setSavedRows([...savedRows, ...addedRows]);
            setAddedRows([]); // Clear addedRows as they are now saved
            setWhtaxfocus(false);
        }
    };
    // const handleCloseGst = () =>{
    //     setGstrows((prevRows) => prevRows.filter((row) => !addedGstRows.includes(row)));
    //     setAddedGstRows([]); // Reset added rows
    //     setGsttaxfocus(false);

    // }
    const handleCloseWh = () => {
        // Keep only the saved rows when closing (discard unsaved rows)
        const retainedRows = whrows.filter(row => !addedRows.includes(row));
        setWhrows(retainedRows);
        setAddedRows([]); // Reset added rows to clear unsaved data
        setWhtaxfocus(false);
    };
    const handleWHtax =()=>{
        setWhtaxfocus(true);
    };
    const resetform = () =>{
        setAddress('');
        setTransporter([]);
        setAccountPerson('');
        setTransporterid('');
        setNameError1(null);
        setCity('');
        setCountry('');
        setWrstate('');
        setPin('');
        setContactPerson('');
        setAp_telephone('');
        setCp_telephone('');
        setCp_email('');
        setCp_mobile('');
        setAp_email('');
        setAp_mobile('');
        setVendorGroup(null);
        setVendorCategory(null);
        setVendorName('');
        setRegistrationType([]);
        setGstin('');
        setAssesTerritory([]);
        setPaymentFrom([]);
        setCreditDays('');
        setCurrency([]);
        setPaymentTerms('');
        setPaymentMode('');
        setAccountName('');
        setAccountNumber('');
        setClearRows(!clearRows);
        setIfsc('');
        setBankName('');
        setPan('');
        setCalwhtax([]);
        setVendorCode('');
        setNatureasses([]);
        setRateType('');
        setWhrows([
            { 
                category: '',
                type: '',
                taxcode:'',
                description:'',
                from_date: '',
                to_date: '',
            }
        ]);
        setGstrows([
            { 
                category: '',
                type: '',
                taxcode:'',
                description:'',
                from_date: '',
                to_date: '',
            }
        ]);
    };
    const handleDelete = async () => {
        try {
            await axiosInstance.delete('vendor/vendor/delete/', {
              data: { ids: selectedIds },
            });
            fetchVendors(currentPage);
            setSelectedIds([]);
            // fetchSelectOptions();
            // fetchCategoryOptions();
            resetform();
          // }
        } catch (error) {
          console.error('Error:', error);
          Swal.fire('Error!', 'There was a problem fetching Item Group names or deleting the Item Group.', 'error');
          
        }
      };
    
      const handleDeleteCategory = async (id) => {
          try {
            await axiosInstance.delete(`vendor/vendor/${id}/`);
            fetchVendors(currentPage);
            // Swal.fire('Deleted!', 'Your category has been deleted.', 'success');
          } catch (error) {
           
            const errorMessage = JSON.stringify(error.response.data.detail, null, 2);
            Swal.fire('Error!',errorMessage , 'error');
      
          }
    
          setSelectedIds([]);
          // fetchSelectOptions();
          // fetchCategoryOptions();
          resetform();
        // }
      };
      const validateName = (value) => {
        console.log(value,'kjhvckjsdnv');
        
       if (value.match('  ')) {
          setNameError1('Continuous spaces are not allowed ');
          return false;
        }  else if(value.length === 30){
          setNameError2(null);
        }else {
          setNameError1(null);
          return true;
        }
      };
      const handleNameChange = (e) => {
        let value = e.target.value;
        value = value.replace(/\s+/g, ' ').trimStart();
        setVendorName(value);
        validateName(value); 
    };
    return(
        <Fragment>
            <Breadcrumbs mainTitle="Vendor" parent="Procurment" title="Vendor" />
                <Container fluid={true} className='Container1'>
                <Form className="theme-form" onSubmit={handleSubmit}>
                    <Col sm="12" xl="6" className='vendorcol2a'>
                        <Card>
                            <div className='formdiv1ba'>
                                <div className='rowForm1'>
                                    <div className='formcol1'>
                                        <FormGroup className='formin1'>
                                            <Label style={{fontWeight:"500"}} className="col-form-label">Vendor Code</Label>
                                            <Input onFocus={handleDescriptionFocus} id="codeInput" onBlur={validateVendorCode} autoComplete="off" className="forminput inputfoc" onKeyDown={handleEnter} ref={vendorCodeRef} style={{color:"black",borderColor: "#afafaf"}} maxLength={10} value={vendorCode} onChange={(e) => setVendorCode(e.target.value)} required/>
                                            {nameError2 && (
                                            <div className="alert alert-danger mt-2">
                                                {nameError2}
                                            </div>
                                            )}
                                        </FormGroup>
                                        <FormGroup className='formin1'>
                                            <Label style={{fontWeight:"500"}} className="col-form-label">Vendor Name</Label>
                                            <Input onBlur={validateVendorName} className="forminput inputfoc" value={vendorName} autoComplete="off" onChange={handleNameChange} ref={vendorNameRef}  onKeyDown={handleEnter}  id='nameInput' style={{color:"black",borderColor: "#afafaf"}} maxLength={100} required/>
                                            {nameError1 && (
                                            <div className="alert alert-danger mt-2">
                                                {nameError1}
                                            </div>
                                            )}
                                        </FormGroup>
                                    </div>
                                </div>
                            </div>
                        </Card>
                    </Col>
                    {TabCardData.map((item, i) =>
                        <Col sm="12" xl="6" className='vendorcol2a' key={i}>
                            <Card>
                                <CardBody>
                                    <div className="tabbed-card">
                                        <Nav className={item.navClass} style={{height:'30px'}}>
                                            <NavItem className="navitem">
                                                <NavLink style={{height:'25px', marginBottom:"10px", display:"flex", alignItems:"center"}} className={activeTab === 1 ? 'active' : ''} onClick={() => setActiveTab(1)}>
                                                    Mailing
                                                </NavLink>
                                            </NavItem>
                                            <NavItem className="navitem">
                                                <NavLink style={{height:'25px', marginBottom:"10px", display:"flex", alignItems:"center"}} className={activeTab === 2 ? 'active' : ''} onClick={() => setActiveTab(2)}>
                                                    Contact Information
                                                </NavLink>
                                            </NavItem>
                                            <NavItem className="navitem">
                                                <NavLink style={{height:'25px', marginBottom:"10px", display:"flex", alignItems:"center"}} className={activeTab === 3 ? 'active' : ''} onClick={() => setActiveTab(3)}>
                                                    Grouping
                                                </NavLink>
                                            </NavItem>
                                            <NavItem className="navitem">
                                                <NavLink style={{height:'25px', marginBottom:"10px", display:"flex", alignItems:"center"}} className={activeTab === 4 ? 'active' : ''} onClick={() => setActiveTab(4)}>
                                                    Tax Registration
                                                </NavLink>
                                            </NavItem>
                                            <NavItem className="navitem">
                                                <NavLink style={{height:'25px', marginBottom:"10px", display:"flex", alignItems:"center"}} className={activeTab === 5 ? 'active' : ''} onClick={() => setActiveTab(5)}>
                                                    Payment Terms
                                                </NavLink>
                                            </NavItem>
                                            <NavItem className="navitem">
                                                <NavLink style={{height:'25px', marginBottom:"10px", display:"flex", alignItems:"center"}} className={activeTab === 6 ? 'active' : ''} onClick={() => setActiveTab(6)}>
                                                    Bank
                                                </NavLink>
                                            </NavItem>
                                            <NavItem className="navitem">
                                                <NavLink style={{height:'25px', marginBottom:"10px", display:"flex", alignItems:"center"}} className={activeTab === 7 ? 'active' : ''} onClick={() => setActiveTab(7)}>
                                                    Statutory
                                                </NavLink>
                                            </NavItem>
                                            <NavItem className="navitem">
                                                <NavLink style={{height:'25px', marginBottom:"10px", display:"flex", alignItems:"center"}} className={activeTab === 8 ? 'active' : ''} onClick={() => setActiveTab(8)}>
                                                    GST Tax
                                                </NavLink>
                                            </NavItem>
                                        </Nav>
                                    </div>
                                    <TabContent activeTab={activeTab.toString()}>
                                        <TabPane tabId="1">
                                            <div className="h5cus">
                                                <h5 className="h5title">Mailing Details </h5>
                                            </div>
                                            <div className='formdiv1b'>
                                                <div className='rowForm1'>
                                                    <div className='formcol1'>
                                                        <FormGroup className='formin2'>
                                                            <Label className="label1">Address</Label>
                                                            <Input autoComplete="off"  onBlur={validateVendorName} onKeyDown={handleEnter} value={address} onChange={(e)=>setAddress(e.target.value)}  onFocus={handleDescriptionFocus} style={{color:"black",borderColor: "#afafaf" ,height:"98px"}} className="inputfoc1" type="textarea" maxLength={250}/>
                                                        </FormGroup>
                                                    <div className='formin2'>
                                                        <FormGroup>
                                                            <Label className="label1">City</Label>
                                                            <Input autoComplete="off" onBlur={validateVendorName} onKeyDown={handleEnter} value={city} onChange={(e)=>setCity(e.target.value)} onFocus={handleDescriptionFocus} style={{color:"black",borderColor: "#afafaf" ,}} className="forminput inputfoc" maxLength={25}/>
                                                        </FormGroup>
                                                        <FormGroup>
                                                            <Label className="label1">State</Label>
                                                            <Input autoComplete="off" onBlur={validateVendorName} onKeyDown={handleEnter} value={wrstate} onChange={(e)=>setWrstate(e.target.value)} onFocus={handleDescriptionFocus} style={{color:"black",borderColor: "#afafaf" ,}} className="forminput inputfoc" maxLength={25}/>
                                                        </FormGroup>
                                                    </div>
                                                    <div className='formin2'>
                                                        <FormGroup >
                                                            <Label className="label1">Country</Label>
                                                            <Input autoComplete="off" onBlur={validateVendorName} value={country} onChange={(e)=>setCountry(e.target.value)} onKeyDown={handleEnter}  onFocus={handleDescriptionFocus} style={{color:"black",borderColor: "#afafaf" }} className="forminput inputfoc" maxLength={25}/>
                                                        </FormGroup>
                                                        <FormGroup>
                                                            <Label className="label1">Pincode</Label>
                                                            <Input autoComplete="off" onBlur={validateVendorName} value={pin} onChange={(e)=>setPin(e.target.value)}  onKeyDown={handleEnter}  onFocus={handleDescriptionFocus} style={{color:"black",borderColor: "#afafaf" }} className="forminput inputfoc" maxLength={10}/>
                                                        </FormGroup>
                                                    </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </TabPane>
                                        <TabPane tabId="2">
                                            <div className="h5cus">
                                                <h5 className="h5title">Contact Information</h5>
                                            </div>
                                            <div className='formdiv1b'>
                                                <div className='rowForm1'>
                                                    <div className='formcol1'>
                                                        <FormGroup className='formin2'>
                                                            <Label className="label1">Contact Person</Label>
                                                            <Input autoComplete="off" value={contactPerson} onChange={(e)=> setContactPerson(e.target.value)} onBlur={handleBlur} onKeyDown={handleEnter}  onFocus={handleDescriptionFocus} style={{color:"black",borderColor: "#afafaf" }} className="forminput inputfoc" type="text" maxLength={50}/>
                                                        </FormGroup>
                                                        <FormGroup className='formin2'>
                                                            <Label className="label1">Telephone</Label>
                                                            <Input autoComplete="off" onBlur={validateVendorName} value={cp_telephone} onChange={(e)=>setCp_telephone(e.target.value)} onKeyDown={handleEnter}  onFocus={handleDescriptionFocus} style={{color:"black",borderColor: "#afafaf" }} className="forminput inputfoc" maxLength={25}/>
                                                        </FormGroup>
                                                        <FormGroup className='formin2'>
                                                            <Label className="label1">Mobile</Label>
                                                            <Input autoComplete="off" onBlur={validateVendorName} onKeyDown={handleEnter} value={cp_mobile} onChange={(e)=>setCp_mobile(e.target.value)} onFocus={handleDescriptionFocus} style={{color:"black",borderColor: "#afafaf" }} className="forminput inputfoc" maxLength={25}/>
                                                        </FormGroup>
                                                    </div>
                                                    <div className='formcol1'>
                                                        <FormGroup className='formin2'>
                                                            <Label className="label1">E-mail</Label>
                                                            <Input autoComplete="off" onBlur={validateVendorName} onKeyDown={handleEnter} value={cp_email} onChange={(e)=>setCp_email(e.target.value)} onFocus={handleDescriptionFocus} style={{color:"black",borderColor: "#afafaf" }} className="forminput inputfoc" type="text" maxLength={50}/>
                                                        </FormGroup>
                                                        <FormGroup className='formin2'>
                                                            <Label className="label1">Accounts Person</Label>
                                                            <Input autoComplete="off" onBlur={validateVendorName} onKeyDown={handleEnter} value={accountsPerson} onChange={(e)=>setAccountPerson(e.target.value)} onFocus={handleDescriptionFocus} style={{color:"black",borderColor: "#afafaf" }} className="forminput inputfoc" maxLength={50}/>
                                                        </FormGroup>
                                                        <FormGroup className='formin2'>
                                                            <Label className="label1">Telephone</Label>
                                                            <Input autoComplete="off" onBlur={validateVendorName} onKeyDown={handleEnter} value={ap_telephone} onChange={(e)=>setAp_telephone(e.target.value)} onFocus={handleDescriptionFocus} style={{color:"black",borderColor: "#afafaf" }} className="forminput inputfoc" maxLength={25}/>
                                                        </FormGroup>
                                                    </div>
                                                    <div className='formcol1'>
                                                        <FormGroup className='formin1'>
                                                            <Label className="label1">Mobile</Label>
                                                            <Input autoComplete="off" onBlur={validateVendorName} onKeyDown={handleEnter} value={ap_mobile} onChange={(e)=>setAp_mobile(e.target.value)} onFocus={handleDescriptionFocus} style={{color:"black",borderColor: "#afafaf" }} className="forminput inputfoc" type="text" maxLength={25}/>
                                                        </FormGroup>
                                                        <FormGroup className='formin1'>
                                                            <Label className="label1">E-mail</Label>
                                                            <Input autoComplete="off" onBlur={validateVendorName} onKeyDown={handleEnter} value={ap_email} onChange={(e)=>setAp_email(e.target.value)} onFocus={handleDescriptionFocus} style={{color:"black",borderColor: "#afafaf" }} className="forminput inputfoc" maxLength={50}/>
                                                        </FormGroup>
                                                    </div>
                                                </div>
                                            </div>
                                        </TabPane>
                                        <TabPane tabId="3">
                                            <div className="h5cus">
                                                <h5 className="h5title">Grouping</h5>
                                            </div>
                                            <div className='formdiv1b'>
                                                <div className='rowForm1'>
                                                    <div className='formcol1'>
                                                        <FormGroup className='formin2 '>
                                                            <Label className="label1">Vendor Location</Label>
                                                            <div className='m-t-10 m-checkbox-inline custom-radio-ml' style={{display:"flex", paddingLeft:"10px"}}>  
                                                                <div className='radio radio-primary'>
                                                                    <Input onBlur={validateVendorName} onFocus={handleDescriptionFocus} id='radioinline1' type='radio' name="rateType" value={1} onChange={() => handleRateTypeChange('domesticVendor')} checked={rateType === 'domesticVendor'}  />
                                                                    <Label className='mb-0' for='radioinline1'>
                                                                    {Option}
                                                                    <span className='digits' style={{fontSize:"12px"}}> {'Domestic Vendor'}</span>
                                                                    </Label>
                                                                </div>
                                                                <div className='radio radio-primary'>
                                                                    <Input onBlur={validateVendorName} onFocus={handleDescriptionFocus} className="forminput"  id='radioinline2' type='radio' name="rateType" value={0} onChange={() => handleRateTypeChange('foreignVendor')} checked={rateType === 'foreignVendor'}  />
                                                                    <Label className='mb-0' for='radioinline2'>
                                                                    {Option}
                                                                    <span className='digits' style={{fontSize:"12px"}}> {'Foreign Vendor'}</span>
                                                                    </Label>
                                                                </div>
                                                            </div>
                                                        </FormGroup>
                                                        <FormGroup className='formin2'>
                                                            <Label className="label1">Vendor Group</Label>
                                                            <Select
                                                                value={vendorGroup}
                                                                onBlur={validateVendorName}
                                                                onKeyDown={handleEnter}
                                                                onMenuOpen={() => {
                                                                    setDropdownOpen(true);
                                                                }}
                                                                onMenuClose={() => {
                                                                    setDropdownOpen(false);
                                                                }}
                                                                className="forminput"
                                                                onFocus={handleDescriptionFocus}
                                                                styles={customStyles}
                                                                onChange={(option) => setVendorGroup(option)}
                                                                
                                                                options={venGroupopt}
                                                                filterOption={(option, inputValue) => 
                                                                    option.label.toLowerCase().startsWith(inputValue.toLowerCase())
                                                                }
                                                                formatOptionLabel={(option, { inputValue }) => 
                                                                    getHighlightedText(option.label, inputValue)
                                                                }
                                                            />
                                                        </FormGroup>
                                                        <FormGroup className='formin2'>
                                                            <Label className="label1">Vendor Category</Label>
                                                            <Select
                                                                value={vendorcategory}
                                                                className="forminput"
                                                                onBlur={validateVendorName}
                                                                onKeyDown={handleEnter}
                                                                onMenuOpen={() => {
                                                                     setDropdownCatOpen(true);
                                                                }}
                                                                onMenuClose={() => {
                                                                    setDropdownCatOpen(false);
                                                                }}
                                                                onFocus={handleDescriptionFocus}
                                                                styles={customStyles}
                                                                onChange={(option) => setVendorCategory(option)}
                                                                
                                                                options={venCategoryopt}
                                                                filterOption={(option, inputValue) => 
                                                                    option.label.toLowerCase().startsWith(inputValue.toLowerCase())
                                                                }
                                                                formatOptionLabel={(option, { inputValue }) => 
                                                                    getHighlightedText(option.label, inputValue)
                                                                }
                                                            />
                                                        </FormGroup>
                                                    </div>
                                                </div>
                                            </div>
                                        </TabPane>
                                        <TabPane tabId="4">
                                            <div className="h5cus">
                                                <h5 className="h5title">Tax Registration Details</h5>
                                            </div>
                                            <div className='formdiv1b'>
                                                <div className='rowForm1'>
                                                    <div className='formcol1'>
                                                        <FormGroup className='formin2'>
                                                            <Label className="label1">Registration type</Label>
                                                            <Select
                                                                value={registrationType}
                                                                onBlur={validateVendorName}
                                                                onKeyDown={handleEnter}
                                                                onMenuOpen={() => {
                                                                    setDropdownRegOpen(true);
                                                                }}
                                                                onMenuClose={() => {
                                                                    setDropdownRegOpen(false);
                                                                }}
                                                                onFocus={handleDescriptionFocus}
                                                                styles={customStyles}
                                                                onChange={(option) => setRegistrationType(option)}
                                                                
                                                                options={regTypeOpt}
                                                                filterOption={(option, inputValue) => 
                                                                    option.label.toLowerCase().startsWith(inputValue.toLowerCase())
                                                                }
                                                                formatOptionLabel={(option, { inputValue }) => 
                                                                    getHighlightedText(option.label, inputValue)
                                                                }
                                                            />
                                                        </FormGroup>
                                                        <FormGroup className='formin2'>
                                                            <Label className="label1">GSTIN</Label>
                                                            <Input autoComplete="off" onBlur={validateVendorName} className="forminput inputfoc" onKeyDown={handleEnter} value={gstin} onChange={(e)=>setGstin(e.target.value)} onFocus={handleDescriptionFocus} style={{color:"black",borderColor: "#afafaf" }} maxLength={15}/>
                                                        </FormGroup>
                                                        <FormGroup className='formin2'>
                                                            <Label className="label1">Assessee Territory</Label>
                                                            <Select
                                                                value={assesTerritory}
                                                                onBlur={validateVendorName}
                                                                onKeyDown={handleEnter}
                                                                onMenuOpen={() => {
                                                                    setDropdownassesOpen(true);
                                                                }}
                                                                onMenuClose={() => {
                                                                    setDropdownassesOpen(false);
                                                                }}
                                                                onFocus={handleDescriptionFocus}
                                                                styles={customStyles}
                                                                onChange={(option) => setAssesTerritory(option)}
                                                                
                                                                options={assesterOpt}
                                                                filterOption={(option, inputValue) => 
                                                                    option.label.toLowerCase().startsWith(inputValue.toLowerCase())
                                                                }
                                                                formatOptionLabel={(option, { inputValue }) => 
                                                                    getHighlightedText(option.label, inputValue)
                                                                }
                                                            />
                                                        </FormGroup>
                                                    </div>
                                                    <div className='formcol1'>
                                                        <FormGroup className='formin1'>
                                                            <Label className="label1">Is a Transporter</Label>
                                                            <Select
                                                                value={transporter}
                                                                onBlur={validateVendorName}
                                                                onKeyDown={handleEnter}
                                                                onMenuOpen={() => {
                                                                    setDropdownTransOpen(true);
                                                                }}
                                                                onMenuClose={() => {
                                                                    setDropdownTransOpen(false);
                                                                }}
                                                                onFocus={handleDescriptionFocus}
                                                                styles={customStyles}
                                                                onChange={(option) => setTransporter(option)}
                                                                
                                                                options={transporteropt}
                                                                filterOption={(option, inputValue) => 
                                                                    option.label.toLowerCase().startsWith(inputValue.toLowerCase())
                                                                }
                                                                formatOptionLabel={(option, { inputValue }) => 
                                                                    getHighlightedText(option.label, inputValue)
                                                                }
                                                            />
                                                        </FormGroup>
                                                        <FormGroup className='formin1'>
                                                            <Label className="label1">Transporter ID</Label>
                                                            <Input autoComplete="off" className="forminput inputfoc" onBlur={validateVendorName} value={transporterid} onChange={(e)=>setTransporterid(e.target.value)} onKeyDown={handleEnter} style={{color:"black",borderColor: "#afafaf" }} maxLength={15}/>
                                                        </FormGroup>
                                                    </div>
                                                </div>
                                            </div>
                                            <div style={{width:"50%", marginBottom:"20px",display:"flex", gap:"20px"}}>
                                                    <Button className='btn2a' color='primary' type='button' onClick={handleWHtax}>WithHold Tax</Button>

                                                </div>
                                        </TabPane>
                                        <TabPane tabId="5">
                                            <div className="h5cus">
                                                <h5 className="h5title">Payment Terms</h5>
                                            </div>
                                            <div className='formdiv1b'>
                                                <div className='rowForm1'>
                                                    <div className='formcol1'>
                                                        <FormGroup className='formin2'>
                                                            <Label className="label1">Terms of payment</Label>
                                                            <Input autoComplete="off" onBlur={validateVendorName} onKeyDown={handleEnter} value={paymentTerms} onChange={(e)=>setPaymentTerms(e.target.value)} style={{color:"black",borderColor: "#afafaf" ,height:"98px"}} type="textarea" className="inputfoc1" maxLength={250}/>
                                                        </FormGroup>
                                                    <div className='formin2'>
                                                        <FormGroup>
                                                                <Label className="label1">Mode of payment</Label>
                                                                <Input autoComplete="off" onBlur={validateVendorName} onKeyDown={handleEnter} value={paymentMode} onChange={(e)=>setPaymentMode(e.target.value)} style={{color:"black",borderColor: "#afafaf" ,}} className="forminput inputfoc" maxLength={50}/>
                                                            </FormGroup>
                                                            <FormGroup>
                                                                <Label className="label1">Credit days</Label>
                                                                <Input autoComplete="off" onBlur={validateVendorName} onInput={(e) => {if (/[eE]/.test(e.target.value)) {e.target.value = e.target.value.replace(/[eE]/g, "");}}} onKeyDown={(event)=>{if (event.key === "e" || event.key === "E" || event.key === "+" || event.key === "-") {event.preventDefault();}handleEnter(event)}} type="number" value={creditDays} onChange={(e)=>setCreditDays(e.target.value)} style={{color:"black",borderColor: "#afafaf" ,}} className="forminput inputfoc" />
                                                            </FormGroup>
                                                    </div>
                                                    <div className='formin2'>
                                                        <FormGroup >
                                                                <Label className="label1">From</Label>
                                                                <Select
                                                                    value={paymentFrom}
                                                                    onBlur={validateVendorName}
                                                                    onKeyDown={handleEnter}
                                                                    onMenuOpen={() => {
                                                                        setDropdownFromOpen(true);
                                                                    }}
                                                                    onMenuClose={() => {
                                                                        setDropdownFromOpen(false);
                                                                    }}
                                                                    onFocus={handleDescriptionFocus}
                                                                    styles={customStyles}
                                                                    onChange={(option) => setPaymentFrom(option)}
                                                                    
                                                                    options={paymentBase}
                                                                    filterOption={(option, inputValue) => 
                                                                        option.label.toLowerCase().startsWith(inputValue.toLowerCase())
                                                                    }
                                                                    formatOptionLabel={(option, { inputValue }) => 
                                                                        getHighlightedText(option.label, inputValue)
                                                                    }
                                                                />
                                                            </FormGroup>
                                                            <FormGroup>
                                                                <Label className="label1">Currency</Label>
                                                                <Select
                                                                    value={currency}
                                                                    onBlur={validateVendorName}
                                                                    onKeyDown={handleEnter}
                                                                    onMenuOpen={() => {
                                                                        setDropdownCurrOpen(true);
                                                                    }}
                                                                    onMenuClose={() => {
                                                                        setDropdownCurrOpen(false);
                                                                    }}
                                                                    onFocus={handleDescriptionFocus}
                                                                    styles={customStyles}
                                                                    onChange={(option) => setCurrency(option)}
                                                                    
                                                                    options={currencyopt}
                                                                    filterOption={(option, inputValue) => 
                                                                        option.label.toLowerCase().startsWith(inputValue.toLowerCase())
                                                                    }
                                                                    formatOptionLabel={(option, { inputValue }) => 
                                                                        getHighlightedText(option.label, inputValue)
                                                                    }
                                                                />
                                                            </FormGroup>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </TabPane>
                                        <TabPane tabId="6">
                                            <div className="h5cus">
                                                <h5 className="h5title">Bank Details</h5>
                                            </div>
                                            <div className='formdiv1b'>
                                                <div className='rowForm1'>
                                                    <div className='formcol1'>
                                                        <FormGroup className='formin1'>
                                                            <Label className="label1">Account Name</Label>
                                                            <Input autoComplete="off" onBlur={validateVendorName} onKeyDown={handleEnter} value={accountName} onChange={(e)=>setAccountName(e.target.value)} style={{color:"black",borderColor: "#afafaf" }} className="forminput inputfoc" type="text" maxLength={100}/>
                                                        </FormGroup>
                                                        <FormGroup className='formin1'>
                                                            <Label className="label1">Account No.</Label>
                                                            <Input autoComplete="off" onBlur={validateVendorName} onKeyDown={handleEnter} value={accountNumber} onChange={(e)=>setAccountNumber(e.target.value)} style={{color:"black",borderColor: "#afafaf" }} className="forminput inputfoc" maxLength={25}/>
                                                        </FormGroup>
                                                    </div>
                                                    <div className='formcol1'>
                                                        <FormGroup className='formin1'>
                                                            <Label className="label1">IFS Code</Label>
                                                            <Input autoComplete="off" onBlur={validateVendorName} value={ifsc} onChange={(e)=>setIfsc(e.target.value)} onKeyDown={handleEnter} style={{color:"black",borderColor: "#afafaf" }} className="forminput inputfoc" maxLength={25}/>
                                                        </FormGroup>
                                                        <FormGroup className='formin1'>
                                                            <Label className="label1">Bank Name</Label>
                                                            <Input autoComplete="off" onBlur={validateVendorName} value={bankName} onChange={(e)=>setBankName(e.target.value)} onKeyDown={handleEnter} style={{color:"black",borderColor: "#afafaf" }} className="forminput inputfoc" maxLength={100}/>
                                                        </FormGroup>
                                                    </div>
                                                </div>
                                            </div>
                                        </TabPane>
                                        <TabPane tabId="7">
                                        <div className="h5cus">
                                                <h5 className="h5title">Statutory Details</h5>
                                            </div>
                                            <div className='formdiv1b'>
                                                <div className='rowForm1'>
                                                    <div className='formcol1'>
                                                        <FormGroup className='formin2'>
                                                            <Label className="label1">PAN / IT No.</Label> 
                                                            <Input autoComplete="off" onBlur={validateVendorName} onKeyDown={handleEnter} value={pan} onChange={(e)=>setPan(e.target.value)} style={{color:"black",borderColor: "#afafaf" }} className="forminput inputfoc" type="text" maxLength={10}/>
                                                        </FormGroup>
                                                        <FormGroup className='formin2'>
                                                            <Label className="label1">Calculate withholding tax</Label>
                                                            <Select
                                                                    value={calwhtax}
                                                                    onBlur={validateVendorName}
                                                                    onKeyDown={handleEnter}
                                                                    onMenuOpen={() => {
                                                                        setDropdownWhholdOpen(true);
                                                                    }}
                                                                    onMenuClose={() => {
                                                                        setDropdownWhholdOpen(false);
                                                                    }}
                                                                    onFocus={handleDescriptionFocus}
                                                                    styles={customStyles}
                                                                    onChange={(option) => setCalwhtax(option)}
                                                                    
                                                                    options={calwhtaxopt}
                                                                    filterOption={(option, inputValue) => 
                                                                        option.label.toLowerCase().startsWith(inputValue.toLowerCase())
                                                                    }
                                                                    formatOptionLabel={(option, { inputValue }) => 
                                                                        getHighlightedText(option.label, inputValue)
                                                                    }
                                                                />
                                                        </FormGroup>
                                                        <FormGroup className='formin2'>
                                                            <Label className="label1">Nature of assessee</Label>
                                                            <Select
                                                                    value={natureasses}
                                                                    onBlur={validateVendorName}
                                                                    onKeyDown={handleEnter}
                                                                    onMenuOpen={() => {
                                                                        setDropdownNatuAssOpen(true);
                                                                    }}
                                                                    onMenuClose={() => {
                                                                        setDropdownNatuAssOpen(false);
                                                                    }}
                                                                    onFocus={handleDescriptionFocus}
                                                                    styles={customStyles}
                                                                    onChange={(option) => setNatureasses(option)}
                                                                    
                                                                    options={natureopt}
                                                                    filterOption={(option, inputValue) => 
                                                                        option.label.toLowerCase().startsWith(inputValue.toLowerCase())
                                                                    }
                                                                    formatOptionLabel={(option, { inputValue }) => 
                                                                        getHighlightedText(option.label, inputValue)
                                                                    }
                                                                />
                                                        </FormGroup>
                                                    </div>
                                                </div>
                                            </div>
                                        </TabPane>
                                        <TabPane tabId="8">
                                            <div className="h5cus">
                                                <h5 className="h5title">GST Tax</h5>
                                            </div>
                                            <div className='formdiv1b'>
                                                <table style={{width:"100%"}}>
                                                    <thead>
                                                        <tr style={{height:"20px"}}>
                                                            <th style={{textAlign:"center"}}>GST Category</th>
                                                            <th style={{textAlign:"center"}}>GST Type</th>
                                                            <th style={{textAlign:"center"}}>GST Tax Cluster</th>
                                                            <th style={{textAlign:"center"}}>Description</th>
                                                            <th style={{textAlign:"center"}}>From Date</th>
                                                            <th style={{textAlign:"center"}}>To Date</th>
                                                            <th style={{textAlign:"center"}}>Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {gstrows.map((row, index) => (
                                                            <tr key={index} style={{height:"20px"}}>
                                                                <td>
                                                                    <div className="table_td_div">
                                                                    <select
                                                                        type="number"
                                                                        name="category"
                                                                        id="category"
                                                                        style={{height:"100%" , padding:0,width:"100%"}}
                                                                        value={row.category}
                                                                        onChange={(e) => handleGSTInputChange(index, e)}
                                                                    >
                                                                        <option value={''} disabled selected>Select Category</option>
                                                                        {gstcategory.map((gstcategory)=>(
                                                                            <option key={gstcategory.value} value={gstcategory.value}>{gstcategory.label}</option>
                                                                        ))}
                                                                    </select>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div className="table_td_div">
                                                                    <select
                                                                        type="number"
                                                                        name="type"
                                                                        id="type"
                                                                        style={{height:"100%" , padding:0,width:"100%"}}
                                                                        value={row.type}
                                                                        onChange={(e) => handleGSTInputChange(index, e)}
                                                                    >
                                                                        <option value={''} disabled selected>Select Type</option>
                                                                        {gsttaxtype.map((gsttaxtype)=>(
                                                                            <option key={gsttaxtype.value} value={gsttaxtype.value}>{gsttaxtype.label}</option>
                                                                        ))}
                                                                    </select>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                <div className="table_td_div">
                                                                    <select
                                                                        type="number"
                                                                        name="taxcluster"
                                                                        id="taxcluster"
                                                                        style={{height:"100%" , padding:0,width:"100%"}}
                                                                        value={row.taxcluster}
                                                                        onChange={(e) => handleGSTInputChange(index, e)}
                                                                        disabled={!row.category || !row.type}
                                                                    >
                                                                        <option value={''} disabled selected>Select Category</option>
                                                                        {filteredTaxClusters.filter((cluster) => cluster.GSTCategory === parseInt(row.category) && cluster.GSTType === parseInt(row.type)).map(cluster=>(
                                                                            <option key={cluster.id} value={cluster.value}>{cluster.label}</option>
                                                                        ))}
                                                                    </select>
                                                                </div>
                                                                </td>
                                                                <td>
                                                                    <div className="table_td_div">
                                                                        <input
                                                                            type="text"
                                                                            name="description"
                                                                            value={row.description}
                                                                            readOnly
                                                                            style={{height:"100%", padding:"0px",color:"black",width:"100%",border:"none"}}
                                                                        />
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div className="table_td_div">
                                                                        <input
                                                                            type="date"
                                                                            name="from_date"
                                                                            style={{height:"100%", padding:"0px",color:"black",width:"100%",border:"none"}}
                                                                            value={row.from_date}
                                                                            onChange={(e) => handleGSTInputChange(index, e)}
                                                                        />
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div className="table_td_div">
                                                                        <input
                                                                            type="date"
                                                                            name="to_date"
                                                                            value={row.to_date}
                                                                            style={{height:"100%", padding:"0px",color:"black",width:"100%",border:"none"}}
                                                                            onChange={(e) => handleGSTInputChange(index, e)}
                                                                        />
                                                                    </div>
                                                                </td>
                                                                <td style={{height:"5px"}}>
                                                                    <button style={{ cursor:'pointer',opacity:1,display:"flex", width:"100%", alignItems:"center", justifyContent:"center"}} title='Delete' type="button" className="vendordele1a" onClick={() => removeRowGst(index)}>
                                                                        <IoTrash className="vendortrash" />
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div style={{display:"flex"}} className="mt-3">
                                                <div style={{width:"50%", marginBottom:"20px",display:"flex", gap:"20px"}}>
                                                    <Button onClick={addRowGst} color='primary' className='btn2a'>
                                                        Add Row
                                                    </Button>
                                                    {/* <Button className='btn2a' color='primary' type='button' onClick={handleWHtax}>WithHold Tax</Button> */}

                                                </div>
                                                 {/* <div className='popbtn' style={{width:"50%"}}> */}
                                                    {/* <Button className='btn2a' color='primary' type='button' onClick={handleSave}>Save</Button>{' '} */}
                                                    {/* <Button className='btn1ab' type="button" onClick={handleCancelGSTTax}>Close</Button> */}
                                                {/* </div> */}
                                            </div>
                                            <div className="subdiv">
                                                <div className="vengirdbtn">
                                                    {/* <Button className='btn2a' color='primary' type='button' onClick={handleGSTtax}>GST Tax</Button> */}
                                                </div>
                                            </div>
                                        </TabPane>
                                    </TabContent>
                                    <div className="mb-3" style={{gap:"10px",display:"flex", justifyContent:"flex-end"}}>
                                        {activeTab === 1? (
                                            null
                                        ):(
                                            <button className='btn2' disabled={activeTab === 1} onClick={handleBack} type='button' ref={submitButtonRef} onKeyDown={(event) => handleArrowNavigation(event, 'save')}>
                                                <IoArrowBackOutline />&nbsp; Previous
                                            </button>
                                        )}
                                        {activeTab === 8 ? (
                                            null
                                        ) : (
                                            <button
                                            className="btn2"
                                            onClick={handleNext}
                                            type="button"
                                            ref={cancelButtonRef}
                                            onKeyDown={(event) => handleArrowNavigation(event, 'cancel')}
                                            >
                                            Next &nbsp;<IoArrowForward />
                                            </button>
                                        )}
                                    </div>
                                </CardBody>
                                {whtaxfocus ?  
                                    <div className="popup">
                                        <div className='popup-inner'>
                                        <div className='popcls'>
                                            <div className='popclsdiv1'>
                                                <h5>Withold Tax</h5>
                                            </div>
                                            <div className='popclsdiv2'>
                                                <button className='popclsbtn' type='buttom' onClick={handleCloseWh}><IoMdClose className='clsico' color='red'/></button>
                                            </div>
                                        </div>
                                            <div className="table-container">
                                                <table style={{width:"100%"}}>
                                                    <thead>
                                                        <tr style={{height:"20px"}}>
                                                            <th style={{textAlign:"center"}}>WHT Category</th>
                                                            <th style={{textAlign:"center"}}>WHT Type</th>
                                                            <th style={{textAlign:"center"}}>WH Tax Code</th>
                                                            <th style={{textAlign:"center"}}>Description</th>
                                                            <th style={{textAlign:"center"}}>From Date</th>
                                                            <th style={{textAlign:"center"}}>To Date</th>
                                                            <th style={{textAlign:"center"}}>Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {whrows.map((row, index) => (
                                                            <tr key={index} style={{height:"20px"}}>
                                                                <td >
                                                                    <div className="table_td_div">
                                                                    <select
                                                                        type="number"
                                                                        name="category"
                                                                        id="category"
                                                                        style={{height:"100%" , padding:0,width:"100%"}}
                                                                        value={row.category}
                                                                        onChange={(e) => handleInputChange(index, e)}
                                                                        required
                                                                    >
                                                                        <option value={''} disabled selected>Select Category</option>
                                                                        {whcategory.map((whcategory)=>(
                                                                            <option key={whcategory.value} value={whcategory.value}>{whcategory.label}</option>
                                                                        ))}
                                                                    </select>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div className="table_td_div">
                                                                    <select
                                                                        type="number"
                                                                        name="type"
                                                                        id="type"
                                                                        style={{height:"100%" , padding:0,width:"100%"}}
                                                                        value={row.type}
                                                                        onChange={(e) => handleInputChange(index, e)}
                                                                        required
                                                                    >
                                                                        <option value={''} disabled selected>Select Category</option>
                                                                        {whtaxtype.map((whtaxtype)=>(
                                                                            <option key={whtaxtype.value} value={whtaxtype.value}>{whtaxtype.label}</option>
                                                                        ))}
                                                                    </select>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                <div className="table_td_div">
                                                                <select
                                                                        type="number"
                                                                        name="taxcode"
                                                                        id="taxcode"
                                                                        style={{height:"100%" , padding:0,width:"100%"}}
                                                                        value={row.taxcode}
                                                                        onChange={(e) => handleInputChange(index, e)}
                                                                        required
                                                                    >
                                                                        <option value={''} disabled selected>Select Category</option>
                                                                        {whtaxcode.map((whtaxcode)=>(
                                                                            <option key={whtaxcode.value} value={whtaxcode.value}>{whtaxcode.label}</option>
                                                                        ))}
                                                                    </select>
                                                                </div>
                                                                </td>
                                                                <td>
                                                                    <div className="table_td_div">
                                                                        <input
                                                                            type="text"
                                                                            name="description"
                                                                            value={row.description}
                                                                            readOnly
                                                                            style={{height:"100%", padding:"0px",color:"black",width:"100%",border:"none"}}
                                                                        />
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div className="table_td_div">
                                                                        <input
                                                                            type="date"
                                                                            name="from_date"
                                                                            style={{height:"100%", padding:"0px",color:"black",width:"100%",border:"none"}}
                                                                            value={row.from_date}
                                                                            onChange={(e) => handleInputChange(index, e)}
                                                                            required
                                                                        />
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div className="table_td_div">
                                                                        <input
                                                                            type="date"
                                                                            name="to_date"
                                                                            value={row.to_date}
                                                                            style={{height:"100%", padding:"0px",color:"black",width:"100%",border:"none"}}
                                                                            onChange={(e) => handleInputChange(index, e)}
                                                                            required
                                                                        />
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <button style={{ cursor: 'pointer', opacity: 1,display:"flex", width:"100%", alignItems:"center", justifyContent:"center"}} title='Delete' type="button" className="vendordele1a" onClick={() => removeRow(index)}>
                                                                        <IoTrash className="vendortrash" />
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div style={{display:"flex"}} className="mt-3">
                                                <div style={{width:"50%"}}>
                                                    <Button onClick={addRow} color='primary' className='btn2a'>
                                                        Add Row
                                                    </Button>
                                                </div>
                                                <div className='popbtn' style={{width:"50%"}}>
                                                    <Button className='btn2a' color='primary' type='button' onClick={handleSaveWh}>Save</Button>{' '}
                                                    {/* <Button className='btn1ab' type="button" onClick={handleCancelWhTax}>Close</Button> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    :null}
                                    
                            </Card>
                            <div className="vengirdbtn1">
                                <Button className='btn1' type='button' onClick={resetform}>Cancel</Button>
                                <button className='btn2' type='submit' disabled={vendorCode==="" || vendorName === "" }>{isEditing? "Update": "Submit"}</button>
                            </div>
                        </Col>
                    )}
                    </Form>
                    <Col sm="12" xl="6" className='vendorcol1a'>
                        <Card className=''>
                            <div style={{ display: "flex" }}>
                                <div className='headercl'>
                                <div className="h5cus">
                                    <h5 style={{paddingTop:"25px",paddingLeft:"30px"}} className="h5title">Vendor List </h5>
                                </div>
                                </div>
                                <div className='selectdel' style={{ display: "flex", alignItems: "center", justifyContent: "flex-end", width: "40%" }}>
                                    <Button color="danger" className='btn1 mt-4' onClick={handleDelete} disabled={selectedIds.length === 0}><IoTrash className="vendortrash1" />Delete</Button>
                                </div>
                            </div>
                        <CardBody>
                            <div className="table-responsive">
                                <InputGroup className="mb-3">
                                    <Input
                                        type="text"
                                        placeholder="Search"
                                        value={searchTerm}
                                        onChange={(e) => setSearchTerm(e.target.value)}
                                        className="form-control input-no-animation inputfoc inputform"
                                        style={{borderColor: "#afafaf",fontSize:"12px" }}
                                    />
                                    <InputGroupText >
                                        <FaSearch />
                                    </InputGroupText>
                                </InputGroup>
                                <DataTable
                                    columns={columns}
                                    data={paginatedData}
                                    selectableRows
                                    selectableRowsSelected={selectedIds}
                                    onSelectedRowsChange={handleCheckboxChange}
                                    clearSelectedRows={clearRows}
                                    pagination
                                    paginationServer
                                    customStyles={customStylesTable}
                                    striped={false}
                                    paginationTotalRows={vendors.length}
                                    paginationPerPage={rowsPerPage}
                                    progressPending={loading}
                                    autoWidth={true}
                                    paginationComponentOptions={{
                                        noRowsPerPage: true, // Disable option to change rows per page
                                    }}
                                    onChangePage={handlePageChange} // Handle pagination
                                    highlightOnHover
                                />
                            </div>
                        </CardBody>
                        </Card>
                    </Col>
                </Container>
        </Fragment>
    );
}
export default Vendor;