import React, { Fragment, useState, useEffect, useContext, useCallback } from 'react';
import CustomContext from '../../_helper/Customizer';
import { MENUITEMS } from './Menu';
import SidebarIcon from './SidebarIcon';
import SidebarLogo from './SidebarLogo';
import SidebarMenu from './SidebarMenu';

const Sidebar = (props) => {
  const customizer = useContext(CustomContext);
  const { toggleIcon } = customizer;
  const id = window.location.pathname.split('/').pop();
  const defaultLayout = Object.keys(customizer.layout);
  const layout = id || defaultLayout;

  // Sidebar Menu State
  const [mainmenu, setMainMenu] = useState(MENUITEMS);
  const [width, setWidth] = useState(0);

  const handleScroll = useCallback(() => {
    const sidebar = document.querySelector('.sidebar-main');
    if (sidebar) {
      if (window.scrollY > 400) {
        sidebar.classList.add('hovered');
      } else {
        sidebar.classList.remove('hovered');
      }
    }
  }, []);

  const handleResize = useCallback(() => {
    setWidth(window.innerWidth - 500);
  }, []);

  // Set the active menu item based on the current URL
  const setNavActive = useCallback((item) => {
    const updatedMenu = mainmenu.map((menuItems) => {
      menuItems.Items = menuItems.Items.map((Items) => {
        if (Items !== item) {
          Items.active = false;
        } else {
          Items.active = !Items.active;
        }
        if (Items.children) {
          Items.children = Items.children.map((submenuItems) => {
            submenuItems.active = submenuItems === item;
            if (submenuItems.children) {
              submenuItems.children = submenuItems.children.map((subSubItems) => {
                subSubItems.active = subSubItems === item;
                return subSubItems;
              });
            }
            return submenuItems;
          });
        }
        return Items;
      });
      return menuItems;
    });
    setMainMenu([...updatedMenu]);
  }, [mainmenu]);

  // Close overlay when clicked outside
  const closeOverlay = () => {
    document.querySelector('.bg-overlay1').classList.remove('active');
    document.querySelector('.sidebar-links').classList.remove('active');
  };

  // Find the active item on initial mount
  useEffect(() => {
    document.querySelector('.left-arrow').classList.add('d-none');
    window.addEventListener('resize', handleResize);
    window.addEventListener('scroll', handleScroll);
    handleResize();
    handleScroll();

    const currentUrl = window.location.pathname;

    mainmenu.forEach((items) => {
      items.Items.forEach((Items) => {
        if (Items.path === currentUrl) {
          setNavActive(Items);
        }
        if (Items.children) {
          Items.children.forEach((subItems) => {
            if (subItems.path === currentUrl) {
              setNavActive(subItems);
            }
            if (subItems.children) {
              subItems.children.forEach((subSubItems) => {
                if (subSubItems.path === currentUrl) {
                  setNavActive(subSubItems);
                }
              });
            }
          });
        }
      });
    });

    // Cleanup event listeners on unmount
    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <Fragment>
      <div
        className='bg-overlay1'
        onClick={closeOverlay}
      ></div>
      <div className={`sidebar-wrapper ${toggleIcon ? 'close_icon' : ''}`} sidebar-layout='stroke-svg'>
        <SidebarIcon />
        <SidebarLogo />
        <SidebarMenu
          setMainMenu={setMainMenu}
          props={props}
          setNavActive={setNavActive}
          activeClass={() => document.querySelector('.bg-overlay1').classList.add('active')}
          width={width}
        />
      </div>
    </Fragment>
  );
};

export default Sidebar;
