import React, { Fragment, useContext } from 'react';
import { ToastContainer } from 'react-toastify';
import { Outlet, useLocation } from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import Taptop from './TapTop';
import Header from './Header';
import Sidebar from './Sidebar';
import ThemeCustomize from '../Layout/ThemeCustomizer';
import Footer from './Footer';
import CustomizerContext from '../_helper/Customizer';
import AnimationThemeContext from '../_helper/AnimationTheme';
import ConfigDB from '../Config/ThemeConfig';

const AppLayout = ({ children, classNames, ...rest }) => {
  const { layout, sidebarIconType } = useContext(CustomizerContext);  // Combined useContext calls
  const { animation } = useContext(AnimationThemeContext);

  // Use localStorage for layout and animation settings
  const layout1 = localStorage.getItem('sidebar_layout') || layout;
  const sideBarIcon = localStorage.getItem('sidebar_icon_type') || sidebarIconType;
  const animationTheme = localStorage.getItem('animation') || animation || ConfigDB.data.router_animation;

  const location = useLocation();  // Get current route location for transitions

  return (
    <Fragment>
      {/* <Loader /> */}
      <Taptop />
      <div className={`page-wrapper ${layout1}`} sidebar-layout={sideBarIcon} id="pageWrapper">
        <Header />
        <div className="page-body-wrapper">
          <Sidebar />
          <div className="page-body">
            {/* Transition for route animations */}
            <TransitionGroup {...rest}>
              <CSSTransition key={location.key} timeout={100} classNames={animationTheme} unmountOnExit>
                {/* Use a single wrapper element for Outlet */}
                <Outlet />
              </CSSTransition>
            </TransitionGroup>
          </div>
          <Footer />
        </div>
      </div>
      <ThemeCustomize />
      <ToastContainer />
    </Fragment>
  );
};

export default AppLayout;
