import React, {useEffect} from "react";
import {Link} from 'react-router-dom';
import { Card, Container } from "reactstrap";


function Inventorysite(){



    return (
        
        <Container>
             <Card>

        
<div className="formdivss">
    
    <div className="formdivcol"><h3> Sub Master</h3>
            <div>
            <Link to="/materialclassification">Material Classification</Link>
            </div>
            <div>
            <Link to="/materialownership">Material Ownership</Link>
            </div>
            <div>
            <Link to="/materialsegregation">Material Segregation</Link>
            </div>
            <div>
            <Link to="/warehousecategory">Warehouse Category</Link>
            </div>
    </div>
        

    <div className="formdivcol"><h3>Master</h3>
        <div>
            <Link to="/itemcategory">Item Category</Link>
        </div>  
          <div>
        <Link to="/itemsubcategory">Item Sub-Category</Link>
        </div>  
          <div>
            <Link to="/uom">UoM</Link>
        </div>
        <div>
            <Link to="/itemgroup">Item Group</Link>
        </div> 
           <div>
        <Link to="/warehouse">Warehouse</Link>
        </div>  
          <div>
            <Link to="/expensecode">Expense Code</Link>
        </div>
        <div>
            <Link to="/iteml3">Item</Link>
        </div>
         
         

    </div>

    
</div>
</Card>



        </Container>
       
   )
        
   
  
   


}

export default Inventorysite;   