import React, {useEffect} from "react";
import {Link} from 'react-router-dom';
import { Card, Container } from "reactstrap";


function Taxationsite(){



    return (
        <Container>
    <Card>
    <div className="formdivss">
   
    <div className="formdivcol"><h3> Sub Master</h3>
            <div>
                <Link to="/whtaxcategory">WH_TaxCategory</Link>
            </div>  
              <div>
            <Link to="/whtaxtype">WH_TaxType</Link>
            </div> 
               <div>
                <Link to="/whtaximpact">WH_TaxImpact</Link>
            </div>
            <div>
                <Link to="/natureofassessee">Nature of assessee</Link>
            </div>  
              <div>
            <Link to="/registrationtype">Registration type</Link>
            </div> 
               <div>
                <Link to="/assesseeterritory">Assessee Territory</Link>
            </div>
            <div>
                <Link to="/gsttaxcategory">GST_TaxCategory</Link>
            </div>  
              <div>
            <Link to="/gsttaxtype">GST_TaxType</Link>
            </div> 
               <div>
                <Link to="/gsttaxnature">GST_Tax Nature</Link>
            </div>
            <div>
                <Link to="/gstchargeablebasis">GST Chargeable Basis</Link>
            </div>
        </div>
        
        <div className="formdivcol"><h3>Master</h3>
                <div>
                <Link to="/hsn">HSN</Link>
                </div>
                <div>
                <Link to="/sac">SAC</Link>
                </div>
                <div>
                <Link to="/withholdtax">Withhold Tax</Link>
                </div>
                <div>
                <Link to="/taxcluster">Tax Cluster</Link>
                </div>
                <div>
                <Link to="/withholdtaxassign">Withhold Tax Assign</Link>
                </div>
                <div>
                <Link to="/taxelement">Tax Element</Link>
                </div>
                <div>
                <Link to="/taxdesign">Tax Design</Link>
                </div>
        </div>
       
    </div>
    </Card>


         
   
  </Container>
   

    )

}

export default Taxationsite;   

